import produce from 'immer';
import cloneDeep from 'lodash-es/cloneDeep';
import keyBy from 'lodash-es/keyBy';

import { defaultDesignerState } from '@/store/_setup/initialState';
import { LogoutAction } from '@/store/user/actions/LogoutAction';

import {
  AddDesignAction,
  CollapseDesignerAction,
  DeleteDesignAction,
  EndEditingAction,
  ExpandDesignerAction,
  StartEditingAction,
  StoreDesignsAction,
  UpdateDesignAction
} from './actions';
import { ClearDesignsAction } from './actions/ClearDesignsAction';

/**
 *  structure:
 *
 *
    designerOn: false,
    designerCollapsed: false,
    designs: {
        '1': { ... }
    },

    draftDesign: {
        id: '0',
        name: "новый дизайн",
        colors: {},
        background: {},
        layout: {},
        header: {},
        fonts: {}
        ...same as design settings...
    }
 *
 */

export const designerReducer = (state = defaultDesignerState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case AddDesignAction.type:
        draft.designs[action.design.id] = action.design;
        break;

      case StoreDesignsAction.type:
        draft.designs = keyBy(action.designs, 'id');
        break;

      case DeleteDesignAction.type:
        delete draft.designs[action.designID];
        break;

      case CollapseDesignerAction.type:
        draft.designerCollapsed = true;
        break;

      case StartEditingAction.type:
        draft.draftDesign = action.designData;
        draft.designerOn = true;
        draft.designerCollapsed = false;
        break;

      case EndEditingAction.type:
        draft.draftDesign = {};
        draft.designerOn = false;
        break;

      case ExpandDesignerAction.type:
        draft.designerCollapsed = false;
        break;

      case UpdateDesignAction.type:
        draft.draftDesign = {
          ...draft.draftDesign,
          ...action.designData
        };
        break;
      case LogoutAction.type:
      case ClearDesignsAction.type:
        draft = cloneDeep(defaultDesignerState);
        break;
      default:
        break;
    }
});
