import { Routes, Route } from 'react-router-dom';

import React from 'react';
import { CommonFeedContainer } from '@/scenes/Dybr/Feeds/CommonFeed';
import BlogList from '@/scenes/Dybr/Feeds/BlogList';
import { ServiceBackground } from '@/components/DybrComponents/ServiceBackground';
import { HeaderGeneral } from '@/components/DybrComponents/HeaderGeneral';
import TabsContainer from '@/components/DybrComponents/TabsContainer';
import { NavTab } from '@/components/DybrComponents/NavTab';

function FeedScenes() {
  return (
    <ServiceBackground data-testid="feed-scenes">
      <HeaderGeneral paddingBottom="45px">
        <h1>дыбр</h1>
        <TabsContainer>
          <NavTab to="blogs">все дневники</NavTab>
          <NavTab to="communities">все сообщества</NavTab>
          <NavTab to="." end>
            общая лента
          </NavTab>
        </TabsContainer>
      </HeaderGeneral>

      <Routes>
        <Route path="/" element={<CommonFeedContainer />} />
        <Route path="/communities" element={<BlogList isCommunity={true} />} />
        <Route path="/blogs" element={<BlogList isCommunity={false} />} />
      </Routes>
    </ServiceBackground>
  );
}

export default FeedScenes;
