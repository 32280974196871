import styled from 'styled-components';
import { BlogDisplayStyled } from '@/components/Shared/BlogDisplay.style';

export const ProfileMainSection = styled.div`
  padding-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-around;

  @media (max-width: 650px) {
    padding-top: 20px;
    flex-wrap: wrap;
    align-items: center;
  }
`;
export const Profile = styled.div`
  flex-grow: 4;
  flex-shrink: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  max-width: 400px;

  .profile-avatar {
    cursor: pointer;
    border: 0;
    background: transparent;
    font-size: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      height: auto;
      width: auto;
      max-width: 120px;
      max-height: 120px;
    }

    &.direct-message-header {
      img {
        border: 3px solid white;
      }
    }
  }
  .link-to-favs {
    position: absolute;
    left: 130px;
    top: 0;

    .icon-favorites {
      height: 26px;
      width: 26px;
    }

    .icon {
      .main-outline,
      .filling-outline,
      .filling-area {
        fill: ${props => props.theme.accent};
        transition: opacity 0.3s;
      }
      .filling-area,
      .secondary-filling-area {
        opacity: 0;
      }
    }

    &:hover .icon,
    .icon.icon-active {
      .filling-area {
        opacity: 1;
      }
      .plus-sign.main-outline {
        fill: ${props => props.theme.backgroundDarker};
      }
    }
  }
  .nickname {
    font-weight: bold;
    font-size: 16px;
    padding-top: 20px;
    word-break: break-word;
    text-align: center;
  }
  .subtext {
    font-size: 12px;
    color: ${p => p.theme.textLight};
    word-break: break-word;
    text-align: center;
  }

  .birthday-icon {
    padding: 7px 0 3px;
    & + .nickname {
      padding-top: 0 !important;
    }
  }
`;

export const Flags = styled.div`
  margin-top: 25px;
  display: flex;
  row-gap: 5px;
  column-gap: 5px;
`;
export const Flag = styled.div`
  background-color: #de413a;
  color: white;
  padding: 2px 16px;
  text-align: center;
  border-radius: 3px;
`;

export const DirectMessageHeaderProfile = styled(Profile)`
  flex: 0;
  margin-top: 40px;

  .nickname {
    padding-top: 10px;
  }
`;

export const Blog = styled.div`
  flex-grow: 5;
  max-width: 680px;
  @media (max-width: 650px) {
    padding-top: 30px;
  }

  ${BlogDisplayStyled} {
    min-height: 156px;
    letter-spacing: 0.6px;
  }

  .blog-info {
    color: ${p => p.theme.text};
    padding-top: 7px;
    font-size: 13px;
    display: flex;
    justify-content: space-around;

    @media (max-width: 600px) {
      padding: 7px 20px;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

export const Subscriptions = styled.div`
  padding-top: 0;
  padding-bottom: 40px;
  width: 100%;

  @media (max-width: 600px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
