import React, { useEffect } from 'react';

import { ContentWrapper } from '@/components/DybrComponents/StyledServicePagesElements';
import IndexingSettings from '@/scenes/User/Settings/Privacy/IndexingSettings';
import AccessSettings from '@/scenes/User/Settings/Privacy/AccessSettings/AccessSettings';
import DybrFeedSettings from '@/scenes/User/Settings/Privacy/DybrFeedSettings';
import PrivacyLists from '@/scenes/User/Settings/Privacy/PrivacyLists';
import MailingSettings from './Privacy/MailingSettings';

function ProfilePrivacySettings() {
  useEffect(() => {
    document.title = `Настройки приватности`;
  }, []);

  return (
    <ContentWrapper>
      <DybrFeedSettings />
      <MailingSettings />
      <AccessSettings />
      <PrivacyLists />
      <IndexingSettings />
    </ContentWrapper>
  );
}

export default ProfilePrivacySettings;
