import React, { useState } from 'react';
import { getAvatar } from '@/utils/helpers/profiles';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import theme from '@/styles/DybrTheme';
import { ARROW_HEIGHT } from './constants';
import { AvatarMenuContainer } from '@/components/Shared/AvatarMenu/AvatarMenu';
import css from '@/components/Shared/AvatarWithMenu.module.css';
import { classNames } from '@/utils/helpers/classNames';

function AvatarWithMenu({ profile, className, subAvatar = false }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const arrowContentRenderer = ({ position, childRect, popoverRect }) => (
    <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor={theme.backgroundLighter}
      arrowSize={ARROW_HEIGHT}
      arrowStyle={{ marginLeft: subAvatar ? '20px' : '0px' }}
      className={classNames({
        [css.popoverArrowContainer]: true,
        [css.popoverArrowContainerSmall]: subAvatar
      })}
    >
      <AvatarMenuContainer profile={profile} />
    </ArrowContainer>
  );

  return (
    <div className={className}>
      <Popover
        padding={5}
        isOpen={isMenuOpen}
        positions={['bottom']}
        align={'center'}
        content={arrowContentRenderer}
        onClickOutside={() => setMenuOpen(false)}
        disableReposition={true}
        transitionDuration={0.1}
      >
        <span className={css.avatarImg}>
          <img
            alt="avatar"
            src={getAvatar(profile)}
            onClick={() => setMenuOpen(!isMenuOpen)}
          />
        </span>
      </Popover>
    </div>
  );
}

export default AvatarWithMenu;
