import {
  communityAllEntryPrivacySettings,
  entryPrivacySettingsTypes
} from '@/configs/dybr';
import LockIcon from '@/styles/Icons/Lock';
import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentAccessType } from '../../../utils/privacy/accessCalculation';

function getTooltip(permissions, isCommunity, canEdit) {
  if (permissions?.access?.length) {
    const accessSettingType = getCurrentAccessType({ permissions });
    if (['registered'].includes(accessSettingType)) {
      return null;
    } // We don't show a lock if the entry is closed for guests

    if (['deny-custom-list'].includes(accessSettingType) && !canEdit) {
      return null;
    } // We don't show a lock if the entry is closed for custom list. Only author should see it!

    const permissionTypes = isCommunity
      ? communityAllEntryPrivacySettings
      : entryPrivacySettingsTypes;

    return permissionTypes.find(
      type => type.value && accessSettingType === type.value.type
    )?.label;
  }
}

function EntryTitle({ type, entry, makeUrl, canEdit }) {
  const isCommunity = entry.community !== undefined;

  const tooltip = getTooltip(entry.settings?.permissions, isCommunity, canEdit);

  const hasLockIcon = Boolean(entry.settings) && tooltip;

  const { title } = entry;

  return (
    <>
      {!title && type === 'feed-entry' && hasLockIcon && (
        <div
          className="entry-lock-icon"
          data-testid="lock-icon-testid"
          title={tooltip}
        >
          <LockIcon />
        </div>
      )}
      {(title || hasLockIcon) && (
        <div className="blog-entry-title">
          {hasLockIcon && (
            <div
              className="entry-lock-icon"
              data-testid="lock-icon-testid"
              title={tooltip}
            >
              <LockIcon />
            </div>
          )}
          <Link to={makeUrl()} data-testid="blog-entry-title-link">
            {title}
          </Link>
        </div>
      )}
    </>
  );
}
export default EntryTitle;
