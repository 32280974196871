import {
  LIMIT_DURATION_FOR_COMMENT_EDIT,
  LIMIT_DURATION_FOR_COMMENT_EDIT_IN_MINUTES
} from '../../configs/dybr';

export default function isCommentEditTimeOut(minutesSincePublished) {
  return (
    LIMIT_DURATION_FOR_COMMENT_EDIT &&
    minutesSincePublished > LIMIT_DURATION_FOR_COMMENT_EDIT_IN_MINUTES
  );
}
