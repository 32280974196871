import {
  communityAllEntryPrivacySettings,
  communityEntryPrivacySettingsTypes,
  entryPrivacySettingsTypes
} from '@/configs/dybr';
import { useEntryEditContext } from '@/scenes/Blog/EntryEdit/EntryEditContext';
import Select from '@/components/FormElements/Select';
import React, { useState } from 'react';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { connect } from 'react-redux';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import CustomListDropdown from '@/scenes/User/Settings/Privacy/AccessSettings/CustomListDropdown';
import {
  calculateReadAccessSettings,
  getCurrentAccessType,
  getCustomListReadAccess,
  getInternalAccessSettings
} from '@/utils/privacy/accessCalculation';
import { AccessType } from '@/utils/privacy/types/AccessType';

const privacyOptions = entryPrivacySettingsTypes.map((setting, i) => ({
  label: setting.label,
  value: i
}));

const communityPrivacyOptions = communityEntryPrivacySettingsTypes.map(
  (setting, i) => ({
    label: setting.label,
    value: i
  })
);

const communityOwnerPrivacyOptions = communityAllEntryPrivacySettings.map(
  (setting, i) => ({
    label: setting.label,
    value: i
  })
);

const privacyTypeByIndex = {};
entryPrivacySettingsTypes.forEach(
  (option, index) => (privacyTypeByIndex[option.value?.type] = index)
);

const communityPrivacyTypeByIndex = {};
communityEntryPrivacySettingsTypes.forEach(
  (option, index) => (communityPrivacyTypeByIndex[option.value?.type] = index)
);

// It's bad that new privacy collection option leads to this code duplication,
// but we would need to refactor DybrSelect component and all its usages to fix that.
const communityOwnerPrivacyTypeByIndex = {};
communityAllEntryPrivacySettings.forEach(
  (option, index) =>
    (communityOwnerPrivacyTypeByIndex[option.value?.type] = index)
);

function getPrivacyOptionId(blog, profile, currentType) {
  if (blog.isCommunity) {
    if (blog.id === profile.id) {
      return communityOwnerPrivacyTypeByIndex[currentType] ?? 0;
    } else {
      return communityPrivacyTypeByIndex[currentType] ?? 0;
    }
  } else {
    return privacyTypeByIndex[currentType] ?? 0;
  }
}

function PrivacySettings({ menuPortalTarget, blog, profile }) {
  const { entrySettings, setEntrySettings } = useEntryEditContext();
  const internalSettings = getInternalAccessSettings(
    entrySettings?.permissions?.access
  );

  const [currentType, setCurrentType] = useState(() =>
    getCurrentAccessType(entrySettings)
  );

  const initialAllowList = internalSettings?.allowCustomList;
  const initialDenyList = internalSettings?.denyCustomList;

  const selectedOptionId = getPrivacyOptionId(blog, profile, currentType);

  const handlePrivacySettingChange = (id) => {
    const requestedAccessType = blog.isCommunity
      ? communityAllEntryPrivacySettings[id].value
      : entryPrivacySettingsTypes[id].value;

    setCurrentType(requestedAccessType?.type);

    const access = calculateReadAccessSettings(
      requestedAccessType,
      entrySettings
    );

    setEntrySettings((settings) => ({
      ...settings,
      permissions: {
        ...settings.permissions,
        access
      }
    }));
  };

  function handleCustomListChange(accessType, options) {
    const access = getCustomListReadAccess(accessType, options);
    setEntrySettings((settings) => ({
      ...settings,
      permissions: {
        ...settings.permissions,
        access
      }
    }));
  }

  const accessOptions = blog.isCommunity
    ? blog.id === profile.id
      ? communityOwnerPrivacyOptions
      : communityPrivacyOptions
    : privacyOptions;

  return (
    <>
      <div className="privacy-settings">
        <div className="privacy-settings-label">доступ</div>
        <div className="privacy-settings-selectors">
          <Select
            options={accessOptions}
            className="privacy-setting-select privacy-setting-select--main-list"
            captureMenuScroll={true}
            menuPortalTarget={menuPortalTarget}
            maxMenuHeight={300}
            menuPlacement="auto"
            blogSelect={true}
            selectedOptionID={selectedOptionId}
            onChange={handlePrivacySettingChange}
          />

          <div className="custom-list-setting-item">
            {currentType === AccessType.allowCustomList && (
              <CustomListDropdown
                onChange={(options) =>
                  handleCustomListChange(currentType, options)
                }
                initialOptions={initialAllowList}
                className="privacy-setting-select"
                blogSelect={true}
              />
            )}
            {currentType === AccessType.denyCustomList && (
              <CustomListDropdown
                onChange={(options) =>
                  handleCustomListChange(currentType, options)
                }
                initialOptions={initialDenyList}
                className="privacy-setting-select"
                blogSelect={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(connect(mapStateToProps)(PrivacySettings));
