import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import lemur from '@/styles/img/lemur.png';
import packageJson from '../../../package.json';

const MENU = [
  {
    href: '/help',
    title: 'Помощь'
  },
  {
    href: '/terms',
    title: 'Правила'
  },
  {
    href: '/contacts',
    title: 'Контакты'
  },
  {
    href: '/about',
    title: 'О нас'
  }
];

const Wrapper = styled.div`
  margin-top: ${props => (props.withLemur ? '140px' : '0px')};
  position: relative;
  display: flex;
  justify-content: space-between;

  background-color: ${props => props.theme.backgroundDarker};
  color: ${props => props.theme.textLighter};
  font-size: 12px;
  padding: 10px;

  @media (min-width: 768px) {
    padding: 10px 20px;
  }
`;

const Lemur = styled.img`
  position: absolute;
  top: -127px;
  left: 0px;
  width: 230px;
  height: 145px;
`;

const Copyright = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 15px;
  text-align: right;
  @media (min-width: 700px) {
    /*margin-bottom: 0;*/
    text-align: left;
  }
`;

const Nav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const NavItem = styled.li`
  display: inline-block;
  vertical-align: top;
  &:not(:last-child) {
    margin-right: 10px;
    @media (min-width: 768px) {
      margin-right: 40px;
    }
  }
  a {
    color: ${props => props.theme.textLighter};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.textLighter};
      text-decoration: underline;
    }
  }
`;
/*
const NavLink = styled(Link)`

`;*/

const YEAR = new Date().getFullYear();

const version = packageJson.version ? `v${packageJson.version}` : '';

const Footer = ({ withLemur, copyright = true }) => {
  return (
    <footer
      data-testid="footer"
      style={{
        position: 'absolute',
        bottom: '0',
        width: '100%',
        zIndex: '100'
      }}
    >
      <Wrapper>
        {withLemur && <Lemur src={lemur} alt="lemur" />}
        <Copyright>
          {`${copyright ? `2017 - ${YEAR} © ` : ''}Дыбр ${version}`}
        </Copyright>
        <Nav>
          {MENU.map(item => (
            <NavItem key={item.title}>
              <Link to={item.href}>{item.title}</Link>
            </NavItem>
          ))}
        </Nav>
      </Wrapper>
    </footer>
  );
};

export default Footer;
