import React from 'react';

import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from '@/components/DybrComponents/StyledServicePagesElements';
import PermissionSettings from '@/scenes/User/Settings/Privacy/AccessSettings/PermissionSettings';
//import DatePicker, { CalendarContainer } from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
/*
const DatePickerWrapper = styled.div`
  .react-datepicker-popper {
    z-index: 500;
  }
  & > div {
    display: inline;
    margin-left: 20px;
  }
`;

class DateInput extends React.Component {
  render() {
    const StyledButton = styled.button`
      cursor: pointer;
      background: none;
      border: none;
      border-bottom: 1px solid ${p => p.theme.divider};
      padding: 5px;
      font-size: 12px;
    `;
    return (
      <StyledButton onClick={this.props.onClick}>
        {this.props.value}
      </StyledButton>
    );
  }
}*/

function AccessSettings() {
  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Доступ к блогу</h4>
          <p>
            Выберите, кто может читать ваш блог. При выборе опции "открыт
            спискам" ваш блог будет закрыт для всех, кроме пользователей из
            выбранных списков. При выборе опции "закрыт спискам" &mdash; будет
            виден всем зарегистрированным пользователям, кроме пользователей из
            указанных списков. Новые списки можно создать в блоке настроек ниже.
          </p>
        </SettingSectionTitle>
        <Settings>
          <PermissionSettings />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

export default AccessSettings;
