/**
 *
 * - get everything as props

 * - render author info if the type says so
 * -
 * - render router links
 * - render comment icon and number
 * - render settings data
 * - render "copy link"
 * -
 */

// Component to display one entry (used both in feeds and entry page)
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { MAX_ENTRY_CONTENT_HEIGHT } from '@/components/Shared/constants';
import EntryMenu from '@/components/Shared/EntryMenu';
import UrlTooltip from '@/components/Shared/UrlTooltip';
import { HOSTNAME } from '@/configs/dybr';
import { BlockUserIcon } from '@/styles/Icons/BlockUser';
import { BookmarkNormalViewIcon } from '@/styles/Icons/BookmarkNormalView';
import { ChatIcon } from '@/styles/Icons/Chat';
import { EditIcon } from '@/styles/Icons/Edit';
import LinkIcon from '@/styles/Icons/Link';
import PinIcon from '@/styles/Icons/Pin';
import ReplyIcon from '@/styles/Icons/ReplyToComment';
import SettingsCogIcon from '@/styles/Icons/SettingsCog';
import WatchIcon from '@/styles/Icons/WatchThread';
import { classNames } from '@/utils/helpers/classNames';
import { formatTimeDate } from '@/utils/helpers/formatTimeDate';
import processHtmlToReact from '@/utils/helpers/processHtml';
import useCopyTooltip from '@/utils/hooks/useCopyTooltip';

import { BlogEntryAuthor } from './BlogEntryAvatar';
import EntryTitle from './EntryTitle';
import { ReadMoreButton } from './ReadMoreButton';

export function canManipulate(loggedIn, ownProfileId, entry) {
  return (
    loggedIn &&
    (ownProfileId === entry.profile.id || ownProfileId === entry.community?.id)
  );
}

const EntryDate = ({ original, published, pinned }) => {
  const [showOriginal, setShowOriginal] = useState(false);
  if (pinned)
    return (
      <div className="blog-entry-date">
        <PinIcon />
        {original}
      </div>
    );
  if (original === published)
    return <div className="blog-entry-date">{published}</div>;

  return (
    <div
      className="blog-entry-date entry-date-changed"
      title={'запись создана ' + original}
      onClick={() => setShowOriginal(!showOriginal)}
    >
      {showOriginal ? null : <EditIcon />}

      {showOriginal ? 'запись создана ' + original : published}
    </div>
  );
};

export default function Entry({
  entry,
  handleReply,
  onDelete,
  canManipulate,
  canManageWatching,
  hasUserFunctions,
  onToggleBookmark,
  onToggleSubscribe,
  hideProfileFromFeed,
  hideProfileFromFeedForAll,
  type,
  allowedAdminActions,
  canBlockProfile,
  hideLargeContent
}) {
  const [showEntryMenu, setShowEntryMenu] = useState(false);
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [watching, setWatching] = useState(entry.meta.subscribed);
  const [isContentExpanded, setIsContentExpanded] = useState(!hideLargeContent);
  const [bookmarked, setBookmark] = useState(entry.meta.bookmark);
  const [height, setHeight] = useState(0);

  const entryContentRef = useRef(null);

  useEffect(() => {
    if (!entryContentRef.current) return;
    if (typeof ResizeObserver !== 'function') return;
    const resizeObserver = new ResizeObserver(() => {
      if (entryContentRef.current) {
        setHeight(entryContentRef.current.offsetHeight);
      }
    });
    resizeObserver.observe(entryContentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const isCommunity = entry.community !== undefined;
  // const [actionInProgress, setActionInProgress] = useState(false);

  const { profile } = entry;

  useEffect(() => {
    setWatching(entry.meta.subscribed);
  }, [entry.meta.subscribed]);

  useEffect(() => {
    setBookmark(entry.meta.bookmark);
  }, [entry.meta.bookmark]);

  const {
    copy: copyEntryURL,
    isCopied: isUrlCopied,
    showTooltip: showUrlTooltip,
    toggle: toggleUrlTooltip
  } = useCopyTooltip(HOSTNAME + makeUrl());

  function getSlug() {
    if (isCommunity) {
      return entry.community.blogSlug;
    } else {
      return profile.blogSlug;
    }
  }

  function makeUrl(scroll) {
    return `/blog/${getSlug()}/${entry.id}${scroll ? '?scroll=true' : ''}`;
  }

  function makeTagUrl(tag) {
    return `/blog/${getSlug()}?tags=${encodeURIComponent(tag)}`;
  }

  function toggleEntryMenu() {
    setShowEntryMenu(showEntryMenu => !showEntryMenu);
  }

  function toggleAdminMenu() {
    setShowAdminMenu(showAdminMenu => !showAdminMenu);
  }

  async function toggleWatch() {
    // optimistic rendering
    setWatching(watching => !watching);
    const res = await onToggleSubscribe();
    if (!res) setWatching(watching => !watching);
  }

  async function toggleBookmark() {
    setBookmark(bookmark => !bookmark);
    const res = await onToggleBookmark();
    if (!res) setBookmark(bookmark => !bookmark);
  }

  function createMenuItems() {
    return [
      <Link key="edit" to={`${makeUrl()}/edit`}>
        редактировать
      </Link>,
      <button
        key="delete"
        onClick={function () {
          if (canManipulate && onDelete) {
            if (window.confirm('Точно удалить запись?')) {
              onDelete(entry.id);
            }
          }
        }}
      >
        удалить
      </button>
    ];
  }

  function createAdminMenuItems(allowedActions) {
    const basic = (
      <button
        onClick={() => {
          if (
            window.confirm('Перестать видеть записи профиля в общей ленте?')
          ) {
            hideProfileFromFeed(isCommunity ? entry.community : profile);
          }
        }}
      >
        убрать из общей ленты для себя
      </button>
    );
    const restricted = [];
    if (allowedActions.feed && allowedActions.feed.includes('hide'))
      restricted.push(
        <button
          onClick={() => {
            const reason = window.prompt(
              'Причина? В будущем будет видна пользователю.'
            );
            if (reason) {
              hideProfileFromFeedForAll(
                isCommunity ? entry.community : profile,
                reason
              );
            }
          }}
        >
          !! убрать из общей ленты для всех !!
        </button>
      );
    return [basic, ...restricted];
  }

  function handleToggleExpand() {
    const shoulScrollUp = isContentExpanded;
    setIsContentExpanded(!isContentExpanded);
    if (entryContentRef.current && shoulScrollUp) {
      const yOffset =
        entryContentRef.current.getBoundingClientRect().top +
        window.pageYOffset;
      window.scrollTo({
        top: yOffset,
        behavior: 'smooth'
      });
    }
  }

  const { id: entryId, content, createdAt, publishedAt, tags, meta } = entry;
  // backend returns empty entry objects in some cases :(
  if (!content && content !== '') return null;

  const showEntryMeta =
    type === 'entry-page' || type === 'feed-entry' || isCommunity;
  const showBlogLink = type === 'feed-entry';
  const showComments = meta.comments || meta.canComment;

  const showWatchIcon =
    canManageWatching && hasUserFunctions && meta.subscribed !== undefined;

  return (
    <div
      className={classNames({
        'blog-entry': true,
        'blog-entry--no-author': !showEntryMeta,
        'blog-entry--no-blog-link': !showBlogLink
      })}
      data-testid="blog-entry"
    >
      <div
        className={
          bookmarked ? 'blog-entry-bookmark active' : 'blog-entry-bookmark'
        }
        onClick={toggleBookmark}
        title={bookmarked ? 'удалить из закладок' : 'добавить в закладки'}
      >
        <BookmarkNormalViewIcon />
      </div>

      <div className="blog-entry-meta--up " id={entryId}>
        <BlogEntryAuthor
          isBlogPage={!showBlogLink}
          isCommunity={isCommunity}
          entry={entry}
          profile={profile}
          slug={getSlug()}
        />

        <EntryDate
          original={formatTimeDate(createdAt)}
          published={formatTimeDate(publishedAt)}
          pinned={meta.pinned}
        />
        <EntryTitle
          type={type}
          entry={entry}
          makeUrl={makeUrl}
          canEdit={canManipulate}
        />
      </div>
      <div
        ref={entryContentRef}
        className={
          isContentExpanded
            ? 'blog-entry-content'
            : 'blog-entry-content blog-entry-content--sliced'
        }
        id={entryId}
      >
        {processHtmlToReact(content)}
      </div>
      {hideLargeContent && height >= MAX_ENTRY_CONTENT_HEIGHT && (
        <ReadMoreButton
          handleToggleExpand={() => handleToggleExpand()}
          isExpanded={isContentExpanded}
        />
      )}
      {tags && tags.length > 0 && (
        <div className="blog-entry-tags">
          {tags.map((tag, i) => (
            <Link to={makeTagUrl(tag)} key={i}>
              #{tag}
            </Link>
          ))}
        </div>
      )}
      <div className="blog-entry-meta--down" id={entryId}>
        <div className="blog-entry-interactions">
          {type !== 'entry-page' && showComments && (
            <div
              className={`blog-entry-comment-icon ${
                meta.canComment ? '' : 'disabled-icon'
              }`}
            >
              <Link to={makeUrl(true)} title="комментировать">
                <ChatIcon />
              </Link>
              {meta.comments !== 0 && (
                <Link to={makeUrl()} className="comment-number">
                  {meta.comments}
                </Link>
              )}
            </div>
          )}
          {type === 'entry-page' && hasUserFunctions && meta.canComment && (
            <div className="blog-entry-reply-icon">
              <span
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleReply(entry.id, profile.id, profile.nickname)
                }
                title="ответить"
              >
                <ReplyIcon />
              </span>
            </div>
          )}
        </div>
        <div className="blog-entry-controls">
          <span
            className="blog-entry-controls-link"
            onClick={copyEntryURL}
            title="скопировать URL"
          >
            <LinkIcon />
            {showUrlTooltip && (
              <UrlTooltip
                onClickAway={toggleUrlTooltip}
                isUrlCopied={isUrlCopied}
                text={'ссылка скопирована!'}
                url={makeUrl()}
              />
            )}
          </span>
          {canBlockProfile && !allowedAdminActions && (
            <span
              className="blog-entry-block-profile"
              onClick={() => {
                if (
                  window.confirm(
                    `Точно отфильтровать записи ${
                      isCommunity ? 'сообщества' : 'пользователя'
                    } из общей ленты?`
                  )
                ) {
                  hideProfileFromFeed(isCommunity ? entry.community : profile);
                }
              }}
              title={`не видеть записи ${
                isCommunity ? 'сообщества' : 'пользователя'
              } в общей ленте`}
            >
              <BlockUserIcon />
            </span>
          )}
          {allowedAdminActions && (
            <span
              className="blog-entry-block-profile"
              onClick={toggleAdminMenu}
              title="функции блокировки"
            >
              <BlockUserIcon />
              {showAdminMenu && (
                <EntryMenu
                  menuItems={createAdminMenuItems(allowedAdminActions)}
                  onClickAway={toggleAdminMenu}
                />
              )}
            </span>
          )}

          {canManipulate && (
            <span
              className="blog-entry-controls-cog"
              onClick={toggleEntryMenu}
              title="управление записью"
            >
              <SettingsCogIcon />
              {showEntryMenu && (
                <EntryMenu
                  menuItems={createMenuItems()}
                  onClickAway={toggleEntryMenu}
                />
              )}
            </span>
          )}
          {showWatchIcon && (
            <span
              className="blog-entry-controls-watch"
              onClick={toggleWatch}
              title={
                watching
                  ? 'отписаться от комментариев'
                  : 'подписаться на комментарии'
              }
            >
              <WatchIcon className={watching ? 'active' : ''} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
