import { hideProfileFromFeedForAllApi } from '@/api/admin';

export const hideProfileFromFeedForAll = ({ profile, reason, token }) => async (
  dispatch
) => {
  const res = await hideProfileFromFeedForAllApi({
    profileId: profile.id,
    reason,
    token
  });

  if (!res.error) {
    alert(
      `Профиль ${profile.nickname} (id: ${profile.id}) заблокирован для всех пользователей`
    );

    return true;
  } else {
    const message = res.data?.errors?.[0]?.detail;
    console.error(
      `Произошла ошибка при блокировке профиля ${profile.nickname} (id ${profile.id})`,
      res
    );
    alert(
      `Произошла ошибка при блокировке профиля ${profile.nickname} (id ${
        profile.id
      }):\n${message ?? JSON.stringify(res, null, 2)}`
    );

    // dispatchError(dispatch, res);
    return false;
  }
};
