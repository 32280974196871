import { NavLink } from 'react-router-dom';
import css from '@/components/DybrComponents/NavTab.module.css';
import { classNames } from '@/utils/helpers/classNames';

type NavTabProps = {
  to: any,
  [key: string]: any;
};

export function NavTab(props: NavTabProps) {
  return (
    <NavLink
      end
      {...props}
      className={({ isActive }) =>
        classNames({ 
          [css.link]: true, 
          [css.active]: isActive 
        })
      }
    />
  );
}
