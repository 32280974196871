import React from 'react';
import UserProfilesSelector from './UserProfilesSelector';
import { selectFavoriteProfiles } from '@/store/lists/selectors/selectFavoriteProfiles';
import { selectReaderProfiles } from '@/store/lists/selectors/selectReaderProfiles';
import { selectProfiles } from '@/store/user/selectors/selectProfiles';
import { connect } from 'react-redux';

function UserProfilesSelectorContainer({
  favorites,
  readers,
  owned,
  containerClassName,
  ...rest
}) {
  return (
    <div className={containerClassName}>
      <UserProfilesSelector
        favorites={favorites}
        readers={readers}
        owned={owned}
        {...rest}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  favorites: selectFavoriteProfiles(state),
  readers: selectReaderProfiles(state),
  owned: Object.values(selectProfiles(state))
  //select favorites, readers and other own profiles
  //no need to select bans, because banned doesn't have access to blog anyway
  //no need to select hidden, why?
  //maybe select profiles in other lists
});

export default connect(mapStateToProps)(UserProfilesSelectorContainer);
