import { changePasswordApi } from '@/api/auth';
import { parseToken } from '@/store/user/reducer/parseToken';
import { fetchUserThunk } from '@/store/user/thunks/fetchUserThunk';

export const changePassword =
  ({ token, password, setToken }) =>
  async dispatch => {
    const res = await changePasswordApi({
      password,
      token
    });
    if (!res.error) {
      setToken(res.accessToken);
    } else {
      // dispatch error
      // return { error: res.error };
    }
    return res;
  };
