(function($) {
  if ($.FroalaEditor) {
    // Define the plugin.
    // The editor parameter is the current instance.
    $.FroalaEditor.PLUGINS.more = function(editor) {
      //console.log($.FroalaEditor.MARKERS);
      function apply(c) {
        editor.selection.text() !== ''
          ? editor.html.insert(
              `[MORE=читать дальше]${editor.html.getSelected()}[/MORE] ${
                $.FroalaEditor.MARKERS
              }`
            )
          : editor.html.insert(
              `[MORE=читать дальше]${$.FroalaEditor.MARKERS}[/MORE]`
            );
      }

      // The start point for your plugin.
      function _init() {
        // console.log('init');
        // You can access any option from documentation or your custom options.
        // console.log(editor.opts.myOption);
        // Call any method from documentation.
        // editor.methodName(params);
        // You can listen to any event from documentation.
        // editor.events.add('contentChanged', function (params) {});
      }

      return {
        _init,
        apply
      };
    };
    $.FroalaEditor.DefineIconTemplate(
      'moreIcon',
      `<svg
    viewBox="0 0 897.71057 718.13392"
    className="icon icon-more-for-editor"
    xmlns="http://www.w3.org/2000/svg"
    style="width:19px;margin:13px 10px;"
  >
    <path
      className="more filling-area"
      d="M 823.07226,5.3192279e-4 74.95508,0.08061192 C 48.04175,-0.10646808 33.89867,9.8154519 21.88867,22.641152 9.87868,35.466842 0,51.116022 0,74.953652 V 429.03962 L 253.11328,223.66264 449.16211,371.12748 637.35937,256.66264 897.71094,406.05131 V 74.953652 c 0,-15.80729 -4.52753,-34.5616 -21.29493,-52.23046 C 861.81704,7.3393419 843.28106,0.37709192 823.07226,5.3192279e-4 Z M 252.59375,291.59233 0,503.22322 0.14844,643.86182 c -0.14749,21.7327 10.15643,40.9585 21.77539,52.1563 10.56694,10.1839 26.85796,22.3153 53.7832,22.1133 l 747.18945,-0.1934 c 26.91333,0.1871 45.31487,-13.7488 52.83399,-21.7793 8.16164,-8.7166 21.98047,-24.0803 21.98047,-52.7793 v -167.793 L 637.64844,325.24858 449.52539,438.01812 Z"
    />

    <path
      className="more main-outline"
      d="M 74.953318,0.07968632 C 48.039987,-0.10739628 33.897851,9.8143967 21.887855,22.640091 9.8778582,35.465785 0,51.115675 0,74.953302 V 429.03794 L 253.1132,223.6615 449.16053,371.12619 637.35765,256.66142 897.71054,406.04971 V 74.953302 c 0,-15.807294 -4.52879,-34.562829 -21.29619,-52.231689 C 861.81538,7.3377635 843.28109,0.37654508 823.07229,-1.7575457e-5 Z M 75.218484,59.917331 c 0,0 516.212326,-0.904604 747.853806,-0.166819 0,0 4.93131,-0.401527 10.30263,4.678984 4.85844,4.59539 4.54661,10.523806 4.54661,10.523806 L 837.87294,302.83767 636.23234,188.05544 449.50969,298.38161 252.455,145.20973 59.792893,300.89776 59.83891,74.953302 c 0,0 -0.321702,-5.055473 4.515756,-10.449859 4.592963,-5.121743 10.863818,-4.586112 10.863818,-4.586112 z M 252.59325,291.59161 0,503.22192 0.14749002,643.8619 C 0,665.59465 10.303367,684.82021 21.922327,696.01802 c 10.566946,10.18392 26.859381,22.31538 53.784626,22.1134 L 822.89551,717.9372 c 26.91333,0.18708 45.31541,-13.74982 52.83453,-21.78027 8.16164,-8.71666 21.9805,-24.07916 21.9805,-52.77815 l 10e-6,-167.79281 -260.064,-150.33835 -188.12124,112.77047 z m 0.2695,76.70353 195.93056,140.01023 188.82951,-113.03754 200.25012,115.45369 0.0486,132.65726 c 0,0 0.61532,5.41931 -4.52465,10.49157 -4.39731,4.33939 -10.38937,4.51182 -10.38937,4.51182 l -745.075947,-0.0884 c -6.793582,-0.10128 -10.208972,-0.68487 -13.394648,-4.42223 -4.744032,-5.56559 -4.744032,-6.9489 -4.553417,-16.73213 -0.332437,-34.54825 0.504412,-70.67175 0,-105.21778 z"
    />
  </svg>`
    );

    $.FroalaEditor.RegisterCommand('more', {
      callback(a, b) {
        // console.log('more callback', a, b);
        this.more.apply();
      },
      plugin: 'more',
      title: 'скрыть часть текста (тэг MORE)'
    });
    $.FroalaEditor.DefineIcon('more', { template: 'moreIcon' });
  }
})($);
