import React, { useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';

import NotFound from '@/scenes/Dybr/Static/NotFound';
import Footer from '@/scenes/Footer/Footer';
import { ResetPasswordContainer } from './ResetPassword';
import { CreateAccountContainer } from '@/scenes/Account/CreateAccount';
import { connect } from 'react-redux';
import { selectIsSignupIncomplete } from '@/store/user/selectors/selectIsSignupIncomplete';
import { ConfirmEmailContainer } from '@/scenes/Account/ConfirmEmail';
import CreateBlog from '@/scenes/Account/createBlog/CreateBlog';
import { useTokenState } from '@/store/localStorage/useTokenState';

function Redirect({ to }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

/**
 * Scenes are mainly for routing and getting the data used in all sub-pages or sections
 *
 */
function Account({ isSignupIncomplete }) {
  const [token] = useTokenState();
  const loggedIn = Boolean(token);

  return (
    <>
      <Routes>
        <Route path="/" element={<Redirect to="/account/new" />} />
        <Route path="new" element={<CreateAccountContainer />} />

        {isSignupIncomplete && (
          <Route path="confirm-email" element={<ConfirmEmailContainer />} />
        )}
        {loggedIn && <Route path="create" element={<CreateBlog />} />}
        <Route path="reset-password" element={<ResetPasswordContainer />} />
        <Route
          path="*"
          element={<NotFound reason={'Аккаунт: что-то пошло не так'} />}
        />
      </Routes>
      <Footer withLemur />
    </>
  );
}

const mapStateToProps = state => {
  return {
    isSignupIncomplete: selectIsSignupIncomplete(state)
  };
};

export const AccountContainer = connect(mapStateToProps)(Account);
