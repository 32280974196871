import { useActiveProfile } from '@/store/localStorage/useTokenState';
import React from 'react';

function WithActiveProfileId(Component) {
  function ComponentWithActiveProfileId(props) {
    const { activeProfileId } = useActiveProfile();

    return <Component {...props} activeProfileId={activeProfileId} />;
  }
  return ComponentWithActiveProfileId;
}

export default WithActiveProfileId;
