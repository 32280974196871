import axios from 'axios';
import Jsona from 'jsona';
import queryString from 'query-string';
import camelcaseKeys from 'camelcase-keys-deep';
import decamelizeKeysDeep from 'decamelize-keys-deep';
import { API_URL } from '@/configs/dybr';

const jsona = new Jsona();

export const createQueryString = (resource, options) => {
  const queryParams = queryString.stringify(options);
  if (queryParams.length)
    return `${resource}?${queryString.stringify(options)}`;
  else return resource;
};

const createEndpointString = (resource, id) => {
  if (id) return `${API_URL}/${resource}/${id}`;
  else return `${API_URL}/${resource}`;
};

function serializeData(queryParams, type, resource, id) {
  return JSON.stringify(
    decamelizeKeysDeep(
      jsona.serialize({
        stuff: { ...queryParams, type: type ?? resource, id },
        includeNames: queryParams.relationshipNames || []
      }),
      '-'
    )
  );
}

async function executeRequest({
  method,
  resource,
  queryParams = {},
  token,
  type,
  id,
  axiosOptions = {},
  withNonce = true
}) {
  if (token) {
    axiosOptions = {
      headers: { Authorization: 'Bearer ' + token }
    };
  }

  switch (method) {
    case 'GET': {
      const url = createEndpointString(resource, id);
      return await axios.get(createQueryString(url, queryParams), axiosOptions);
    }
    case 'GET_FILE': {
      const result = await axios.get(resource, {
        ...axiosOptions,
        responseType: 'blob'
      });
      return result.data;
    }
    case 'PATCH': {
      const data = serializeData(queryParams, type, resource, id);

      return axios({
        ...axiosOptions,
        method,
        data,
        url: createEndpointString(resource, id)
      });
    }
    case 'POST': {
      const data = serializeData(queryParams, type, resource, id);

      const nonce = new Date().getTime();
      const urlPostfix = withNonce ? '?nonce=' + nonce : '';

      return axios({
        ...axiosOptions,
        method,
        data,
        url: createEndpointString(resource, id) + urlPostfix
      });
    }
    case 'CUSTOM_POST':
      return axios({
        ...axiosOptions,
        method: 'POST',
        data: queryParams,
        url: resource
      });
    case 'DELETE': {
      const data =
        queryParams && Object.keys(queryParams).length > 0
          ? JSON.stringify(
              decamelizeKeysDeep(
                jsona.serialize({
                  stuff: queryParams,
                  includeNames: queryParams.relationshipNames || []
                }),
                '-'
              )
            )
          : undefined;
      return axios({
        ...axiosOptions,
        method,
        data,
        url: createEndpointString(resource, id)
      });
    }
    default: {
      const msg = 'UNKNOWN API ACTION';
      console.error(msg);
      throw new Error(msg);
    }
  }
}

function processResponse(result, method) {
  if (result.data && method !== 'GET_FILE') {
    const resultData = camelcaseKeys(result.data);
    const deserialized = jsona.deserialize(resultData);

    return {
      status: result.status,
      data: camelcaseKeys(deserialized),
      dataOriginal: resultData,
      meta: result.data.meta ? camelcaseKeys(result.data.meta) : {}
    };
  }

  return result;
}

export const query = async (props) => {
  const result = await executeRequest(props);

  return processResponse(result, props.method);
};

// export const tryQuery = async (method, resource, options, id) => {
//   try {
//     return await query(method, resource, options, id);
//   } catch (error) {
//     console.log('ERROR', error);
//     const { status, statusText, data, config } = error.response;
//     return {
//       error: true,
//       noAuth: status === 403,
//       status,
//       statusText,
//       data,
//       url: config.url,
//       options: config.data
//     };
//   }
// };
