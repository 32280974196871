import Collapse from '@/components/Shared/Collapse';
import { ListHeader } from '@/scenes/User/Settings/Privacy/PrivacyLists/ListHeader';
import ScrollArea from '@/components/DybrComponents/ScrollArea';
import React from 'react';
import { usePrivacyListContext } from '@/scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';
import AddMoreNames from '@/scenes/User/Settings/Privacy/PrivacyLists/List/AddMoreNames';
import ProfileEntry from '@/scenes/User/Settings/Privacy/PrivacyLists/List/ProfileEntry';
import * as css from './CustomPrivacyLists.module.css';

function CustomPrivacyLists() {
  const { lists } = usePrivacyListContext();

  return (
    <div className={css.container}>
      {lists.map((list) => (
        <Collapse key={list.id} header={<ListHeader list={list} />}>
          <>
            <ScrollArea className={css.body}>
              <AddMoreNames list={list} />
              <div className={css.userList}>
                {list.profiles?.map((profile) => (
                  <div key={profile.id}>
                    <ProfileEntry list={list} profile={profile} />
                  </div>
                ))}
              </div>
            </ScrollArea>
          </>
        </Collapse>
      ))}
    </div>
  );
}

export default CustomPrivacyLists;
