import { merge } from 'lodash-es';
import { createSelector } from 'reselect';

import defaultProfileSettings from '@/configs/defaultProfileSettings';

import { selectProfiles } from './selectProfiles';
import { selectUser } from './selectUser';

export const selectInactiveProfilesAugmented = createSelector(
  selectUser,
  selectProfiles,
  (state, { activeProfileId }) => activeProfileId,
  (user, profiles, activeProfileId) => {
    const inactiveProfiles = { ...profiles };

    if (activeProfileId && activeProfileId !== '0') {
      delete inactiveProfiles[activeProfileId];
    }

    return Object.values(inactiveProfiles).map(profile => ({
      ...profile,
      settings: merge({}, defaultProfileSettings, profile.settings)
    }));
  }
);
