import React from 'react';

export const BirthdayCakeIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 950 950"
    className={`icon icon-birthday-cake ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="cherry-stem main-outline"
      d="m 513.753,172.095 c -4.607,-9.426 -9.713,-18.638 -15.216,-27.62 -5.547,-9.054 -11.493,-17.874 -17.715,-26.451 C 473.196,107.512 465.156,97.365 456.911,87.612 441.58,69.477 424.939,52.31 406.648,37.133996 c -16.017,-13.291 -33.048,-26.094 -52.186,-34.563 -10.823,-4.79 -23.232,-3.264 -29.708,7.79 -5.471,9.34 -3.039,24.917 7.79,29.709 8.982,3.975004 17.517,8.928004 25.643,14.435004 2.348,1.591 4.677,3.209 6.968,4.881 0.835,0.61 3.828,2.892 4.616,3.473 3.471,2.748 6.902,5.545 10.253,8.44 8.839,7.638 17.339,15.672 25.455,24.075 8.545,8.847 16.76,18.018 24.546,27.541 2.212,2.706 4.407,5.427 6.557,8.184 0.005,0.006 0.009,0.011 0.015,0.017 0.077,0.103 0.162,0.215 0.257,0.339 0.905,1.196 1.812,2.391 2.707,3.595 0.032,0.045 0.064,0.09 0.098,0.134 3.533,4.762 6.996,9.577 10.328,14.483 2.328,3.427 4.607,6.888 6.839,10.38 4.352,6.812 8.504,13.752 12.369,20.852 0.914,1.679 1.812,3.366 2.701,5.058 z"
    />
    <path
      className="cake main-outline"
      id="path4"
      d="m 901.261,449.657 c -16.71,-43.479 -35.013,-91.496 -64.4,-128.276 -22.216,-27.803 -63.87,-51.881 -100.746,-46.25 -0.479,0.073 -3.56,0.746 -8.913,1.943 1.331,9.227 2.011,18.583 2.011,28.039 0,26.248 -5.153,51.743 -15.318,75.776 -9.807,23.184 -23.831,43.991 -41.686,61.847 -17.854,17.854 -38.663,31.88 -61.846,41.685 -24.033,10.165 -49.528,15.319 -75.776,15.319 -26.248,0 -51.742,-5.154 -75.775,-15.319 -23.184,-9.806 -43.991,-23.831 -61.847,-41.685 -17.854,-17.855 -31.88,-38.663 -41.685,-61.847 -2.411,-5.7 -4.53,-11.485 -6.375,-17.34 -152.941,35.083 -294.746,67.669 -303.027,69.597 -7.299,1.699 -16.184,2.713 -22.735,6.199 -5.526,2.94 -9.487,7.641 -9.487,15.993 v 381.766 c 0,15.044 15.905,28.903 35.503,30.944 l 141.915,14.758 638.322,66.438 c 58.699,6.117 106.354,-25.531 106.354,-70.708 0,-28.52 -18.981,-55.522 -47.74,-73.177 28.759,-11.623 47.745,-34.733 47.745,-63.21 0,-28.471 -18.984,-55.474 -47.693,-73.079 84.847,-34.563 36.914,-157.705 13.199,-219.413 z M 714.38,875.212 88.654,810.058 v -62.544 l 625.726,65.105 c 58.701,6.123 106.347,25.059 106.347,42.329 0,17.275 -47.646,26.386 -106.347,20.264 z m 0,-124.661 -625.726,-65.155 v -62.593 l 625.726,65.202 c 58.701,6.123 106.347,25.054 106.347,42.329 0,17.272 -47.646,26.34 -106.347,20.217 z m 0,-124.661 -625.726,-65.11 v -62.593 l 625.726,65.11 c 58.701,6.118 106.347,25.104 106.347,42.373 0,17.324 -47.646,26.338 -106.347,20.22 z"
    />
    <path
      className="cherry main-outline"
      d="m 399.96,305.114 c 0,15.768 2.721,30.899 7.701,44.961 18.506,52.241 68.341,89.666 126.924,89.666 74.353,0 134.626,-60.274 134.626,-134.626 0,-4.971 -0.277,-9.876 -0.803,-14.708 -7.328,-67.432 -64.441,-119.918 -133.823,-119.918 -7.086,0 -14.042,0.551 -20.832,1.606 0,0 -20.4655,2.53341 -41.857,13.862 -42.774,22.55 -71.936,67.444 -71.936,119.157 z"
    />
  </svg>
);
