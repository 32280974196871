import styled from 'styled-components';
import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from '@/components/DybrComponents/StyledServicePagesElements';
import { getAvatar } from '@/utils/helpers/profiles';
import React from 'react';
import { updateProfileSetting } from '@/store/user/thunks/updateProfileSetting';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { connect } from 'react-redux';
import { upload } from '@/utils/helpers/fileUploads';
import { useDropzone } from 'react-dropzone';
import { useDropzoneStyle } from '@/scenes/User/Settings/Blog/Dropzone.style';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

const StyledAvatarWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const StyledAvatar = styled.div`
  flex-shrink: 0;

  background: none;
  width: 120px;
  height: 120px;
  position: relative;
  background-image: url('${(props) => props.img}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;
const StyledAvatarUploader = styled.div`
  margin-left: 10px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  .dropzone {
    height: 120px;

    background: ${(p) => p.theme.backgroundLight};
    border: 2px dashed ${(p) => p.theme.divider};
    border-radius: 0;
    div {
      padding: 48px 10px;
      text-align: center;
    }
  }
  .dropzone-reject {
    border-color: ${(props) => props.theme.brand};
  }
`;
const MAX_FILE_SIZE = 1024 * 400; // in bytes, 300Kb
const ALLOWED_FILE_TYPE = 'image/png, image/jpeg, image/gif';

const handleDropRejected = (fileRejections) => {
  const rejection = fileRejections[0];

  if (!rejection.file) {
    window.alert(`Случилось что-то непредвиденное: файл не найден`);
    return;
  }

  if (rejection.file.size > MAX_FILE_SIZE) {
    window.alert(
      `Превышен максимальный размер в 300Kб (${Math.ceil(
        rejection.file.size / 1024 / 1024
      )}).`
    );
  } else if (rejection.file.type !== ALLOWED_FILE_TYPE) {
    window.alert(`Разрешены только .png, .jpeg или .gif файлы.`);
  } else {
    window.alert(
      `Случилось что-то непредвиденное, обратитесь к разработчикам, предоставив информацию ниже:\n${rejection.errors
        .map((error) => `Message: ${error.message} Code: ${error.code}`)
        .join('\n')}`
    );
  }
};

function AvatarUpload({ profile, updateProfileSetting }) {
  const [token] = useTokenState();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: ALLOWED_FILE_TYPE,
    maxSize: 500000,
    maxFiles: 1,
    onDropAccepted: (files, event) => {
      //console.log(accepted);

      upload(files, token, (link) => {
        updateProfileSetting('avatar', link, token);
      });
    },
    onDropRejected: handleDropRejected
  });
  const { style } = useDropzoneStyle(isDragActive, isDragAccept, isDragReject);

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Аватар</h4>
          <p>
            Можно загрузить картинку. А потом можно будет загрузить много. Но
            пока одну. Большие файлы пока не принимаются, лимит 300 Кб.
          </p>
        </SettingSectionTitle>
        <Settings>
          <StyledAvatarWrapper>
            <StyledAvatar img={getAvatar(profile)} />
            <StyledAvatarUploader>
              <div {...getRootProps({ className: 'dropzone', style })}>
                <input {...getInputProps()} />
                <div>загрузить</div>
              </div>
            </StyledAvatarUploader>
          </StyledAvatarWrapper>
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(AvatarUpload)
);
