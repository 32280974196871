import styled from 'styled-components';
import { BlogDisplayStyled } from '@/components/Shared/BlogDisplay.style';

import { ARROW_HEIGHT } from '@/components/Shared/constants';
import { Link } from 'react-router-dom';

export const IconButton = styled(Link)`
  position: relative;
  cursor: pointer;
  display: flex;
  border: none;
  background-color: transparent;
  justify-content: center;
  align-items: center;

  .icon {
    .main-outline,
    .filling-outline,
    .filling-area {
      fill: ${props => props.theme.text};
      transition: opacity 0.3s;
    }
    .filling-area,
    .secondary-filling-area {
      opacity: 0;
    }
  }

  &:hover .icon,
  .icon.icon-active {
    .filling-area {
      opacity: 1;
    }
  }
`;

export const ProfileNotFoundContainer = styled.div`
  padding: 16px 0px 24px 0px;
`;

export const ProfileNotFoundTitle = styled.p``;
export const ProfileNotFoundSubtitle = styled.p`
  font-size: 14px;
  color: ${props => props.theme.textLight};
  padding-top: 10px;

  a {
    display: block;
    font-size: 14px;
  }
`;

export const Wrapper = styled.div`
  margin: 0px 8px 8px 10px;
  width: 320px;
  height: auto;
  padding-top: ${ARROW_HEIGHT}px;
  text-align: center;
  z-index: 2;

  background: ${props => props.theme.backgroundLighter};
  background-color: ${p => p.theme.backgroundLighter};

  ${BlogDisplayStyled} {
    min-height: 135px;
  }

  .icon {
    .main-outline,
    .filling-outline,
    .filling-area {
      fill: ${props => props.theme.backgroundDarker};
      transition: opacity 0.3s;
    }
    .filling-area,
    .secondary-filling-area {
      opacity: 0;
    }
  }

  .icon-profile,
  .icon-journal,
  .icon-favorites {
    width: 25px;
  }
  .icon-journal {
    position: relative;
    top: 2px;
    height: 29px;
  }
  .icon-block-user,
  .icon-watch-thread {
    width: 21px;
    height: 21px;
  }

  .icon.icon-block-user .main-outline,
  .icon.icon-watch-thread .main-outline {
    fill: ${p => p.theme.brand};
  }
  .icon.icon-block-user,
  .icon.icon-watch-thread {
    margin-right: 8px;
  }

  ul.icon-positioning {
    height: 30px;
    display: flex;
    margin: 20px 20px 0px 20px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid ${props => props.theme.divider};

    li {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &.small {
    width: 150px;
    ul.icon-positioning {
      border-bottom: none;
      padding-bottom: 30px;
    }
  }
`;

export const ListsDisplay = styled.div`
  font-size: 14px;
  margin-bottom: 20px;

  ul.list-positioning {
    margin: 0px 20px 20px 20px;
    display: flex;
    width: 280px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .list-positioning li {
    margin-bottom: 5px;
    margin-right: 10px;
    height: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.backgroundLight};
  }
  .list-positioning li:first-child {
    background-color: transparent;
    color: ${props => props.theme.textLight};
    margin-right: 0px;
  }

  button.menu-add-to-list {
    background-color: ${props => props.theme.backgroundDark} !important;
    color: ${p => p.theme.backgroundLighter};
    padding: 10px 15px;
    margin: 0px;
    font-size: 14px;
    font-weight: normal;
  }
`;

export const BlockUserDisplay = styled.div`
  ${
    /*border-top: 1px solid ${props => props.theme.divider}; */
    /*enable if no blog element is shown*/
    '' /* empty string to remove syntax error */
  }

  min-height: 20px;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  .block-user-link,
  .show-in-feed {
    display: flex;
    text-decoration: none;
    font-size: 14px;
    margin: 25px 0;
    padding: 0px;

    .icon-block-user {
      opacity: 0.5;
    }
    color: ${p => p.theme.textLight};
  }
  .block-user-link:hover,
  .show-in-feed:hover {
    .icon-block-user {
      opacity: 1;
    }
    color: ${p => p.theme.text};
  }
  .block-user-link span,
  .show-in-feed span {
    margin-top: 1px;
  }
`;
