import moment from 'moment';
import { BirthdayCakeIcon } from '@/styles/Icons/BirthdayCake';
import { Cake } from './styled/StyledCake';

type BirthdayIconProps = {
  birthday: string;
  nick: string;
};

export const BirthdayIcon = ({ birthday, nick }: BirthdayIconProps) => {
  if (!birthday) return null;

  const today = moment();
  const birth = moment(birthday);
  return (
    birth.month() === today.month() &&
    birth.date() === today.date() && (
      <Cake className="birthday-icon" title={`У ${nick} сегодня День Торта`}>
        <BirthdayCakeIcon />
      </Cake>
    )
  );
};
