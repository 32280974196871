import isPropValid from '@emotion/is-prop-valid';
import axios from 'axios';
import moment from 'moment';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';

import './index.css';

import 'font-awesome/css/font-awesome.css';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { StyleSheetManager } from 'styled-components';
import { createContainer } from 'unstated-next';

import { AppContainer } from './App';
import { store as reduxStore } from './app/store';
import ErrorBoundary from './components/Helpers/ErrorBoundary';

import 'moment/dist/locale/ru';

import { API_URL } from './configs/dybr';
import { unregister } from './registerServiceWorker';
import PrivacyListProvider from './scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';
import { StateMonitorContainer } from './StateMonitor';
import theme from './styles/DybrTheme';

moment.locale('ru');

unregister(); // disable service workers

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/vnd.api+json';
axios.defaults.headers.patch['Content-Type'] = 'application/vnd.api+json';
axios.defaults.timeout = 1000 * 60 * 5;

let Counter = createContainer();

/* Sentry */
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  maxBreadcrumbs: 50,
  debug: import.meta.env.DEV,
  release: 'dybr-client@' + import.meta.env.VITE_DYBR_VERSION,
  environment: import.meta.env.MODE,
  sampleRate: 1,
  initialScope: { user: {} }
});

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <ReduxProvider store={reduxStore}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ErrorBoundary>
              {/* <Counter.Provider> */}
              <StateMonitorContainer>
                <PrivacyListProvider>
                  <AppContainer />
                </PrivacyListProvider>
              </StateMonitorContainer>
              <div id="modal-root" />
              {/* </Counter.Provider> */}
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeProvider>
      </StyleSheetManager>
    </ReduxProvider>
  </StrictMode>
);

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
