import React from 'react';
import { Link } from 'react-router-dom';
import defaultBlogDesign from '@/configs/defaultBlogDesign';

import { AddToFavoritesContainer } from '@/components/Shared/AddToFavorites';
import { BlogDisplayStyled } from '@/components/Shared/BlogDisplay.style';
import { useTokenState } from '@/store/localStorage/useTokenState';

function BlogDisplay({ profile, design }) {
  const [token] = useTokenState();
  const isUserLoggedIn = Boolean(token);

  return (
    <BlogDisplayStyled design={design ?? defaultBlogDesign}>
      <Link
        className="blog-title"
        to={`/blog/${profile.blogSlug}`}
        title="блог"
      >
        {profile.blogTitle}
      </Link>
      {isUserLoggedIn && <AddToFavoritesContainer profile={profile} />}
    </BlogDisplayStyled>
  );
}

export default BlogDisplay;
