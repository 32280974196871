import React, { useContext, useRef } from 'react';
import useSelectionQuote from '@/utils/hooks/useSelectionQuote';

const EntryPageContext = React.createContext();
EntryPageContext.displayName = 'EntryPageContext';

export function useEntryPageContext() {
  const context = useContext(EntryPageContext);
  if (context === undefined) {
    throw new Error(
      `useEntryPageContext must be used within a EntryPageProvider`
    );
  }
  return context;
}

export function EntryPageProvider({ children }) {
  const editorRef = useRef();
  const formRef = useRef();

  const selection = useSelectionQuote();

  const insertReply = (id = '', profileId, nickname) => {
    const quote = selection.getQuote(id);

    editorRef.current.html.set(
      `${editorRef.current.html.get()}${
        quote ? `<blockquote>${quote}</blockquote>` : ''
      }<a href="/profile/${profileId}" rel='author' data-reply-link="#${id}">${nickname}</a>,&nbsp;`
    );

    editorRef.current.events.focus(true);
    editorRef.current.selection.setAtEnd(editorRef.current.$el.get(0));
    editorRef.current.selection.restore();

    formRef.current.scrollIntoView();
  };

  const handleCommentEdit = content => {
    editorRef.current.events.focus(true);
    editorRef.current.html.set(content);
    editorRef.current.selection.setAtEnd(editorRef.current.$el.get(0));
    editorRef.current.selection.restore();
    formRef.current.scrollIntoView();
  };

  const value = {
    editorRef,
    formRef,
    insertReply,
    handleCommentEdit
  };

  return (
    <EntryPageContext.Provider value={value}>
      {children}
    </EntryPageContext.Provider>
  );
}
