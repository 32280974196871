import { selectUserSettings } from '@/store/user/selectors/selectUserSettings';
import setFieldUpdeep from '@/utils/helpers/setFieldUpdeep';
import { updateUserApi } from '@/api/users';
import { SetUserSettingsAction } from '@/store/user/actions/SetUserSettingsAction';

export const updateUserSetting = (key, value, token, userId) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const currentSettings = selectUserSettings(state);

  const newSettings = setFieldUpdeep(currentSettings, key, value);
  const result = await updateUserApi({
    data: {
      settings: newSettings
    },
    userId,
    token
  });

  if (!result.error) {
    dispatch(new SetUserSettingsAction(newSettings));
    return result;
  } else {
    // dispatch error
    return result;
  }
};
