import { Routes, Route } from 'react-router-dom';

import NotFound from '@/scenes/Dybr/Static/NotFound';
import Footer from '@/scenes/Footer/Footer';
import ManageCommunity from '@/scenes/Management/Community/ManageCommunity';

function ManagementScenes() {
  return (
    <>
      <Routes>
        <Route path="community" element={<ManageCommunity />} />
        <Route
          path="*"
          element={<NotFound reason="Аккаунт: что-то пошло не так" />}
        />
      </Routes>
      <Footer withLemur />
    </>
  );
}

export default ManagementScenes;
