import React, { useEffect } from 'react';

import { ContentWrapper } from '@/components/DybrComponents/StyledServicePagesElements';
import AvatarUpload from '@/scenes/User/Settings/Blog/ProfileSettings/AvatarUpload';
import NicknameSettings from '@/scenes/User/Settings/Blog/ProfileSettings/NicknameSetting';
import AboutSettings from '@/scenes/User/Settings/Blog/ProfileSettings/AboutSection';

function ProfileSettings() {
  useEffect(() => {
    document.title = `Настройки профиля`;
  }, []);

  return (
    <ContentWrapper>
      <NicknameSettings />
      <AboutSettings />
      <AvatarUpload />
    </ContentWrapper>
  );
}

export default ProfileSettings;
