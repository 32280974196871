import React from 'react';
import { AccessType } from '@/utils/privacy/types/AccessType';

const dev = import.meta.env.DEV;

export const SLON_MODE = import.meta.env.VITE_DYBR_MODE === 'slonopotam';

export const HOSTNAME = window.location.origin;

export const API_URL = import.meta.env.VITE_API_ENDPOINT;

export const IMG_HOSTNAME = dev ? import.meta.env.VITE_IMG_HOSTNAME : '';

export const dybrConfigs = {
  dybrNewsId: 131,
  MAX_LENGTH: {
    blogTitle: 50,
    blogSlug: 20,
    nickname: 50,
    subtext: 150,
    crosspost: {
      tag: 150,
      footer: 200
    }
  },
  NOTIFICATION_LOAD_INTERVAL: 3 * 60000,
  CHECK_TOKEN_INTERVAL: 5 * 60000,
  EXPIRATION_BUFFER: 0 /* Previous value was 24 * 60 * 60 */
};

export const LIMIT_DURATION_FOR_COMMENT_EDIT = false;
export const LIMIT_DURATION_FOR_COMMENT_EDIT_IN_MINUTES = 15;

export const UNSUPPORTED_EMAIL_DOMAIN_LIST = ['hotmail.com', 'outlook.com'];

const customListsOptions = [
  {
    label: 'открыта спискам',
    value: { type: AccessType.allowCustomList }
  },
  {
    label: 'закрыта спискам',
    value: { type: AccessType.denyCustomList }
  }
];

export const entryPrivacySettingsTypes = [
  {
    label: 'как у дневника',
    value: null
  },
  {
    label: 'закрыта от всех',
    value: { type: AccessType.private }
  },
  ...customListsOptions,
  {
    label: 'закрыта от незарегистрированных',
    value: { type: AccessType.registered }
  },
  {
    label: 'открыта для читателей',
    value: { type: AccessType.subscribers }
  },
  {
    label: 'открыта для избранных',
    value: { type: AccessType.favorites }
  }
];

export const communityEntryPrivacySettingsTypes = [
  {
    label: 'как у сообщества',
    value: null // или вообще пустое
  },
  {
    label: 'закрыта от незарегистрированных',
    value: { type: AccessType.registered }
  },
  ...customListsOptions,
  {
    label: 'открыта для читателей и участников',
    value: { type: AccessType.subscribers }
  },
  {
    label: 'открыта для участников',
    value: { type: AccessType.participants }
  }
];

export const communityOwnerEntryPrivacySettings = [
  {
    label: 'открыта только для админов',
    value: { type: AccessType.admins }
  },
  {
    label: 'закрыта от всех',
    value: { type: AccessType.private }
  }
];

export const communityAllEntryPrivacySettings = [
  ...communityEntryPrivacySettingsTypes,
  ...communityOwnerEntryPrivacySettings
];

export default dybrConfigs;
