import React, { useEffect } from 'react';

import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

import { ContentWrapper } from '@/components/DybrComponents/StyledServicePagesElements';
import { ArchiveSettings } from '@/scenes/User/Settings/Blog/ArchiveSettings';
import { BlogTitleContainer } from '@/scenes/User/Settings/Blog/BlogTitle';
import { DesignsBlockContainer } from '@/scenes/User/Settings/Blog/DesignsBlock';
import { CrosspostSettings } from '@/scenes/User/Settings/SettingsCrosspost';
import CommunityPostSettings from '@/scenes/User/Settings/Blog/CommunityPostSettings';
import { ChangeBlogContainer } from './ChangeBlogSettings';
import { DeleteBlogContainer } from './DeleteBlogSettings';

export function BlogSettingsScene() {
  useEffect(() => {
    document.title = `Настройки блога`;
  }, []);

  return (
    <ContentWrapper data-testid="common-settings">
      <BlogTitleContainer />
      <CommunityPostSettings />
      <DesignsBlockContainer />
      <ArchiveSettings />
      <CrosspostSettings />
      <ChangeBlogContainer />
      <DeleteBlogContainer />
    </ContentWrapper>
  );
}
