import { updateNotificationAPI } from '@/api/notifications';
import { selectNotificationIdsByCommentIds } from '@/store/notifications/selectors/selectNotificationIds';
import { MarkNotificationReadAction } from '@/store/notifications/actions/MarkNotificationReadAction';

export const readComments = ({ commentIds, token, activeProfileId }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const notificationIds = selectNotificationIdsByCommentIds(state, {
    commentIds
  });

  for (const notificationId of notificationIds) {
    const res = await updateNotificationAPI({
      profileId: activeProfileId,
      data: {
        id: notificationId,
        state: 'read'
      },
      token
    });
    if (!res.error) {
      dispatch(new MarkNotificationReadAction(notificationId));
    } else {
      // dispatch error
    }
  }
};
