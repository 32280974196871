import styled from 'styled-components';
import { rgbaObjectToString } from '@/utils/helpers/colors';
import theme from '@/styles/DybrTheme';

export const ColorStyled = styled.div`
  width: 50px;
  height: 30px;
  border-radius: 0px;
  background: ${p => rgbaObjectToString(p.rgba)};
`;

export const SwatchSectionStyled = styled.div`
  padding: 5px;
  background: ${theme.backgroundLight};
  border-radius: 0px;
  display: inline-block;
  cursor: pointer;
  border: 4px solid ${p => p.theme.backgroundDark};
`;
