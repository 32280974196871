import React, { useState } from 'react';
import * as css from '@/components/Shared/Collapse.module.css';
import PlusForExpand from '@/styles/Icons/PlusForExpand';
import MinusForContract from '@/styles/Icons/MinusForContract';
import { classNames } from '@/utils/helpers/classNames';

function Collapse({ header, defaultCollapsed = true, children }) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  return (
    <div>
      <div className={css.header}>
        <div
          className={css.collapseClickableArea}
          onClick={() => setCollapsed(!collapsed)}
        >
          <div className={css.collapseIcon}>
            {collapsed ? <PlusForExpand /> : <MinusForContract />}
          </div>
        </div>
        {header}
      </div>
      <div
        className={classNames({
          [css.bodyCollapsed]: collapsed
        })}
      >
        {children}
      </div>
    </div>
  );
}

export default Collapse;
