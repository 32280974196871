import React, { useEffect, useState } from 'react';
import TextField from '@/components/FormElements/TextField';
import queryString from 'query-string';

import {
  ButtonWrapper,
  ErrorWrapper,
  FormWrapper
} from './components/StyledForm';
import { ActionButton } from '@/components/Shared/Buttons';

import { Section } from '@/components/DybrComponents/Section';
import { changePassword } from '@/store/user/thunks/changePassword';
import { connect } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';

import { useTokenState } from '@/store/localStorage/useTokenState';
import { fetchUserThunk } from '@/store/user/thunks/fetchUserThunk';
import { parseToken } from '@/store/user/reducer/parseToken';

function ResetPassword({ changePassword, fetchUserThunk }) {
  const navigate = useNavigate();
  const location = useLocation();

  const params = queryString.parse(location.search);

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [, setToken] = useTokenState();

  const passwordToken = params.token;

  useEffect(() => {
    document.title = `Сброс пароля - Дыбр`;
  }, []);

  useEffect(() => {
    if (!passwordToken) {
      setError('неверная ссылка');
    }
  }, [passwordToken]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setError('пароли не совпадают');
      return;
    }
    const res = await changePassword({
      password,
      token: passwordToken,
      setToken
    });

    if (res.error) {
      setError(res.error);
    } else {
      setSuccess(true);
      setTimeout(() => {
        fetchUserThunk({
          setToken,
          token: res.accessToken,
          ...parseToken(res.accessToken),
          navigate
        });
      }, 1000);
    }
  }

  return (
    <Section withBackground noPadding>
      <FormWrapper>
        {success ? (
          <>
            <h2>Пароль успешно изменён!</h2>

            <ActionButton onClick={() => navigate('/')}>
              на главную
            </ActionButton>
          </>
        ) : (
          <>
            <h2>Смена пароля</h2>
            <ErrorWrapper show={error}>{error}</ErrorWrapper>
            <form onSubmit={handleSubmit}>
              <div />

              <TextField
                value={password}
                onChange={e => {
                  setError('');
                  setPassword(e.target.value);
                }}
                meta={{}}
                id="pass"
                label="новый пароль"
                type="password"
              />
              <TextField
                type="password"
                value={passwordConfirmation}
                onChange={e => {
                  setError('');
                  setPasswordConfirmation(e.target.value);
                }}
                meta={{}}
                id="pass2"
                label="пароль ещё раз"
              />
              <ButtonWrapper>
                <ActionButton type="submit">продолжить</ActionButton>
              </ButtonWrapper>
              <div />
              <div />
            </form>
          </>
        )}
      </FormWrapper>
    </Section>
  );
}

const mapDispatchToProps = {
  changePassword,
  fetchUserThunk
};

export const ResetPasswordContainer = connect(
  null,
  mapDispatchToProps
)(ResetPassword);
