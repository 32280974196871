import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MainLoggedInContainer } from './Main/MainLoggedIn';
import About from './Static/About';
import Contacts from './Static/Contacts';
import Terms from './Static/Terms';
import Help from './Static/Help/Help';
import NotFound from '@/scenes/Dybr/Static/NotFound';
import Footer from '@/scenes/Footer/Footer';
import { selectIsSignupIncomplete } from '@/store/user/selectors/selectIsSignupIncomplete';
import { connect } from 'react-redux';
import { ProfileContainer } from '@/scenes/Dybr/Users/ProfilePage/Profile';
import { MainLoggedOut } from '@/scenes/Dybr/Main/MainLoggedOut';
import FeedScenes from '@/scenes/Dybr/Feeds/FeedScenes';
import { useTokenState } from '@/store/localStorage/useTokenState';
import SearchScene from './Feeds/SearchScene';
import ForbiddenActions from './Static/TermsDetails/ForbiddenActions';

export function Dybr({ isSignupIncomplete }) {
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const creatingFirstProfile = isLoggedIn && isSignupIncomplete;

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn && !isSignupIncomplete ? (
              <MainLoggedInContainer />
            ) : (
              <MainLoggedOut />
            )
          }
        />
        {!creatingFirstProfile && (
          <Route path="feed/*" element={<FeedScenes />} />
        )}
        {!creatingFirstProfile && (
          <Route path="search/*" element={<SearchScene />} />
        )}
        {!creatingFirstProfile && (
          <Route path="profile/:pid" element={<ProfileContainer />} />
        )}

        <Route path="about" element={<About />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="terms" element={<Terms />} />        
        <Route path="terms/forbidden-actions" element={<ForbiddenActions />}/>
        <Route path="help" element={<Help />} />
        <Route
          path="*"
          element={<NotFound reason={'Dybr scene: не можем найти страницу'} />}
        />
      </Routes>
      <Footer withLemur />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  isSignupIncomplete: selectIsSignupIncomplete(state)
});

export const DybrContainer = connect(mapStateToProps)(Dybr);
