import React, { useState } from 'react';
import { ActionButton } from '@/components/Shared/Buttons';
import { getDybrArchive } from '@/api/profiles';
import fileDownload from 'js-file-download';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { connect } from 'react-redux';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

export function ArchiveDownload({ profileId }) {
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [token] = useTokenState();

  async function getArchive(profileId) {
    setLoading(true);
    setStartTime(Date.now());
    const data = await getDybrArchive({ profileId, token });
    if (!data.error) {
      const filename = 'dybr_blog_' + profileId + '.zip';
      fileDownload(data, filename);
    } else {
      window.alert(
        'Проблема при загрузке, попробуйте еще раз или обратитесь к администрации'
      );
    }
    setLoading(false);
  }

  const waitingTime = startTime && (Date.now() - startTime) / 1000;

  return (
    <>
      <p>Вы можете скачать архив своего блога в формате json:</p>
      {loading && waitingTime > 1 ? (
        <p>
          <strong>Архив создается, не закрывайте страницу</strong>
        </p>
      ) : (
        <p> </p>
      )}
      <ActionButton
        onClick={() => {
          getArchive(profileId);
        }}
        disabled={loading}
      >
        Скачать архив
      </ActionButton>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profileId: selectActiveProfileAugmented(state, { activeProfileId }).id
  };
};

export const ArchiveDownloadContainer = WithActiveProfileId(
  connect(mapStateToProps)(ArchiveDownload)
);
