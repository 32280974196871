import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { DybrContainer } from '@/scenes/Dybr/_DybrScene';
import { BlogContainer } from '@/scenes/Blog/_BlogScene';
import { EntryRedirectContainer } from '@/scenes/Blog/_EntryRedirect';
import { AccountContainer } from '@/scenes/Account/_AccountScene';
import SettingsPage from '@/scenes/User/SettingsPage';
import NotFound from '@/scenes/Dybr/Static/NotFound';

import { loadLists } from '@/store/lists/operations';
import {
  loadNotificationEntries,
  loadNotifications
} from '@/store/notifications/operations';
import { loadUserById } from '@/store/user/thunks/loadUserById';
import { selectIsSignupIncomplete } from '@/store/user/selectors/selectIsSignupIncomplete';
import { HeaderContainer } from '@/scenes/Header/_HeaderScene';
import { selectNotificationEntryIDs } from '@/store/notifications/selectors';
import config, { SLON_MODE } from '@/configs/dybr';
import { ProduceError } from '@/scenes/Dybr/Service/ProduceError';
import { checkTokenThunk } from '@/store/user/thunks/checkTokenThunk';
import { TestCommentsContainer } from '@/scenes/Dybr/Service/TestComments';
import ManagementScenes from '@/scenes/Management/ManagementScenes';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';
import Loading from '@/components/Shared/Loading';
import { ConfirmEmailChange } from '@/scenes/User/Settings/ConfirmEmailChange';
import ErrorLog from '@/scenes/Dybr/Static/ErrorLog';
import { ConversationContainer } from '@/scenes/Dybr/Conversations/Conversation';
import { ConversationListContainer } from '@/scenes/Dybr/Conversations/ConversationList';

export const AppWrapper = styled.div`
  min-height: 100vh;
  display: block; /*for IE*/
  min-width: 320px;
  position: relative;
  background: ${p => p.theme.backgroundLighter};
`;

export function App({
  isSignupIncomplete,
  loadNotifications,
  loadNotificationEntries,
  loadLists,
  loadUserById,
  notificationEntryIds,
  checkTokenThunk
}) {
  const navigate = useNavigate();
  const { userId, activeProfileId } = useActiveProfile();
  const [token, setToken] = useTokenState();
  const isLoggedIn = Boolean(token);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await loadUserById({
        token,
        setToken,
        userId,
        activeProfileId,
        navigate
      });
      setLoading(false);
    }

    fetchData();
  }, [loadUserById]);

  useEffect(() => {
    if (token && activeProfileId !== '0') {
      loadLists({ profileId: activeProfileId, token });
    }
  }, [token, activeProfileId, loadLists, isLoggedIn]);

  useEffect(() => {
    loadNotifications({ token, activeProfileId });
  }, [activeProfileId, loadNotifications]);

  useEffect(() => {
    loadNotificationEntries({ entryIds: notificationEntryIds, token });
  }, [notificationEntryIds, loadNotificationEntries]);

  const creatingFirstProfile = isLoggedIn && isSignupIncomplete;

  useEffect(() => {
    checkTokenThunk({ token, setToken });
    const interval = setInterval(() => {
      return checkTokenThunk({ token, setToken }); // todo check if it will be renewed
    }, config.CHECK_TOKEN_INTERVAL);
    return () => clearInterval(interval);
  }, [token]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AppWrapper data-testid="app-wrapper">
      <Routes>
        <Route path="*" element={<HeaderContainer />} />
      </Routes>

      <Routes>
        <Route
          path="/*"
          element={<DybrContainer data-testid="dybr-container" />}
        />
        {!creatingFirstProfile && (
          <Route path="/blog/:slug/*" element={<BlogContainer />} />
        )}
        {!creatingFirstProfile && (
          <Route path="/entry/:eid" element={<EntryRedirectContainer />} />
        )}
        {!creatingFirstProfile && (
          <Route path="/settings/*" element={<SettingsPage />} />
        )}
        <Route path="/account/*" element={<AccountContainer />} />
        <Route path="/manage/*" element={<ManagementScenes />} />
        <Route path="/errorlog" element={<ErrorLog />} />
        <Route path="/direct" element={<ConversationListContainer />} />
        <Route path="/direct/:targetProfileId" element={<ConversationContainer/>} />
        {isLoggedIn && (
          <Route
            path="/account/confirmation/:emailToken"
            element={<ConfirmEmailChange />}
          />
        )}
        {SLON_MODE && <Route path="/error" element={<ProduceError />} />}
        {SLON_MODE && (
          <Route path="/testcomments" element={<TestCommentsContainer />} />
        )}
        <Route
          path="*"
          element={<NotFound reason={'App: Не можем найти такую страницу'} />}
        />
      </Routes>
    </AppWrapper>
  );
}

const mapDispatchToProps = {
  loadLists,
  loadNotifications,
  loadNotificationEntries,
  loadUserById,
  checkTokenThunk
};

const mapStateToProps = state => ({
  isSignupIncomplete: selectIsSignupIncomplete(state),
  notificationEntryIds: selectNotificationEntryIDs(state)
});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
