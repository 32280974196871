import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from '@/components/FormElements/Select';
import {
  BirthdayBlock,
  ErrorText,
  Meters,
  Title,
  TitleBlock
} from './BirthdaySettingStyled';

export const BirthdaySetting = ({ birthday, onChange }) => {
  const [value, setValue] = useState(getDayAndMonthIndex(birthday));
  const [unsetValue, setUnsetValue] = useState(null);

  const getMonths = () => [
    { value: -1, label: 'месяц' },
    ...moment.months().map((v, i) => {
      return { value: i, label: v };
    })
  ];

  function getDaysInMonth(month) {
    return moment({ y: 2000, M: month ? month - 1 : 0 }).daysInMonth();
  }

  const getDays = () => {
    const daysInMonth = getDaysInMonth(value.month);
    return [
      { value: 0, label: 'число' },
      ...Array.from(Array(daysInMonth), (label, index) => {
        return { value: index + 1, label: index + 1 };
      })
    ];
  };

  function getDayAndMonthIndex(birthday) {
    const value = { day: 0, month: 0 };
    if (birthday) {
      const mom = moment(birthday);
      if (mom.isValid()) {
        value.day = mom.date();
        value.month = mom.month() + 1;
      }
    }
    return value;
  }

  useEffect(() => {
    const dayAndMonthIndex = getDayAndMonthIndex(birthday);
    value.month = dayAndMonthIndex.month;
    value.day = dayAndMonthIndex.day;
    setValue(value);
  }, [birthday]);

  useEffect(() => {
    //Correct day if possible
    const unsetDay = value.day <= 0;
    const unsetMonth = value.month <= 0;
    if (!unsetDay && getDaysInMonth(value.month) < value.day) {
      setValue({ ...value, day: getDaysInMonth(value.month) });
      return;
    }

    //Handle not valid cases
    if (unsetDay && !unsetMonth) {
      setUnsetValue('day');
      return;
    }
    if (unsetMonth && !unsetDay) {
      setUnsetValue('month');
      return;
    }

    //Handle valid cases
    if (unsetDay && unsetMonth) {
      setUnsetValue(null);
      onChange('');
      return;
    }
    if (!unsetDay && !unsetMonth) {
      setUnsetValue(null);
      const mom = moment({ y: 2000, M: value.month - 1, d: value.day });
      onChange(mom.format('YYYY-MM-DD'));
      return;
    }
  }, [value]);

  function getSelectClass(field) {
    if (unsetValue === field) {
      return 'error';
    }
    return value[field] === 0 ? 'unset' : 'valid';
  }

  return (
    <BirthdayBlock>
      <TitleBlock>
        <Title>день рождения</Title>
      </TitleBlock>
      <Meters>
        <Select
          options={getDays()}
          className={['setting-select', getSelectClass('day')]
            .filter(Boolean)
            .join(' ')}
          placeholder="число"
          onChange={day => setValue({ ...value, day })}
          selectedOptionID={value.day}
        />
        <Select
          options={getMonths()}
          className={['setting-select', getSelectClass('month')]
            .filter(Boolean)
            .join(' ')}
          placeholder="месяц"
          onChange={month => setValue({ ...value, month })}
          selectedOptionID={value.month}
        />
        <ErrorText>
          {unsetValue &&
            `Пожалуйста, укажите ${unsetValue === 'day' ? 'число' : 'месяц'}`}
        </ErrorText>
      </Meters>
    </BirthdayBlock>
  );
};
