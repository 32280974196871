import { merge } from 'lodash-es';
import { createSelector } from 'reselect';

import defaultProfileSettings from '@/configs/defaultProfileSettings';

import { selectUser } from './selectUser';

export const selectActiveProfileAugmented = createSelector(
  selectUser,
  (state, { activeProfileId }) => activeProfileId,
  (user, activeProfileId) => {
    const profileStub = { settings: defaultProfileSettings };

    if (!Object.keys(user.profiles).length) return profileStub;
    if (!activeProfileId) return profileStub;

    const profile = user.profiles[activeProfileId] || profileStub;
    return {
      ...profile,
      settings: merge({}, defaultProfileSettings, profile.settings)
    };
  }
);
