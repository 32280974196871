import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from '@/components/Shared/Loading';
import { loadEntryToRedirect } from '@/store/notifications/operations/loadEntryToRedirect';
import { useTokenState } from '@/store/localStorage/useTokenState';
import { useParams } from 'react-router-dom';


function EntryRedirect({ loadEntryToRedirect }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [token] = useTokenState();

  const { eid } = useParams();

  useEffect(() => {
    loadEntryToRedirect({ eid, location, token, navigate });
  }, [token, eid, location, loadEntryToRedirect]);

  return <Loading />;
}

const mapDispatchToProps = {
  loadEntryToRedirect
};

export const EntryRedirectContainer = connect(
  null,
  mapDispatchToProps
)(EntryRedirect);
