import { loadUserById } from '@/store/user/thunks/loadUserById';

import { selectIsSignupIncomplete } from '@/store/user/selectors/selectIsSignupIncomplete';

// todo: looks like it can be easily replaced by loadUserById thunk...
export const fetchUserThunk =
  ({ setToken, token, userId, activeProfileId, navigate }) =>
  async (dispatch, getState) => {
    await loadUserById({ setToken, token, userId, activeProfileId, navigate })(
      dispatch,
      getState
    );

    // todo maybe it should be moved to the Main component as it is more about presentation layer
    const isSignupIncomplete = selectIsSignupIncomplete(getState());
    if (isSignupIncomplete) {
      navigate('/account/create');
    }
  };
