import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getEntryApi } from '@/api/entries';
import Loading from '@/components/Shared/Loading';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { publishEntry } from '@/store/entries/thunks/publishEntry';
import {
  EntryEditProvider,
  useEntryEditContext
} from '@/scenes/Blog/EntryEdit/EntryEditContext';
import { EntryEditContainer } from '@/scenes/Blog/EntryEdit/EntryEdit';
import { PreviewEntry } from '@/scenes/Blog/EntryEdit/PreviewEntry';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import { useEntryDraftById } from '@/store/localStorage/drafts/useEntryDraftById';
import { useSetEntryDraft } from '@/store/localStorage/drafts/useSetEntryDraft';

function EntryEditView({
  profile,
  hotUpdateTagList,
  unpinEntryIfPinned,
  pinEntry,
  publishEntry,
  blog
}) {

  const { eid, slug } = useParams();

  const navigate = useNavigate();
  const [authorProfile, setAuthorProfile] = useState(profile);
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();
  // TODO maybe it should be stored in a state variable, maybe saving will be faster
  const entryDraft = useEntryDraftById({ activeProfileId, entryId: eid, blog });
  const saveEntryDraft = useSetEntryDraft();

  useEffect(() => {
    if (eid && eid !== '0') {
      document.title = `Редактирование записи - ${blog.blogTitle} - Дыбр`;
    } else {
      document.title = `Новая запись - ${blog.blogTitle} - Дыбр`;
    }
  }, [blog.blogTitle, eid]);

  const {
    loading,
    setLoading,
    setSubmitting,
    error,
    setError,
    preview,
    setEntryContent,
    entryTitle,
    setEntryTitle,
    tags,
    setTags,
    entrySettings,
    setEntrySettings,
    isPinned,
    setIsPinned,
    updatePublishAt
  } = useEntryEditContext();

  useEffect(() => {
    async function loadEntry() {
      setError('');
      setLoading(true);

      const { data: entry, error } = await getEntryApi({
        entryId: eid,
        include: 'profile',
        token
      });

      if (entry === undefined || error) {
        setError(error ?? 'что-то пошло не так');
      } else {
        if (entry.profile.id !== profile.id && blog.id !== profile.id) {
          setError('чужую запись редактировать нельзя');
          setLoading(false);
          return;
        }

        setAuthorProfile(entry.profile);
        setEntryContent(entryDraft.content || entry.content || '');
        setEntryTitle(entryDraft.title || entry.title || '');
        setTags(entryDraft.tags || entry.tags || []);
        setEntrySettings({
          feed: blog.settings.privacy.dybrfeed,
          ...(entry.settings || {
            permissions: { access: [], comments: [] }
          })
        });
        setIsPinned(
          blog.settings.pinnedEntries &&
            blog.settings.pinnedEntries.includes(eid)
        );
      }

      setLoading(false);
    }

    if (eid && eid !== '0') {
      loadEntry();
    } else {
      setEntryContent(entryDraft.content || '');
      setEntryTitle(entryDraft.title || '');
      setTags(entryDraft.tags || []);
      setEntrySettings({
        permissions: { access: [], comments: [] },
        feed: blog.settings.privacy.dybrfeed
      });
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [eid, profile, token]);

  const handlePublishEntry = content => {
    setSubmitting(true);

    const requestFields = {
      title: entryTitle,
      content,
      tags,
      state: 'published',
      // bogolt asked not to send default blog's value here
      settings: {
        ...entrySettings,
        feed:
          entrySettings.feed === blog.settings.privacy.dybrfeed
            ? undefined
            : entrySettings.feed
      }
    };

    publishEntry({
      entryId: eid,
      blogSlug: slug,
      targetBlog: blog,
      tags,
      hotUpdateTagList,
      isPinned,
      unpinEntryIfPinned,
      pinEntry,
      requestFields,
      updatePublishAt,
      saveEntryDraft,
      token,
      navigate
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="blog-info-block">{error}</div>;
  }

  return preview ? (
    <PreviewEntry onPublish={handlePublishEntry} eid={eid} blog={blog} />
  ) : (
    <EntryEditContainer
      eid={eid}
      authorProfile={authorProfile}
      onPublish={handlePublishEntry}
      blog={blog}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  publishEntry
};

function EntryEditWrapper(props) {
  return (
    <EntryEditProvider>
      <EntryEditView {...props} />
    </EntryEditProvider>
  );
}

export const BlogEntryEditContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(EntryEditWrapper)
);
