import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import ContentWrap from '@/components/DybrComponents/ContentWrap';
import { Section, SectionTitle } from '@/components/DybrComponents/Section';
import Tabs from '@/components/DybrComponents/Tabs';
import theme from '@/styles/DybrTheme';
import ProfileAboutSection from '@/scenes/Dybr/Users/ProfilePage/ProfileAboutSection';
import BlogDisplay from '@/components/Shared/BlogDisplay';

import Loading from '@/components/Shared/Loading';
import {
  Blog,
  Flag,
  Flags,
  Profile,
  ProfileMainSection,
  Subscriptions
} from '@/scenes/Dybr/Users/styled/StyledProfile';
import AvatarWithMenu from '@/components/Shared/AvatarWithMenu';

import defaultBlogDesign from '@/configs/defaultBlogDesign';

import { getProfileApi } from '@/api/profiles';
import { getBlogEntriesApi } from '@/api/entries';
import { selectReaderProfiles } from '@/store/lists/selectors/selectReaderProfiles';
import { selectFavoriteProfiles } from '@/store/lists/selectors/selectFavoriteProfiles';
import { getFavoritesApi, getReadersApi } from '@/api/favorites';
import { BirthdayIcon } from '@/scenes/Dybr/Users/BirthdayIcon';
import { getDesignAPI } from '@/api/designs';
import { ErrorBlock } from '@/scenes/Account/components/StyledForm';
import ProfilesList from '@/scenes/Dybr/Users/ProfilePage/ProfilesList';
import { getCommunityMembersApi } from '@/api/communities';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '../../../../store/localStorage/WithActiveProfileId';
import { selectActiveProfileAugmented } from '../../../../store/user/selectors/selectActiveProfileAugmented';
import { useParams } from 'react-router-dom';

function getLastEntry(entries) {
  if (entries.length === 2) {
    if (entries[0].meta.pinned) return entries[1];
    else return entries[0];
  }
  if (entries.length === 1) return entries[0];
  return {};
}

function sortByNickname(profiles) {
  return profiles.slice().sort((a, b) => {
    return a.nickname > b.nickname ? 1 : -1;
  });
}

function addMutualSubscriptionsInfo(myReaders, myFavorites, profiles) {
  if (!myReaders.length && !myFavorites.length) return profiles;
  return profiles.map(a => ({
    ...a,
    isReader: Boolean(myReaders.find(b => b.id === a.id)),
    isFavorite: Boolean(myFavorites.find(b => b.id === a.id))
  }));
}

function getProfileTabs(isCommunity) {
  const communityTabs = [{ title: 'участники сообщества', name: 'members' }];
  const nonCommunityTabs = [
    { title: 'участник сообществ', name: 'communities' }
  ];

  return [
    { title: 'читатели', name: 'readers' },
    { title: 'избранное', name: 'favorites' },
    ...(isCommunity ? communityTabs : nonCommunityTabs)
  ];
}

const loadDesign = async (profile, token, setDesign) => {
  const designId = (profile.settings || {}).currentDesign;

  if (!designId || designId === '0') {
    setDesign(defaultBlogDesign);
    return;
  }

  const res = await getDesignAPI({
    designId,
    profileId: profile.id,
    token
  });

  if (!res.error) {
    setDesign(res.data);
  } else {
    setDesign(defaultBlogDesign);
  }
};

function ProfilePage({ myReaders, myFavorites, activeProfile }) {
  const [profile, setProfile] = useState();
  const [design, setDesign] = useState(defaultBlogDesign);
  const [subscriptionsTab, setSubscriptionsTab] = useState('readers');
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingBlogInfo, setLoadingBlogInfo] = useState(true);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
  const [error, setError] = useState('');
  const [lastEntry, setLastEntry] = useState({});
  const [totalEntries, setTotalEntries] = useState(0);
  const [favorites, setFavorites] = useState();
  const [readers, setReaders] = useState();
  const [members, setMembers] = useState();
  const [communities, setCommunities] = useState();
  const [token] = useTokenState();

  const { pid } = useParams();

  useEffect(() => {
    document.title = `Профиль ${profile?.nickname} - Дыбр`;
  }, [profile?.nickname]);

  const isCommunity = profile?.isCommunity;
  const tabs = getProfileTabs(isCommunity);

  const curseNames = {
    hide: 'Бан в общей ленте',
    shadowban: 'Теневой бан',
    archive: 'Архив',
    ban: 'Бан'
  };

  useEffect(() => {
    async function fetchProfile() {
      const loadProfile = async () => {
        setError('');

        const res = await getProfileApi({
          profileId: pid,
          token
        });

        if (!res.error) {
          const profile = res.data;

          setProfile(profile);
          setSubscriptionsTab('readers');
          setLoadingProfile(false);

          return profile;
        } else {
          setError(
            'не удалось получить данные профиля: возможно, он находится в архиве или удален'
          );
          setLoadingProfile(false);
          setLoadingBlogInfo(false);
          return false;
        }
      };
      const loadBlogInfo = async () => {
        const res = await getBlogEntriesApi({
          blogId: pid,
          pageSize: 2,
          pageNumber: 1,
          token
        });

        if (res.error) {
          setError('не удалось загрузить информацию о записях в блоге');
        } else {
          setLastEntry(getLastEntry(res.data));
          setTotalEntries(res.meta?.totalEntries ?? 0);
        }
        setLoadingBlogInfo(false);
      };

      const loadLists = async profile => {
        setLoadingSubscriptions(true);
        const favs = await getFavoritesApi({ profileId: profile.id, token });
        const readers = await getReadersApi({ profileId: profile.id, token });
        const mem = profile.isCommunity
          ? await getCommunityMembersApi({ communityId: profile.id, token })
          : {};

        if (favs.error || readers.error || mem.error) {
          setError('не получилось загрузить профили');
          setLoadingSubscriptions(false);
          console.error('Unable to load lists', favs, readers, mem);
        } else {
          const profileFavorites = sortByNickname(favs.data || []);
          const profileReaders = sortByNickname(readers.data || []);
          const profileMembers = sortByNickname(
            mem.data?.map(item => item.profile) || []
          );
          const profileMemberOf = sortByNickname(profile.communities || []);

          setFavorites(
            addMutualSubscriptionsInfo(myReaders, myFavorites, profileFavorites)
          );
          setReaders(
            addMutualSubscriptionsInfo(myReaders, myFavorites, profileReaders)
          );
          setMembers(
            addMutualSubscriptionsInfo(myReaders, myFavorites, profileMembers)
          );
          setCommunities(
            addMutualSubscriptionsInfo(myReaders, myFavorites, profileMemberOf)
          );
          setLoadingSubscriptions(false);
        }
      };

      const profile = await loadProfile();
      if (profile) {
        await loadDesign(profile, token, setDesign);
        await loadBlogInfo();
        await loadLists(profile);
      }
    }

    fetchProfile();
    // eslint-disable-next-line
  }, [pid, token, myReaders.length, myFavorites.length]);

  let profiles = [];
  switch (subscriptionsTab) {
    case 'readers':
      profiles = readers;
      break;
    case 'favorites':
      profiles = favorites;
      break;
    case 'members':
      profiles = members;
      break;
    case 'communities':
      profiles = communities;
      break;
  }

  function hasAdminPermissions() {
    return activeProfile?.admin != null;
  }

  return (
    <ContentWrap>
      <SectionTitle>
        Профиль {isCommunity ? 'сообщества' : 'пользователя'}
      </SectionTitle>
      {loadingProfile || loadingBlogInfo ? (
        <Loading />
      ) : (
        <Fragment>
          {error || !profile ? (
            <div style={{ marginTop: 40, width: '100%' }}>
              <ErrorBlock show={Boolean(error)}>{error}</ErrorBlock>
            </div>
          ) : (
            <>
              <ProfileMainSection>
                <Profile>
                  <AvatarWithMenu
                    className="profile-avatar"
                    profile={profile}
                  />
                  <BirthdayIcon
                    birthday={profile.birthday}
                    nick={profile.nickname}
                  />
                  <div className="nickname">{profile.nickname}</div>
                  <div className="subtext">{profile.settings.subtext}</div>
                  {hasAdminPermissions() && profile.curses && (
                    <Flags>
                      {profile.curses.map(curse => {
                        return (
                          <Flag key={curse.type}>
                            {curseNames[curse.type] || curse.type}
                          </Flag>
                        );
                      })}
                    </Flags>
                  )}
                </Profile>

                <Blog userTheme={design}>
                  <BlogDisplay profile={profile} design={design} />
                  <div className="blog-info">
                    <div>
                      {isCommunity ? 'Сообщество' : 'Дневник'} ведется с{' '}
                      {moment(profile.createdAt).format('DD.MM.YYYY')}
                    </div>
                    <div>записей: {totalEntries}</div>
                    {lastEntry.publishedAt && (
                      <div>
                        {`Последняя запись ${moment(
                          lastEntry.publishedAt
                        ).format('DD.MM.YYYY')}`}
                      </div>
                    )}
                  </div>
                </Blog>
              </ProfileMainSection>

              <ProfileAboutSection profile={profile} />

              <Section backgroundColor={theme.backgroundLighter} lastSection>
                <Subscriptions>
                  <Tabs
                    tabs={tabs}
                    currentTab={subscriptionsTab}
                    onTabChange={setSubscriptionsTab}
                  />
                  {loadingSubscriptions ? (
                    <Loading />
                  ) : (
                    <ProfilesList profiles={profiles} />
                  )}
                </Subscriptions>
              </Section>
            </>
          )}
        </Fragment>
      )}
    </ContentWrap>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    myFavorites: selectFavoriteProfiles(state),
    myReaders: selectReaderProfiles(state),
    activeProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export const ProfileContainer = WithActiveProfileId(
  connect(mapStateToProps)(React.memo(ProfilePage))
);
