import { createSelector } from 'reselect';
import {
  selectNotificationsMentioned,
  selectNotificationsOwnBlog,
  selectNotificationsSmbdElseBlog,
  selectNotificationsPrivate
} from '@/store/notifications/selectors/selectNotifications';
import { selectNotificationEntriesMap } from '@/store/notifications/selectors/selectNotificationEntriesMap';
import { getUniqueOrderedEntryIds } from '@/store/notifications/utils/getUniqueOrderedEntryIds';

export const selectCommentedEntriesOwn = createSelector(
  [selectNotificationsOwnBlog, selectNotificationEntriesMap],
  (comments, entries) =>
    getUniqueOrderedEntryIds(comments.reverse())
      .map(entryId => entries[entryId])
      .filter(entry => Boolean(entry))
);

export const selectCommentedEntriesSmbdElse = createSelector(
  [selectNotificationsSmbdElseBlog, selectNotificationEntriesMap],
  (notifications, entries) =>
    getUniqueOrderedEntryIds(notifications.reverse())
      .map(entryId => entries[entryId])
      .filter(entry => Boolean(entry))
);

export const selectEntriesWithMention = createSelector(
  [selectNotificationsMentioned, selectNotificationEntriesMap],
  (notifications, entries) => {
    return getUniqueOrderedEntryIds(notifications.reverse())
      .map(entryId => entries[entryId])
      .filter(entry => Boolean(entry));
  }
);

export const selectProfilesWithPrivateNotification = createSelector(
  [selectNotificationsPrivate],
  notifications => {
    const addedProfileIds = {};
    const uniqueOrderedProfiles = [];

    for (const notification of notifications) {
      if (!addedProfileIds[notification.source.id]) {
        uniqueOrderedProfiles.push(notification.source);
        addedProfileIds[notification.source.id] = true;
      }
    }
    return uniqueOrderedProfiles;
  }
);
