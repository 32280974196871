import TextField from '@/components/FormElements/TextField';
import config from '@/configs/dybr';
import React, { useState } from 'react';
import { usePrivacyListContext } from '@/scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';
import UserProfilesSelectorContainer from './UserProfilesSelectorContainer';
import * as css from './AddPrivacyList.module.css';
import { ActionButton } from '@/components/Shared/ButtonsNew';

function AddPrivacyList() {
  const [listName, setListName] = useState('');
  const [profiles, setProfiles] = useState([]); // {label: nickname, value: id}

  const { handleAddList } = usePrivacyListContext();

  return (
    <>
      <div className={css.header}>Добавить список:</div>
      <div className={css.inputWrapper}>
        <TextField
          label="название списка"
          isRequired={true}
          onChange={e => setListName(e.target.value)}
          maxLen={config.MAX_LENGTH.nickname}
          value={listName}
          touched
        />

        <UserProfilesSelectorContainer
          containerClassName={css.profilesWrapper}
          onChange={setProfiles}
          value={profiles}
        />
      </div>
      <ActionButton
        onClick={async () => {
          const res = await handleAddList(
            listName,
            profiles.map(p => p.value)
          );

          if (res.data) {
            setListName('');
            setProfiles([]);
          }
        }}
        disabled={!listName}
      >
        cоздать
      </ActionButton>
    </>
  );
}

export default AddPrivacyList;
