import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import css from '@/components/Shared/SoftAlert.module.css';
import ErrorIcon from '@/styles/Icons/ErrorIcon';
import InformationIcon from '@/styles/Icons/InformationIcon';
import TickIcon from '@/styles/Icons/TickIcon';
import { classNames } from '@/utils/helpers/classNames';

export const SoftAlertType = {
  inProgress: 'inProgress',
  error: 'error',
  success: 'success'
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  height: max-content;
  background: ${(props) => {
    switch (props.type) {
      case SoftAlertType.inProgress:
        return props.theme.softAlertInProgress;
      case SoftAlertType.error:
        return props.theme.softAlertError;
      case SoftAlertType.success:
        return props.theme.softAlertSuccess;
      default:
        return 'white';
    }
  }};
  color: ${(props) => {
    switch (props.type) {
      case SoftAlertType.inProgress:
        return props.theme.softAlertInProgressText;
      case SoftAlertType.error:
        return props.theme.brandDarker;
      case SoftAlertType.success:
        return props.theme.softAlertSuccessText;
      default:
        return 'white';
    }
  }};
  border-radius: 5px;
  padding: ${(props) =>
    props.type === SoftAlertType.success ? '8px 10px' : '9px 10px'};
  font-size: 12px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 12px;
`;

function SoftAlert({ label, type, className, hideTimeout }) {
  const [isVisible, setIsVisible] = useState(true);

  let Icon;
  switch (type) {
    case SoftAlertType.inProgress:
      Icon = InformationIcon;
      break;
    case SoftAlertType.error:
      Icon = ErrorIcon;
      break;
    case SoftAlertType.success:
      Icon = TickIcon;
      break;
    default:
      Icon = null;
  }

  useEffect(() => {
    let timeoutHandler;
    if (hideTimeout) {
      timeoutHandler = setTimeout(() => setIsVisible(false), hideTimeout);
    }

    return () => {
      if (hideTimeout && timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [hideTimeout]);

  return (
    <div
      className={classNames({
        [className]: className,
        [css.transparent]: !isVisible
      })}
    >
      <Wrapper type={type}>
        <div
          className={
            type === SoftAlertType.success
              ? css.successIconContainer
              : css.iconContainer
          }
        >
          <Icon />
        </div>
        <StyledLabel>{label}</StyledLabel>
      </Wrapper>
    </div>
  );
}

export default SoftAlert;
