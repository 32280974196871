import { classNames } from '@/utils/helpers/classNames';
import React from 'react';
import { removeEditedEntry } from '@/store/entries/thunks/removeEditedEntry';
import { useEntryEditContext } from '@/scenes/Blog/EntryEdit/EntryEditContext';
import { connect } from 'react-redux';
import { useTokenState } from '@/store/localStorage/useTokenState';
import { useSetEntryDraft } from '@/store/localStorage/drafts/useSetEntryDraft';
import { useNavigate } from 'react-router-dom';

export function EntryEditButtonGroup({
  onPublish,
  onTogglePreview,
  eid,
  removeEditedEntry,
  blog
}) {
  const navigate = useNavigate();
  const { submitting, entryContent, preview } = useEntryEditContext();
  const [token] = useTokenState();
  const saveEntryDraft = useSetEntryDraft();
  const textButtonDelete = eid === '0' ? 'удалить черновик' : 'удалить';
  const textButtonPreview = preview ? 'редактировать' : 'предпросмотр';

  return (
    <div
      className={classNames({
        'edit-entry-form-buttons': true,
        'edit-entry-form-buttons-preview': preview
      })}
    >
      <div className="edit-entry-form-main-buttons">
        <button
          disabled={submitting || entryContent.length === 0}
          onClick={onPublish}
          className="add-entry-btn "
        >
          {submitting ? 'публикуем...' : 'опубликовать'}
        </button>
        <button
          disabled={submitting || entryContent.length === 0}
          onClick={onTogglePreview}
          className="preview-entry-btn"
        >
          {textButtonPreview}
        </button>
      </div>
      <div className="edit-entry-form-secondary-buttons">
        <button
          disabled={submitting || entryContent.length === 0}
          onClick={() =>
            removeEditedEntry({
              entryId: eid,
              blog,
              token,
              saveEntryDraft,
              navigate
            })
          }
          className="delete-entry-btn"
        >
          {textButtonDelete}
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  removeEditedEntry
};

export const EntryEditButtonGroupContainer = connect(
  null,
  mapDispatchToProps
)(EntryEditButtonGroup);
