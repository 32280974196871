import { Routes, Route } from 'react-router-dom';

import React from 'react';
import { CommonFeedContainer } from '@/scenes/Dybr/Feeds/CommonFeed';
import { ServiceBackground } from '@/components/DybrComponents/ServiceBackground';
import { HeaderGeneral } from '@/components/DybrComponents/HeaderGeneral';

function SearchScene() {
  return (
    <ServiceBackground data-testid="search-scene">
      <HeaderGeneral paddingBottom="45px">
        <h1>результаты поиска</h1>
      </HeaderGeneral>

      <Routes>
        <Route path="/" element={<CommonFeedContainer isFiltered />} />
      </Routes>
    </ServiceBackground>
  );
}

export default SearchScene;
