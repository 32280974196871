import cloneDeep from 'lodash-es/cloneDeep';

import { StartEditingAction } from '@/store/designer/actions';
import { selectDesign } from '@/store/designer/selectors';

export const startDesignerWithACopy = designID => (dispatch, getState) => {
  const design = cloneDeep(selectDesign(getState(), designID));
  const name = design.name.substr(0, 22) + ' (копия)';
  dispatch(new StartEditingAction({ ...design, id: '0', name }));
};
