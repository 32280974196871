import { rgbaStringToObject } from '@/utils/helpers/colors';
import { getRelativeUrl } from '@/utils/helpers/hostNames';

const addBackground = (header, background, colors) => {
  if (!background.enable) return '';
  const hasFullBlownHeader = rgbaStringToObject(header.background).a === '1';
  return `
    ${hasFullBlownHeader ? '.blog-container' : '.blog-page'} {
      background-image: url("${getRelativeUrl(background.url)}");
      background-repeat: ${background.repeat};
      background-position: ${background.position};
      background-attachment: ${background.attachment};
      background-size: ${background.size};
    }
  `;
};

export default ({ colors, header, background }) => `
    .blog-page {
      line-height: 1.2;
      color: ${colors.text};
    }

    ${addBackground(header, background, colors)}


    .blog-page a
     {
      text-decoration: none;
      color: ${colors.links};
    }
    .blog-page button {
      cursor: pointer;
      border: none;
      background: none;
    }

    .blog-info-block {
      background: ${colors.blocks};
      padding: 20px;
      text-align: center;
    }

    ${
      background.attachment === 'fixed'
        ? '@media (hover: none) {.blog-page { background-attachment: normal; background-repeat:repeat; }}'
        : ''
    }

`;
