import { changeRgbaAlpha } from '@/utils/helpers/colors';

export default ({ colors, header, font }) => `
  .fr-toolbar.fr-top {
    top: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .fr-toolbar {
    color: ${colors.elements};
    background: ${changeRgbaAlpha(colors.blocks, 1)};
    padding: 0 2px;
    border: none;
    border-bottom: 1px solid ${colors.meta};
    padding-bottom: 10px;
  }

  .fr-fullscreen .fr-toolbar {
    background: ${changeRgbaAlpha(colors.blocks, 1)};
  }

  .fr-toolbar.fr-sticky-on {
    background: ${changeRgbaAlpha(colors.blocks, 1)};
    border-right: 1px solid ${colors.meta};
    border-left: 1px solid ${colors.meta};
    border-bottom: none;
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.3), 0 -8px 10px -10px rgba(0,0,0,0.3);;
  }
  textarea.fr-code {
    background: transparent;
    color: ${colors.text};
  }

  .fr-box.fr-basic.fr-top .fr-wrapper {
    border-top: 0;
    border-radius: 0;
    box-shadow: none;
    background: ${colors.blocks};
    border: 1px solid ${colors.meta};
    border-top: none;
  }
  .fr-toolbar .fr-command.fr-btn {
    color: ${colors.text};
    background: transparent;
    margin: 0 3px;
  }
  .fr-toolbar .fr-command.fr-btn path {
    fill: ${colors.text};
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
  .fr-popup .fr-command.fr-btn.fr-dropdown::after {
    border-top: 4px solid ${colors.text}
  }
  .fr-toolbar .fr-command.fr-btn.fr-disabled,
  .fr-popup .fr-command.fr-btn.fr-disabled {
    color: ${colors.offtop};
  }
  .fr-toolbar .fr-command.fr-btn.fr-disabled path {
    fill: ${colors.offtop};
  }

  .fr-toolbar .fr-command.fr-btn i {
    font-size: 16px;
    width: 16px;
  }
  .fr-box.fr-basic .fr-element {
    color: ${colors.text};
    background: ${colors.blocks};
  }
  .fr-box.fr-basic .fr-element .offtop {
    color: ${colors.offtop};
  }
  /*
  .fr-fullscreen .fr-element.fr-view {
    height: ${(() => {
      // temp hack for mobile keyboard overlay
      // need a better solution:
      //  on textarea focus, if mobile view,
      //  open fullscreen, measure toolbar height,
      //  do this calculation
      return window.innerHeight - 162;
    })()}px!important;
  }*/
  .fr-view .fr-video {
    text-align: inherit;
  }
  .fr-view img.fr-dib {
    display: inline-block;
  }

  .fr-box .fr-counter {
    color: ${colors.elements};
    background: transparent;
    border-radius: 0;
    border: none;
    box-shadow: none;
    font-size: 10px;
    font-family: Roboto;
    position: absolute;
    bottom: -25px !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .fr-wrapper .fr-placeholder {
    color: ${colors.meta};
    z-index: 10;
    opacity: 0.7;
  }
  .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li {
  background: ${changeRgbaAlpha(colors.blocks, 1)};
    color: ${colors.text};
}

.fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li:hover a {
  background: ${changeRgbaAlpha(colors.text, 1)};
    color: ${changeRgbaAlpha(colors.blocks, 1)};
  }

  .fr-popup {
    background: ${changeRgbaAlpha(colors.blocks, 1)};
    color: ${colors.text};
  }
  .fr-popup .fr-input-line input+label, .fr-popup .fr-input-line textarea+label {
    background: ${changeRgbaAlpha(colors.blocks, 1)};
  }
  .fr-toolbar .fr-command.fr-btn.fr-active, .fr-popup .fr-command.fr-btn.fr-active {
    color: ${colors.links};
  }

  .fr-popup .fr-input-line input.fr-not-empty+label,
  .fr-popup .fr-input-line textarea.fr-not-empty+label,
  .fr-popup .fr-input-line input.fr-not-empty:focus+label,
  .fr-popup .fr-input-line textarea.fr-not-empty:focus+label  {
    color: ${colors.meta};
  }
  .fr-popup .fr-input-line input[type=text],
  .fr-popup .fr-input-line textarea {
    color: ${colors.text};
    border-bottom: solid 2px ${colors.meta};
    margin-bottom: 0;
  }
  .fr-popup .fr-input-line input::placeholder,
  .fr-popup .fr-input-line textarea::placeholder {
    color: ${changeRgbaAlpha(colors.text, 0.8)};
  }
  .fr-popup .fr-input-line input[type=text]:focus,
  .fr-popup .fr-input-line textarea:focus {
    border-bottom: solid 2px ${colors.accent};
  }
  /* the check mark */
  .fr-popup .fr-checkbox svg path {
    fill: ${colors.headers};
  }
  /* background and border when checked */
  .fr-popup .fr-checkbox input:checked+span {
    background: transparent;
    border-color: ${colors.headers};
  }
  /* border when not checked */
  .fr-popup .fr-checkbox span {
    border-color: ${colors.meta};
  }
  .fr-popup .fr-action-buttons button.fr-command {
    background: ${colors.buttons};
    color: ${changeRgbaAlpha(colors.blocks, 1)};
  }
  .fr-popup .fr-action-buttons button.fr-command:hover,
  .fr-popup .fr-action-buttons button.fr-command:focus {
    background: ${colors.buttons};
    color: ${changeRgbaAlpha(colors.blocks, 1)};
  }
  .fr-popup .fr-input-focus,
  .fr-popup .fr-image-upload-layer:hover {
    background: ${changeRgbaAlpha(colors.blocks, 1)};
  }
  .fr-desktop .fr-command.fr-active:hover,
  .fr-desktop .fr-command.fr-active:focus {
    background: ${changeRgbaAlpha(colors.blocks, 1)};
  }
  .fr-popup .fr-image-upload-layer {
    border: 2px dashed ${changeRgbaAlpha(colors.text, 0.6)};
  }

`;
