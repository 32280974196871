import React from 'react';
import Pagination from '@/components/Shared/Pagination';

const BlogPagination = (props) => {
  const { classModifier, ...rest } = props;
  const wrap = (pagination) => (
    <div
      className={`blog-pagination blog-pagination--${classModifier}`}
      data-testid={`pagination-${classModifier}`}
    >
      {pagination}
    </div>
  );
  return <Pagination {...rest} wrap={wrap} />;
};

export default BlogPagination;
