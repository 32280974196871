import React, { useState } from 'react';

import Switch from '@/components/FormElements/Switch';

import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from '@/components/DybrComponents/StyledServicePagesElements';
import { updateProfileSetting } from '@/store/user/thunks/updateProfileSetting';
import { connect } from 'react-redux';
import { selectActiveProfileSettingsAugmented } from '@/store/user/selectors/selectActiveProfileSettingsAugmented';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

function DybrFeedSettings({ settings, updateProfileSetting }) {
  const [dybrFeed, setDybrFeed] = useState(settings.privacy?.dybrfeed ?? false);
  const [token] = useTokenState();

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Общая лента Дыбра</h4>
          <p>
            На Дыбре есть общая лента всех записей. Вы можете исключить из неё
            свой дневник.
          </p>
        </SettingSectionTitle>
        <Settings>
          <Switch
            label="участвовать в общей ленте"
            checked={dybrFeed}
            onClick={(e) => {
              updateProfileSetting('privacy.dybrfeed', e.target.checked, token);
              setDybrFeed(e.target.checked);
            }}
          />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(DybrFeedSettings)
);
