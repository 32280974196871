import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTooltip = styled.div`
  position: absolute;
  left: 50px;
  display: none;
  font-weight: bold;
  width: 270px;
  border: 1px solid ${props => props.theme.backgroundDark};
  font-size: 12px;

  div {
    padding: 7px 10px;
  }

  @media {
    display: ${props => (props.isVisible ? 'block' : 'none')};
  }
`;

const TooltipHeader = styled.div`
  background: ${props => props.theme.backgroundDark};
  color: ${props => props.theme.textLighter};
`;

const TooltipBody = styled.div`
  background: ${props => props.theme.backgroundLight};
  color: ${props => props.theme.text};
`;

const Tooltip = ({ isVisible, nickname = '', blogTitle = '' }) => (
  <StyledTooltip isVisible={isVisible}>
    <TooltipHeader>{nickname}</TooltipHeader>
    {blogTitle && <TooltipBody>{blogTitle}</TooltipBody>}
  </StyledTooltip>
);

Tooltip.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  nickname: PropTypes.string,
  blogTitle: PropTypes.string
};

export default Tooltip;
