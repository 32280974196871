(function($) {
  if ($.FroalaEditor) {
    // Define the plugin.
    // The editor parameter is the current instance.
    $.FroalaEditor.PLUGINS.quoteBrackets = function(editor) {
      function apply(c) {
        // editor.selection.text() !== '' ? editor.html.insert('hiiii') : editor.html.insert('hello');
        //  console.log('apply');
        editor.selection.text() !== ''
          ? editor.html.insert(`«${editor.selection.text()}»`)
          : editor.html.insert(`«${$.FroalaEditor.MARKERS}»`);
      }

      // The start point for your plugin.
      function _init() {
        // console.log('init');
        // console.log($.FroalaEditor.ICON_TEMPLATES);
        // You can access any option from documentation or your custom options.
        // console.log(editor.opts.myOption);
        // Call any method from documentation.
        // editor.methodName(params);
        // You can listen to any event from documentation.
        // editor.events.add('contentChanged', function (params) {});
      }

      return {
        _init,
        apply
      };
    };
    $.FroalaEditor.DefineIcon('quoteBracket', { NAME: '«»', template: 'text' });
    $.FroalaEditor.RegisterCommand('quoteBrackets', {
      callback(a, b) {
        //  console.log('brackets callback', a, b);
        this.quoteBrackets.apply();
      },
      plugin: 'quoteBrackets',
      icon: 'quoteBracket',
      title: 'кавычки'
    });
  }
})($);
