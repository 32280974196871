import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import NavBarLoggedOut from './NavBarLoggedOut';
import HeaderMain from './HeaderMain';
import { NavBarLoggedInContainer } from '@/scenes/Header/NavBarLoggedIn';
import { selectIsSignupIncomplete } from '@/store/user/selectors/selectIsSignupIncomplete';
import { connect } from 'react-redux';
import { useTokenState } from '@/store/localStorage/useTokenState';

function Header({ isSignupIncomplete }) {
  const location = useLocation();
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  return (
    <div data-testid="header">
      {isLoggedIn && !isSignupIncomplete ? (
        <NavBarLoggedInContainer pathname={location.pathname} />
      ) : (
        <Routes primary={false}>
          <Route path="/" element={<HeaderMain />} />
          <Route path="*" element={<NavBarLoggedOut />} />
        </Routes>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  isSignupIncomplete: selectIsSignupIncomplete(state)
});

export const HeaderContainer = connect(mapStateToProps)(Header);
