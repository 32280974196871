import TextField from '@/components/FormElements/TextField';
import { dybrConfigs } from '@/configs/dybr';
import React from 'react';

function NicknameField({
  nickname,
  setNickname,
  setErrorNickname,
  errorNickname,
  validate
}) {
  return (
    <TextField
      id="nickname"
      type="text"
      value={nickname}
      meta={{}}
      maxLen={dybrConfigs.MAX_LENGTH.nickname}
      onChange={(e) => {
        setNickname(e.target.value);
        setErrorNickname('');
      }}
      label="никнейм"
      onBlur={validate}
      error={errorNickname}
      touched={Boolean(errorNickname)}
    />
  );
}

export default NicknameField;
