import { createSelector } from 'reselect';
import { selectProfiles } from '@/store/user/selectors/selectProfiles';

export const selectIsOwnProfile = createSelector(
  selectProfiles,
  (state, { profileId }) => profileId,
  (profiles, profileId) => {
    if (!profiles) {
      return false;
    }
    return Boolean(profiles[profileId]);
  }
);
