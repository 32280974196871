import produce from 'immer';
import cloneDeep from 'lodash-es/cloneDeep';
import keyBy from 'lodash-es/keyBy';

import { defaultUserState } from '@/store/_setup/initialState';
import { AddCommunityAction } from '@/store/user/actions/AddCommunityAction';
import { LeaveCommunityAction } from '@/store/user/actions/LeaveCommunityAction';
import { LogoutAction } from '@/store/user/actions/LogoutAction';
import { SetActiveProfileIdAction } from '@/store/user/actions/SetActiveProfileIdAction';
import { SetCommunitiesAction } from '@/store/user/actions/SetCommunitiesAction';
import { SetProfileAction } from '@/store/user/actions/SetProfileAction';
import { SetUserSettingsAction } from '@/store/user/actions/SetUserSettingsAction';
import { StoreUserDataAction } from '@/store/user/actions/StoreUserDataAction';
import { UpdateProfileAction } from '@/store/user/actions/UpdateProfileAction';

/**
 *  structure:
 *
    userData: {
      createdAt: "2020-05-28T17:03:26Z",
      email: "bubnova.j.i2@gmail.com",
      ...
    }
    activeProfile: "20795",
    token: '',
    userId: 5230,
    settings: {
        pagination: {},
        disabledDesigns: ['1']
    },
    profiles: {
        "288": {
                id: "288"
                createdAt: "2018-02-25T17:47:54Z"
                isCommunity: false
                nickname: ""
                settings: {avatar: "/static/media/default_ava.9b2e26d5.jpg",…}
                updatedAt: "2018-09-10T18:35:07Z"
                meta: {entries: {public: 0}}
            }
        }

 ~~~
 previously it was:
 {
   token: '',
   profiles: [],
   activeProfile: null,
   settings: defaultUserSettings,
   loading: true,
   ...props
 }
 ~~~~
 *
 */

export const userReducer = (state = defaultUserState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case StoreUserDataAction.type:
        draft.userData = {
          createdAt: action.data.createdAt,
          updatedAt: action.data.updatedAt,
          email: action.data.email,
          isAdult: action.data.isAdult,
          termsOfService: action.data.termsOfService
        };

        draft.profiles = keyBy(action.data.profiles || [], 'id');
        draft.settings = action.data.settings;
        break;
      // case SetTokenAction.type: {
      //
      //   const { userId, activeProfileId } = parseToken(action.token);
      //   // todo this should be parsed from local storage
      //   draft.userId = userId;
      //   if (activeProfileId) {
      //     draft.activeProfile = activeProfileId;
      //   }
      //   break;
      // }
      case SetActiveProfileIdAction.type:
        draft.activeProfile = action.activeProfile;
        break;
      case SetProfileAction.type:
        draft.profiles[action.profile.id] = action.profile;
        break;
      case SetCommunitiesAction.type:
        draft.communities = {
          ...draft.communities,
          [action.profileId]: keyBy(action.communities || [], 'id')
        };
        break;
      case AddCommunityAction.type:
        draft.communities = {
          ...draft.communities,
          [action.profileId]: {
            ...draft.communities?.[action.profileId],
            [action.community.id]: action.community
          }
        };
        break;
      case LeaveCommunityAction.type: {
        if (!draft.communities || !draft.communities[action.profileId]) {
          break;
        }
        delete draft.communities[action.profileId][action.communityId];
        break;
      }
      case SetUserSettingsAction.type:
        draft.settings = action.settings;
        break;
      case UpdateProfileAction.type:
        draft.profiles[action.id] = {
          ...draft.profiles[action.id],
          ...action.data
        };
        break;
      case LogoutAction.type:
        return defaultUserState;
      default:
        break;
    }
  });
