import { CheckmarkIcon } from '@/styles/Icons/Checkmark';
import { CopyDesignIcon } from '@/styles/Icons/CopyDesign';
import { EditIcon } from '@/styles/Icons/Edit';
import IconBin from '@/styles/Icons/GarbageBin';
import Preview from '@/styles/img/preview.jpg';
import { ActionButton } from '@/components/Shared/Buttons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import {
  deleteDesign,
  startDesignerWithACopy,
  startEditingDesign
} from '@/store/designer/operations';
import { updateProfileSetting } from '@/store/user/thunks/updateProfileSetting';
import { connect } from 'react-redux';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

export function DesignItem({
  design,
  startDesignerWithACopy,
  startEditingDesign,
  deleteDesign,
  updateProfileSetting,
  profile
}) {
  const navigate = useNavigate();
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  const isCurrent = design.id === profile.settings.currentDesign;

  function copyDesign() {
    startDesignerWithACopy(design.id);
    navigate(`/blog/${profile.blogSlug}`);
  }

  function editDesign() {
    startEditingDesign(design.id);
    navigate(`/blog/${profile.blogSlug}`);
  }

  async function handleDeleteDesign() {
    if (window.confirm('Точно удалить дизайн?')) {
      deleteDesign({ designId: design.id, token, activeProfileId });
      if (isCurrent) {
        await updateProfileSetting('currentDesign', '0', token);
      }
    }
  }

  if (!design || !design.id) {
    return null;
  }

  return (
    <div key={design.id} className={isCurrent ? 'design current' : 'design'}>
      <span>{design.name}</span>
      {isCurrent ? <CheckmarkIcon /> : ''}
      <div className="design-controls">
        <button title="копировать дизайн" onClick={() => copyDesign()}>
          <CopyDesignIcon />
        </button>
        <button title="редактировать дизайн" onClick={() => editDesign()}>
          <EditIcon />
        </button>
        <button title="удалить дизайн" onClick={() => handleDeleteDesign()}>
          <IconBin />
        </button>
      </div>
      <div className="preview">
        <img src={Preview} width="318px" alt="" />
      </div>
      <div className="action-buttons">
        <ActionButton
          onClick={() =>
            updateProfileSetting('currentDesign', design.id, token)
          }
        >
          применить
        </ActionButton>
        {/*<SecondaryButton onClick={this.goToDesignPreview}>
            предпросмотр
    </SecondaryButton>*/}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    designs: state.designer.designs,
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  deleteDesign,
  startDesignerWithACopy,
  startEditingDesign,
  updateProfileSetting
};

export const DesignItemContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(DesignItem)
);
