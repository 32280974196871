import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowContainer, Popover } from 'react-tiny-popover';

import css from '@/components/Shared/AvatarWithMenu.module.css';
import theme from '@/styles/DybrTheme';
import { classNames } from '@/utils/helpers/classNames';

import { AvatarMenuPreload } from './AvatarMenu/AvatarMenuPreload';
import { ARROW_HEIGHT } from './constants';

//copied from AvatarWithMenu, need to refactor
function ProfileLinkWithMenu({ profileId, profileName }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  function handleClickOnProfileLing(ev) {
    ev.preventDefault();
    setMenuOpen(true);
  }

  const arrowContentRenderer = ({ position, childRect, popoverRect }) => (
    <ArrowContainer
      position={position}
      childRect={childRect}
      popoverRect={popoverRect}
      arrowColor={theme.backgroundLighter}
      arrowSize={ARROW_HEIGHT}
      arrowStyle={{ marginLeft: '0px' }}
      className={classNames({
        [css.popoverArrowContainer]: true
      })}
    >
      <AvatarMenuPreload profileId={profileId} />
    </ArrowContainer>
  );

  return (
    <>
      <Popover
        padding={5}
        isOpen={isMenuOpen}
        positions={['bottom']}
        align={'center'}
        content={arrowContentRenderer}
        onClickOutside={() => setMenuOpen(false)}
        disableReposition={true}
        transitionDuration={0.1}
      >
        <Link to="/" onClick={handleClickOnProfileLing}>
          {profileName}
        </Link>
      </Popover>
    </>
  );
}

export default ProfileLinkWithMenu;
