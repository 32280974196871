import { createSelector } from 'reselect';
import { selectNotificationEntriesMap } from '@/store/notifications/selectors/selectNotificationEntriesMap';
import { getCommonNotificationUrl } from '@/utils/helpers/getCommonNotificationUrl';
import {
  selectNotificationsMentioned,
  selectNotificationsOwnBlog,
  selectNotificationsSmbdElseBlog
} from './selectNotifications';

export const selectOldestMentionedNotificationUrl = createSelector(
  [selectNotificationsMentioned, selectNotificationEntriesMap],
  (notifications, entries) => getCommonNotificationUrl(notifications, entries)
);

export const selectOldestOwnNotificationUrl = createSelector(
  [selectNotificationsOwnBlog, selectNotificationEntriesMap],
  (notifications, entries) => getCommonNotificationUrl(notifications, entries)
);

export const selectOldestSmbdElseNotificationUrl = createSelector(
  [selectNotificationsSmbdElseBlog, selectNotificationEntriesMap],
  (notifications, entries) => getCommonNotificationUrl(notifications, entries)
);
