import { UpdateProfileAction } from '@/store/user/actions/UpdateProfileAction';
import { updateProfileApi } from '@/api/profiles';

export const updateProfileThunk = ({ data, token, activeProfileId }) => async (
  dispatch
) => {
  const res = await updateProfileApi({ data, activeProfileId, token });

  if (!res.error) {
    dispatch(new UpdateProfileAction(data, activeProfileId));
  } else {
    return { error: res.error };
  }
};
