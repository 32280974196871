import { createSelector } from 'reselect';
import { selectNotificationsMap } from '@/store/notifications/selectors/selectNotificationsMap';

export const selectNotificationsTotal = createSelector(
  [selectNotificationsMap],
  items => {
    const uniqueItemsWithNotifications = new Set();
    Object.values(items).forEach(value => {
      if (value.type === 'comment' || value.type === 'mention') {
        uniqueItemsWithNotifications.add(
          `comment${value.entry}${value.comment}`
        );
      }
      if (value.type === 'message') {
        uniqueItemsWithNotifications.add(
          `message${value.message?.id}${value.source?.id}`
        );
      }
    });
    return uniqueItemsWithNotifications.size;
  }
);
