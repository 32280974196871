// Component to display one entry (used both in feeds and entry page)
import React from 'react';
import styled from 'styled-components';
import Entry from '@/components/Shared/Entry/Entry';
import { darkenColor } from '@/utils/helpers/colors';
import { getBlogEntrySubAuthorCss } from '@/components/Shared/Entry/BlogEntryAvatar';
import { MAX_ENTRY_CONTENT_HEIGHT } from '@/components/Shared/constants';

const Wrapper = styled.div`
  width: 100%;

  .blog-entry {
    position: relative;
    background: ${(p) => p.theme.backgroundLighter};
    border: 1px solid ${(p) => p.theme.divider};
    font-size: 15px;
    font-family: ${(p) => p.theme.textFont};
    line-height: 1.45;
    text-align: justify;
    padding: 40px 0 20px 0;
    margin: 20px 0;
    width: 100%;

    button {
      cursor: pointer;
      border: none;
      background: none;
    }
  }

  @media (max-width: 550px) {
    .blog-entry,
    .blog-entry p {
      line-height: 1.4;
      margin: 15px 0;
    }
  }

  /******  FEED ENTRY CONTENT ******/
  .blog-entry-content {
    padding: 0px 40px 0 40px;
    word-break: break-word;
  }
  .blog-entry-content--sliced {
    max-height: ${MAX_ENTRY_CONTENT_HEIGHT}px;
    overflow: hidden;
  }

  .blog-entry-content-expansion-button {
    color: ${p => p.theme.accent};
    font-weight: 500;
    padding: 20px 40px 0 40px;
    cursor: pointer;
    display: flex;
    position: relative;

    &:hover {
      .main-outline {
        fill: ${p => p.theme.brand};
      }
      span {
        color: ${p => p.theme.brand};
      }
    }

    &.blog-entry-content-expansion-button--collapsed {
      &:before {
        content: '';
        width: 100%;
        height: 24px;
        position: absolute;
        z-index: 20;
        left: 0;
        bottom: 100%;
        background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          ${p => p.theme.backgroundLighter}
        );
      }
    }
  }

  @media (max-width: 650px) {
    .blog-entry-content {
      padding: 0px 15px 20px 15px;
    }
    .blog-entry-content-expansion-button {
      padding: 10px 15px 0px 15px;
    }
  }

  .blog-entry-content img,
  .blog-entry-content iframe {
    max-width: 100%;
  }
  .blog-entry-content img {
    height: auto !important;
  }

  .blog-entry-content pre {
    white-space: pre-wrap;
  }
  table {
    border-collapse: collapse;
    empty-cells: show;
    border-color: ${(p) => p.theme.divider};
    border-spacing: 0;
  }

  table td {
    border: 1px solid ${(p) => p.theme.divider};
    padding: 3px;
  }
  table th {
    background: ${(p) => p.theme.backgroundLight};
    border: 1px solid ${(p) => p.theme.divider};
    padding: 3px;
  }
  table.fr-alternate-rows tbody tr:nth-child(2n) {
    background: ${(p) => p.theme.backgroundLight};
  }

  .fr-dashed-borders td {
    border-style: dashed;
  }
  .fr-thick {
    border-width: 2px;
  }
  blockquote,
  blockquote blockquote,
  blockquote blockquote blockquote {
    border-left: solid 2px ${(p) => p.theme.backgroundDarker};
    margin-left: 0;
    margin: 10px 0 10px 0;
    padding-left: 5px;
    color: ${(p) => p.theme.text};
  }

  .blog-entry-content ol ol ul,
  .blog-entry-content ol ul ul,
  .blog-entry-content ul ol ul,
  .blog-entry-content ul ul ul {
    list-style-type: square;
  }

  .blog-entry-content ul ul,
  .blog-entry-content ol ul {
    list-style-type: circle;
  }

  .blog-entry-content ul,
  .blog-entry-content ol {
    margin-left: 40px;
  }

  .blog-entry-content p {
    margin: 1em 0;
    text-align-last: left;
  }

  .blog-entry-content p:first-child {
    margin-top: 0;
  }

  .blog-entry-content p:last-child {
    margin-bottom: 0;
  }
  .blog-entry-content span.offtop {
    color: ${(p) => p.theme.textLight};
  }

  .blog-entry-content a,
  .blog-entry-content a:visited {
    text-decoration: none;
    color: ${(p) => p.theme.accentDark};
  }

  .blog-entry-content a:hover {
    text-decoration: underline;
    color: ${(p) => p.theme.accentDark};
  }

  .blog-entry-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${(p) => p.theme.textFont};
      text-align: left;
      text-transform: none;
    }

    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    h4 {
      font-size: 1em;
    }
  }

  /****** FEED ENTRY META-UP ******/

  .blog-entry-meta--up {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 30px;
    overflow: hidden;
    align-items: flex-start;
  }

  @media (max-width: 550px) {
    .blog-entry-meta--up {
      justify-content: center;
    }
  }

  .blog-entry-bookmark {
    width: 27px;
    position: absolute;
    right: 40px;
    top: -9px;
    font-size: 1px;
    line-height: 1;
  }

  .blog-entry-avatar {
    cursor: pointer;
    padding: 0 15px 0 30px;
    text-align: center;
    min-height: 120px;
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    overflow: hidden;
    line-height: 1;
    flex-shrink: 0;
    flex-grow: 0;
  }

  @media (max-width: 650px) {
    .blog-entry-avatar {
      padding: 0 15px 0 10px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-avatar {
      padding: 0px;
      width: 100%;
      min-height: initial;
    }
  }

  .avatar-image-wrapper {
    padding: 0 10px;
    display: flex;
    position: relative;
    max-height: 120px;
    align-items: center;
  }

  @media (max-width: 550px) {
    .avatar-image-wrapper {
      height: initial;
    }
  }

  .avatar-image-wrapper::before {
    display: inline-block;
    content: '';
    border-top: 1px solid ${(p) => p.theme.divider};
    width: 1000px;
    position: absolute;
    top: 50%;
    right: 100%;
  }

  .avatar-image-wrapper::after {
    display: inline-block;
    content: '';
    border-top: 1px solid ${(p) => p.theme.divider};
    width: 1000px;
    position: absolute;
    top: 50%;
    left: 100%;
  }

  .blog-entry-avatar span {
    padding: 0 10px;
    display: flex;
    position: relative;
    max-height: 120px;
    align-items: center;
  }

  .blog-entry-avatar span::after,
  .blog-entry-avatar span::before {
    display: inline-block;
    content: '';
    border-top: 1px solid ${(p) => p.theme.divider};
    width: 1000px;
    position: absolute;
    top: 50%;
  }
  .blog-entry-avatar span::before {
    right: 100%;
  }
  .blog-entry-avatar span::after {
    left: 100%;
  }

  .blog-entry-avatar img {
    max-width: 120px;
    max-height: 120px;
  }

  .blog-entry-author-wrapping {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    flex-shrink: 10;
    flex-basis: 0;
  }

  @media (max-width: 650px) {
    .blog-entry-author-wrapping {
      margin-right: 15px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-author-wrapping {
      flex-basis: 100%;
      margin-left: 15px;
    }

    .blog-entry-author-wrapping a,
    .blog-entry-author-wrapping span {
      flex-basis: 100%;
      padding: 0px;
    }
  }

  .blog-entry-blog {
    position: relative;
    left: 10px;
    height: 60px;
    line-height: 1;
    font-size: 1px;
  }

  @media (max-width: 550px) {
    .blog-entry-blog {
      position: static;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      height: auto;
    }
  }

  .blog-entry-blog a {
    color: ${(p) => p.theme.accentDark};
  }

  .blog-entry-bookmark a,
  .blog-entry-blog a {
    display: inline-block;
  }

  .blog-entry-author {
    font-weight: bold;
    display: block;
    margin-top: -0.5em;
    line-height: 1;
    font-size: 18px;
  }

  @media (max-width: 550px) {
    .blog-entry-author {
      margin-top: 0px;
      text-align: center;
    }

    .blog-entry--no-blog-link .blog-entry-author {
      margin-top: 5px;
    }
  }

  .blog-entry-author a,
  .blog-entry-author a:visited {
    color: ${(p) => p.theme.text};
    padding: 0 10px;

    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .blog-entry-author a::before {
    display: inline-block;
    content: '';
    border-top: 1px solid ${(p) => p.theme.divider};
    width: 8000px;
    position: absolute;
    top: 50%;
    left: 100%;
  }

  ${getBlogEntrySubAuthorCss(true)}

  @media (max-width: 550px) {
    .blog-entry-author a::before {
      border: none;
    }
  }

  .blog-entry-subtext {
    margin-top: 0.3em;
    word-break: break-word;
  }

  .blog-entry-subtext span {
    padding: 0 10px;
    font-size: 12px;
    display: inline-block;
  }

  @media (max-width: 550px) {
    .blog-entry-subtext {
      text-align: center;
    }
  }

  .blog-entry-date {
    color: ${(p) => p.theme.textLight};
    min-width: 128px;
    margin-left: auto;
    margin-right: 40px;
    text-align: right;
    font-size: 0.75em;
    padding-top: 5px;
    flex-grow: 0;
    flex-shrink: 0;
    text-decoration-color: transparent;
  }
  .blog-entry-date .icon-pin {
    height: 1.5em;
    margin-right: 5px;
    position: relative;
    top: 0.5em;
  }
  .entry-date-changed {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .entry-date-changed .icon {
    height: 0.75em;
    margin-right: 5px;
  }
  .blog-entry-date .icon .main-outline {
    fill: ${(p) => p.theme.textLight};
  }

  @media (max-width: 650px) {
    .blog-entry-date {
      flex-basis: 100%;
      text-align: left;
      margin-top: 30px;
      margin-left: 15px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-date {
      margin: 30px 0 0 0;
      padding: 0 15px 0 15px;
    }
  }

  .blog-entry-title {
    font-size: 24px;
    font-family: ${(p) => p.theme.textFont};
    font-weight: bold;
    flex-basis: 100%;
    line-height: 1;
    word-break: break-word;
    margin: 30px 40px 0 40px;
  }

  @media (max-width: 650px) {
    .blog-entry-title {
      margin: 5px 15px 0 15px;
    }
  }

  @media (max-width: 550px) {
    .blog-entry-title {
      text-align: left;
    }
  }

  .blog-entry-title a,
  .blog-entry-title a:visited {
    color: ${(p) => p.theme.text};
    text-decoration: none;
  }

  .blog-entry--no-title {
    display: none;
  }
  .blog-entry-tags {
    clear: both;
    padding: 30px 40px 0 40px;
    text-align: left;

    @media (max-width: 650px) {
      padding: 20px 15px 0 15px;
    }
  }
  .blog-entry-tags a,
  .blog-entry-tags span {
    display: inline-block;
    color: ${(p) => p.theme.textLight};
    text-decoration: none;
    margin-right: 1em;
  }
  .blog-entry-tags a:hover,
  .blog-entry-tags a:focus,
  .blog-entry-tags a:active {
    color: ${(p) => p.theme.accent};
  }

  /****** FEED ENTRY META-DOWN ******/

  .blog-entry-meta--down {
    clear: both;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 20px 40px 0 40px;
    margin-top: 40px;
    border-top: 1px solid ${(p) => p.theme.divider};
  }

  @media (max-width: 650px) {
    .blog-entry-meta--down {
      padding: 20px 20px 0 20px;
    }
  }

  .blog-entry-meta--down a,
  .blog-entry-controls button {
    color: ${(p) => p.theme.accentDark};
  }

  .blog-entry-comment-icon a {
    display: inline-block;
    height: 30px;
  }
  .entry-lock-icon {
    display: inline-block;
    margin-right: 10px;
  }
  .entry-lock-icon .icon {
    height: 0.5em;
  }
  .icon-lock .main-outline {
    fill: ${(p) => p.theme.text};
  }

  .blog-entry-meta--down .blog-entry-interactions {
    line-height: 1;
    height: 30px;
  }

  .comment-number {
    position: relative;
    top: -9px;
    margin-left: 10px;
    text-decoration: none;
  }

  .blog-entry-controls {
    display: flex;
    justify-items: right;
    align-items: center;
    position: relative;
  }

  .blog-entry-controls .icon {
    cursor: pointer;
    margin-left: 20px;
    height: 20px;
  }
  .blog-entry-controls span {
    height: 20px;
  }

  .controls-dropdown-wrapper {
    position: absolute;
    top: 28px;
    left: -80px;
  }

  .blog-entry-controls-link,
  .blog-entry-controls-cog,
  .blog-entry-block-profile {
    position: relative;
  }
  .blog-entry-controls-link .controls-dropdown {
    padding: 10px;
  }

  .blog-entry-controls-link .controls-dropdown input {
    height: 25px;
    width: 100%;
    background: none;
    color: ${(p) => p.theme.text};
    border: none;
  }

  .controls-dropdown {
    position: relative;
    background: ${(p) => p.theme.backgroundLighter};
    z-index: 2000;
    width: 150px;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    box-shadow: 0px 0px 6px 0px ${(p) => p.theme.shadow};
  }

  .controls-dropdown:before {
    position: absolute;
    top: -9px;
    right: 30px;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${(p) => p.theme.backgroundLighter};
    z-index: 1999;
  }

  .controls-dropdown:after {
    position: absolute;
    top: -11px;
    right: 28px;
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 11px solid rgba(0, 0, 0, 0.1);
    filter: blur(1px);
    z-index: 1998;
  }

  .controls-dropdown ul {
    list-style-type: none;
  }

  .controls-dropdown ul li button,
  .controls-dropdown ul li a {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 20px;
    font-size: 12px;
    color: ${(p) => p.theme.text};
    text-decoration: none;
  }

  @media (max-width: 550px) {
    .controls-dropdown ul li button,
    .controls-dropdown ul li a {
      font-size: 1em;
    }
  }

  .controls-dropdown ul li button:hover,
  .controls-dropdown ul li a:hover {
    padding-left: 9px;
    background: ${(p) => p.theme.backgroundLight};
    border-left: 10px solid ${(p) => p.theme.brand};
    color: ${(p) => p.theme.text};
    font-weight: bold;
  }

  /****** ICONS ******/

  .blog-entry .icon-comments {
    height: 30px;
    width: 34px;
  }

  .blog-entry .icon-journal,
  .blog-entry .icon-chat {
    height: 27px;
    width: 27px;
  }

  .blog-entry .icon-bookmark-normal-view {
    height: 30px;
  }

  .blog-entry .icon-settings-cog {
    height: 20px;
    width: 20px;
  }

  .blog-entry-bookmark .icon .main-outline,
  .blog-entry-author-wrapping .icon .main-outline,
  .blog-entry-interactions .icon .main-outline,
  .blog-entry-bookmark .icon .filling-outline,
  .blog-entry-author-wrapping .icon .filling-outline,
  .blog-entry-interactions .icon .filling-outline,
  .blog-entry-bookmark .icon .filling-area,
  .blog-entry-author-wrapping .icon .filling-area,
  .blog-entry-interactions .icon .filling-area {
    fill: ${(p) => p.theme.accentDark};
  }

  .blog-entry-controls .icon-watch-thread.active .filling-area {
    transition: fill 0.2s;
    fill: ${(p) => p.theme.accentDark};
    opacity: 1;
  }
  .blog-entry-controls .blog-entry-controls-watch {
    height: 17px;
  }
  .blog-entry-controls-watch .icon-watch-thread {
    height: 17px;
  }

  .blog-entry-controls .icon .filling-area,
  .blog-entry-controls .icon .main-outline {
    fill: ${(p) => p.theme.textLight};
    opacity: 0.5;
  }

  .blog-entry-controls .icon:hover .main-outline {
    transition: opacity 0.2s;
    opacity: 1;
  }

  .blog-entry-bookmark .icon .secondary-filling-area,
  .blog-entry-author-wrapping .icon .secondary-filling-area,
  .blog-entry-interactions .icon .secondary-filling-area {
    opacity: 0;
  }

  .blog-entry-author-wrapping .icon .filling-area,
  .blog-entry-interactions .icon .filling-area {
    transition: opacity 0.2s;
    opacity: 0;
  }

  .blog-entry-author-wrapping .icon:hover .filling-area,
  .blog-entry-interactions .icon:hover .filling-area {
    opacity: 1;
  }

  .blog-entry .icon-bookmark-normal-view .main-outline {
    fill: ${(p) => p.theme.text};
  }

  .blog-entry .icon-bookmark-normal-view .filling-area {
    fill: ${(p) => p.theme.backgroundLighter};
    transition: fill 0.2s;
    cursor: pointer;
    opacity: 1;
  }

  .blog-entry .icon-bookmark-normal-view .secondary-filling-area {
    fill: ${(p) => darkenColor(p.theme.backgroundLighter)};
    cursor: pointer;
    opacity: 1;
  }

  .blog-entry .active .icon-bookmark-normal-view .filling-area {
    fill: ${(p) => p.theme.brand};
  }
  .blog-entry .active .icon-bookmark-normal-view .secondary-filling-area {
    fill: ${(p) => darkenColor(p.theme.brand)};
  }
`;

const DybrEntry = (props) => {
  return (
    <Wrapper>
      <Entry {...props} />
    </Wrapper>
  );
};

export default DybrEntry;
