import { getDybrVersion } from '@/utils/helpers/getDybrVersion';
import { parseToken } from '@/store/user/reducer/parseToken';
import { useSelector } from 'react-redux';

function getParsedStorage(storage) {
  const parsedStorage = {};

  for (const key in storage) {
    const values = storage[key];
    if (key === 'lists') {
      console.log('values: ', values);
    }
    const item = {
      ...values,
      ...(key === 'lists'
        ? {
            profiles: `-- removed ${
              Object.keys(values?.profiles ?? {}).length
            } profiles by error report parser --`
          }
        : {})
    };

    parsedStorage[key] = item;
  }
  return parsedStorage;
}

function parseError(error, errorInfo) {
  const errorMeta = {
    name: error.name,
    message: error.message,
    'error.toString': error.toString(),
    'columnNumber: ': error.columnNumber,
    'fileName: ': error.fileName,
    'lineNumber: ': error.lineNumber,
    'stack: ': error.stack,
    componentStack: errorInfo.componentStack
  };
  return errorMeta;
}

export function formReportHtml({
  userToken,
  userInfo,
  userMessage,
  userEmail,
  appVersion,
  appStoredVersion,
  appStorage,
  browserInfo,
  errorMeta: error
}) {
  console.log(appStorage);

  const messageItems = [''];

  if (userMessage) {
    messageItems.push(
      `<strong>Сообщение пользователя:</strong>
       <br />
       <pre>${userMessage}</pre>`
    );
  }

  if (userEmail) {
    messageItems.push(
      `<strong>Email для ответа:</strong>
       <br />
       <pre>${userEmail}</pre>`
    );
  }

  if (error) {
    messageItems.push(`<strong>Error:</strong>
       [MORE=показать]
       <em>Javascript Error Info:</em>

       <pre>${JSON.stringify(error, undefined, 2)}</pre>

       <em>React Error Info:</em>
      <pre>${error?.name} ${error?.message}
      ${error.componentStack.toString()}</pre>
      [/MORE]`);
  }

  messageItems.push(`<strong>Версия дыбра:</strong> <br />
    in package.json <pre>${appVersion}</pre> <br />
    stored in local storage: <pre>${appStoredVersion}</pre> <br />
  `);
  messageItems.push(`<strong>Информация о токене:</strong> <br />
    <pre>${JSON.stringify(userInfo, undefined, 2)}</pre>
  `);

  messageItems.push(`<strong>Информация о браузере:</strong>
    <br />
    [MORE=показать]
    <pre>${JSON.stringify(browserInfo, undefined, 2)}</pre>
    [/MORE]`);

  messageItems.push(`<strong>Состояние local storage:</strong> <br />
    [MORE=показать]
    <pre>${JSON.stringify(appStorage, undefined, 2)}</pre>

   [/MORE]`);
  return messageItems.join('<br /><br />');
}

export function exportReportData({ error, errorInfo, storage }) {
  const userToken = localStorage.getItem('token')?.replaceAll('"', '') ?? '';
  const userInfo = parseToken(userToken);
  const appVersion = getDybrVersion();
  const appStoredVersion = localStorage.getItem('storedVersion');
  const appStorage = getParsedStorage(storage);
  const browserInfo = getBrowserInfo();
  const errorMeta = error ? parseError(error, errorInfo) : null;

  return {
    userToken,
    userInfo,
    appVersion,
    appStoredVersion,
    appStorage,
    browserInfo,
    errorMeta
  };
}

//https://stackoverflow.com/questions/11219582/how-to-detect-my-browser-version-and-operating-system-using-javascript
export function getBrowserInfo() {
  let nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = '' + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(' ')) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return {
    browserName,
    fullVersion,
    majorVersion,
    appName: navigator.appName,
    userAgent: navigator.userAgent
  };
}
