import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { hexToRgba } from '@/utils/helpers/colors';
import TextField from '@/components/FormElements/TextField';
import Editor from '@/components/Froala/Editor';
import { ActionButton } from '@/components/Shared/Buttons';
import config from '@/configs/dybr';

import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from '@/components/DybrComponents/StyledServicePagesElements';
import { BirthdaySetting } from '@/scenes/User/Settings/BirthdaySetting';
import { connect } from 'react-redux';
import { updateProfileThunk } from '@/store/user/thunks/updateProfileThunk';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

const StyledEditorLabel = styled.p`
  color: ${p => p.theme.textLight};
  font-size: 1em;
  margin-bottom: 5px;
`;
const StyledErrorLabel = styled(StyledEditorLabel)`
  color: ${p => p.theme.brand};
`;
const StyledEditorWrapper = styled.div`
  margin-bottom: 15px;

  .fr-box.fr-basic .fr-element {
    min-height: 200px !important;
  }
  .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none;
    border: 1px solid ${p => p.theme.divider};
  }

  .fr-toolbar {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .fr-toolbar.fr-sticky-on {
    background: ${p => hexToRgba(p.theme.backgroundLighter, 1)};
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.3),
      0 -8px 10px -10px rgba(0, 0, 0, 0.3);
  }
`;

const validateSubtext = text => {
  const maxLen = config.MAX_LENGTH.subtext;
  return text && text.length > maxLen
    ? `подпись не может быть больше ${maxLen} символов`
    : '';
};

function AboutSettings({ profile, updateProfileThunk }) {
  const [subtext, setSubtext] = useState(profile.settings.subtext ?? '');
  const [subtextError, setSubtextError] = useState(
    validateSubtext(profile.settings.subtext)
  );
  const [description, setDescription] = useState(profile.description ?? '');
  const [birthday, setBirthday] = useState(profile.birthday);
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();
  const [error, setError] = useState('');

  useEffect(() => {
    setSubtext(profile.settings.subtext ?? '');
    setSubtextError(validateSubtext(profile.settings.subtext));
    setBirthday(profile.birthday);
    setDescription(profile.description ?? '');
  }, [profile]);

  const validateAboutSection = () => {
    let valid = true;
    setError('');

    if (description.length < 3) {
      setError('Описание не может быть пустым');
      valid = false;
    }

    return valid;
  };

  const updateAboutSection = () => {
    const valid = validateAboutSection();
    if (!valid) return;

    const data = {};

    if (subtext !== profile.settings.subtext) {
      data.settings = {
        ...profile.settings,
        subtext
      };
    }
    if (description !== profile.description) {
      data.description = description;
    }
    if (birthday !== profile.birthday) {
      data.birthday = birthday;
    }

    updateProfileThunk({ data, token, activeProfileId });
  };

  const changeSubtext = e => {
    const text = e.target.value;
    setSubtext(text);
    setSubtextError(validateSubtext(text));
  };

  const canSaveAboutSection = () => {
    const subtextChanged = subtext !== profile.settings.subtext;
    const descriptionChanged = description !== profile.description;
    const birthdayChanged = birthday !== (profile.birthday ?? '');

    return (
      !subtextError && (descriptionChanged || birthdayChanged || subtextChanged)
    );
  };

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>О себе</h4>
          <p>
            Здесь вы можете отредактировать информацию, которую видят о вас
            другие пользователи
          </p>
        </SettingSectionTitle>
        <Settings>
          <TextField
            label="подпись"
            isRequired={false}
            value={subtext}
            onChange={changeSubtext}
            maxLen={config.MAX_LENGTH.subtext}
            error={subtextError}
            touched
          />
          <BirthdaySetting birthday={birthday} onChange={setBirthday} />

          <StyledEditorLabel>пара слов или картинок о вас:</StyledEditorLabel>
          <StyledEditorWrapper>
            <Editor
              value={description}
              onChange={value => {
                setDescription(value);
                setError('');
              }}
              onInit={() => {}}
              onSubmitByEnter={setDescription}
            />
          </StyledEditorWrapper>
          <StyledErrorLabel>{error}</StyledErrorLabel>
          {canSaveAboutSection() && (
            <ActionButton onClick={updateAboutSection}>сохранить</ActionButton>
          )}
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileThunk
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(AboutSettings)
);
