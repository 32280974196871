import React from 'react';
import Select from '@/components/FormElements/Select';
import designConstants from '@/configs/designConstants';
import { SettingWrapper } from '@/scenes/Blog/designer/components/styled';

const FONT_SIZE_OPTIONS = (() => {
  let items = [];
  let realIndex = 0;
  for (let i = 10; i <= 100; i += 2) {
    items.push({ value: realIndex, settingsValue: i, label: i.toString() });
    realIndex++;
  }
  return items;
})();
/*
const LINE_HEIGHT_OPTIONS = [
  { value: 1, label: '1' },
  { value: 1.2, label: '1.2' },
  { value: 1.5, label: '1.5' },
  { value: 1.7, label: '1.7' },
  { value: 2, label: '2' },
];*/

class FontSettings extends React.Component {
  getFontFamilySettingID = () => {
    const { family } = this.props.setting;
    return designConstants.fontFamilies.findIndex(f => f.value === family);
  };

  getFontStyleSettingID = () => {
    const { weight, style, textDecoration } = this.props.setting;
    return designConstants.fontStyleTypes.findIndex(
      f =>
        f.weight === weight &&
        f.style === style &&
        f.textDecoration === textDecoration
    );
  };

  getFontFamilyOptions = () => {
    return designConstants.fontFamilies.map((f, i) => ({
      label: <span style={{ fontFamily: f.value }}>{f.label}</span>,
      value: f.value
    }));
  };
  getFontSizeSettingID = () => {
    return FONT_SIZE_OPTIONS.findIndex(
      o => o.settingsValue === this.props.setting.size
    );
  };
  getFontStyleOptions = () => {
    return designConstants.fontStyleTypes.map((f, i) => ({
      label: (
        <span
          style={{
            fontFamily: this.props.setting.family,
            fontWeight: f.weight,
            fontStyle: f.style,
            textDecoration: f.textDecoration || 'none'
          }}
        >
          {f.label}
        </span>
      ),
      value: i
    }));
  };
  handleFontFamilyChange = ID => {
    this.props.update(this.props.nodeKey + '.family')(
      designConstants.fontFamilies[ID].value
    );
  };
  handleFontSizeChange = ID => {
    this.props.update(this.props.nodeKey + '.size')(
      FONT_SIZE_OPTIONS[ID].settingsValue
    );
  };
  handleFontStyleChange = ID => {
    this.props.update(this.props.nodeKey)({
      ...this.props.setting,
      weight: designConstants.fontStyleTypes[ID].weight,
      style: designConstants.fontStyleTypes[ID].style,
      textDecoration: designConstants.fontStyleTypes[ID].textDecoration
    });
  };

  render() {
    const { label, menuPlacement, nodeKey } = this.props;
    if (!nodeKey) return null;

    return (
      <SettingWrapper className="font-settings">
        <div className="label-section">{label}</div>
        <div className="select-section">
          <Select
            options={this.getFontFamilyOptions()}
            captureMenuScroll={true}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            isSearchable={true}
            menuPlacement={menuPlacement}
            selectedOptionID={this.getFontFamilySettingID()}
            onChange={this.handleFontFamilyChange}
          />
          <div className="double-select">
            <Select
              options={FONT_SIZE_OPTIONS}
              captureMenuScroll={true}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              isSearchable={true}
              className="select-font-size"
              menuPlacement={menuPlacement}
              selectedOptionID={this.getFontSizeSettingID()}
              onChange={this.handleFontSizeChange}
            />
            <Select
              options={this.getFontStyleOptions()}
              captureMenuScroll={true}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              isSearchable={true}
              menuPlacement="auto"
              className="select-font-style"
              selectedOptionID={this.getFontStyleSettingID()}
              onChange={this.handleFontStyleChange}
            />
          </div>
        </div>
      </SettingWrapper>
    );
  }
}

export default FontSettings;
