import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from '@/components/DybrComponents/StyledServicePagesElements';
import TextField from '@/components/FormElements/TextField';
import configs from '@/configs/dybr';
import { ActionButton } from '@/components/Shared/Buttons';
import React, { useState } from 'react';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { updateProfileThunk } from '@/store/user/thunks/updateProfileThunk';
import { connect } from 'react-redux';
import { validateBlogSetting } from '@/scenes/User/Settings/Blog/validateBlogSetting';
import { validateBlogSlug } from '@/utils/helpers/validate';
import { getProfileByBlogSlug } from '@/api/profiles';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

function BlogTitle({ profile, updateProfileThunk }) {
  const [title, setTitle] = useState(profile.blogTitle);
  const [slug, setSlug] = useState(profile.blogSlug);
  const [error, setError] = useState({ title: '', slug: '' });
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  function handleTitleChange(e) {
    setTitle(e.target.value);
    setError({
      ...error,
      title: validateBlogSetting(e.target.value, 'своему дневнику')
    });
  }

  const handleSlugChange = async ({ target: { value } }) => {
    setSlug(value);
    if (value === profile.blogSlug) {
      setError({ ...error, slug: '' });
      return;
    }
    let err = validateBlogSlug(value);
    if (!err) {
      const res = await getProfileByBlogSlug({ slug: value, token });
      if (!res.error) {
        err = 'адрес занят';
      }
    }
    setError({ ...error, slug: err });
  };
  const isChanged =
    (title !== profile.blogTitle || slug !== profile.blogSlug) &&
    !error.title &&
    !error.slug;

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>О дневнике</h4>
          <p>Базовая информация</p>
        </SettingSectionTitle>
        <Settings>
          <TextField
            label="название дневника"
            isRequired
            value={title}
            onChange={handleTitleChange}
            maxLen={configs.MAX_LENGTH.blogTitle}
            error={error.title}
            touched
          />
          <TextField
            label="адрес дневника"
            isRequired
            value={slug}
            onChange={handleSlugChange}
            maxLen={configs.MAX_LENGTH.blogSlug}
            error={error.slug}
            touched
          />
          {
            <ActionButton
              style={{ display: isChanged ? 'block' : 'none' }}
              disabled={!isChanged}
              onClick={() =>
                updateProfileThunk({
                  data: { blogTitle: title, blogSlug: slug },
                  token,
                  activeProfileId
                })
              }
            >
              сохранить
            </ActionButton>
          }
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  updateProfileThunk
};

export const BlogTitleContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(BlogTitle)
);
