import { useLocation, useNavigate } from 'react-router-dom';

import {
  getFeedEntriesApi,
  getFilteredEntriesApi,
  removeEntryApi
} from '@/api/entries';
import Entry from '@/components/DybrComponents/DybrEntry';

import Pagination from '@/components/DybrComponents/DybrPagination';
import { canManipulate } from '@/components/Shared/Entry/Entry';
import Loading from '@/components/Shared/Loading';
import queryString from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import css from '@/scenes/Dybr/Feeds/CommonFeed.module.css';
import { useBookmark } from '@/scenes/hooks/useBookmark';

import { hideProfile } from '@/store/lists/operations';
import { hideProfileFromFeedForAll } from '@/store/lists/thunks/hideProfileFromFeedForAll';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { selectUserSettingsAugmented } from '@/store/user/selectors/selectUserSettingsAugmented';
import { classNames } from '@/utils/helpers/classNames';
import { parseSearchParams } from '../../../utils/helpers/searchParamUtils';

function CommonFeed({
  userSettings,
  profile,
  isFiltered: hasSearchParams,
  hideProfileFromFeed,
  hideProfileFromFeedForAll
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [countDown, setCountDown] = useState(5);
  const [token] = useTokenState();
  const [totalEntries, setTotalEntries] = useState(0);
  const loggedIn = Boolean(token);

  const parsed = queryString.parse(location.search);
  const page = Number(parsed.page || parsed.p) || 1;
  const pageStarter = useRef(Math.floor(Date.now() / 1000));
  const pageSize = userSettings.pagination.entries;
  const autocutEnabled = profile.settings.feed.autocutLength
    ? profile.settings.feed.autocutLength > 0
    : true; // enable autocut by default

  useEffect(() => {
    if (hasSearchParams && !loggedIn) {
      //Redirect from search page to main page for unauthorized user
      setRedirect(true);
      setTimeout(() => navigate('feed'), 5000);
    }
    document.title = hasSearchParams
      ? `Результаты поиска - Дыбр`
      : `Общая лента - Дыбр`;
  }, []);

  useEffect(() => {
    if (hasSearchParams && !loggedIn) {
      const intervalId = setInterval(() => setCountDown(countDown - 1), 1000);
      return () => clearInterval(intervalId);
    }
  }, [countDown]);

  const loadEntries = useCallback(async () => {
    setError(false);
    setLoading(true);

    if (page === 1) {
      pageStarter.current = Math.floor(Date.now() / 1000);
    }

    const filters = parseSearchParams(location.search);

    const res =
      filters && ((filters.tags && filters.tags.length) || filters.content)
        ? await getFilteredEntriesApi({
            pageNumber: page,
            pageStarter: pageStarter.current,
            pageSize,
            ...filters,
            token
          })
        : await getFeedEntriesApi({
            pageNumber: page,
            pageStarter: pageStarter.current,
            pageSize,
            token
          });

    if (!res.error) {
      setEntries(res.data);
      setTotalEntries(res.meta.totalEntries);
    } else {
      setError(true);
    }
    setLoading(false);
  }, [pageSize, page, location.search, token]);

  useEffect(() => {
    if (hasSearchParams && !loggedIn) {
      return;
    }
    window.scrollTo(0, 0);
    loadEntries();
    // eslint-disable-next-line
  }, [pageSize, page, location.search, token]);

  const { toggleBookmark } = useBookmark({ setEntries, token });

  function deleteEntry(id) {
    setEntries(currentEntries => currentEntries.filter(e => e.id !== id));
    removeEntryApi({ entryId: id, token });
  }

  const renderPagination = type => {
    const searchParams = new URLSearchParams(location.search);

    const handlePageChange = newPage => {
      searchParams.set('page', newPage);
      navigate(location.pathname + '?' + searchParams.toString());
    };

    return (
      <Pagination
        classModifier={type}
        currentPage={page}
        pageSize={pageSize}
        totalRecords={totalEntries}
        unknownSize={!hasSearchParams}
        maxFirst={5}
        onPageChange={handlePageChange}
      />
    );
  };

  return (
    <div className={css.container}>
      <div className={css.block}>{renderPagination('top')}</div>

      {loading && (
        <div
          className={classNames({
            [css.block]: true,
            [css.loading]: true
          })}
        >
          <Loading />
        </div>
      )}

      {!loading && error && (
        <div className={css.block}>Что-то пошло не так.</div>
      )}

      {!redirect && entries.length === 0 && (
        <div className={css.block}>Ничего не найдено или все записи скрыты</div>
      )}

      {redirect && (
        <div className={css.block}>
          Зарегистрируйтесь или войдите, чтобы воспользоваться поиском.
          Возвращаемся в общую ленту через {countDown}...
        </div>
      )}

      {entries.map(entry => (
        <div className={css.block} key={entry.id}>
          <Entry
            entry={entry}
            canManipulate={canManipulate(loggedIn, profile.id, entry)}
            canManageWatching={loggedIn && entry.profile.id !== profile.id}
            hasUserFunctions={loggedIn}
            canBlockProfile={loggedIn && entry.profile.id !== profile.id}
            hideProfileFromFeed={async profileToHide => {
              await hideProfileFromFeed({ profile: profileToHide, token });
              loadEntries();
            }}
            hideProfileFromFeedForAll={async (profileToHide, reason) => {
              await hideProfileFromFeedForAll({
                profile: profileToHide,
                token,
                reason
              });
              loadEntries();
            }}
            onDelete={deleteEntry}
            onToggleBookmark={() => toggleBookmark(entry)}
            allowedAdminActions={profile.admin}
            hideLargeContent={autocutEnabled}
            type="feed-entry"
          />
        </div>
      ))}
      <div className={css.block}>
        {!loading && entries.length !== 0 && renderPagination('bottom')}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId }),
    userSettings: selectUserSettingsAugmented(state)
  };
};

const mapDispatchToProps = {
  hideProfileFromFeed: hideProfile,
  hideProfileFromFeedForAll
};

export const CommonFeedContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(CommonFeed)
);
