import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from '@/components/DybrComponents/StyledServicePagesElements';
import React, { useState } from 'react';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { connect } from 'react-redux';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import Switch from '@/components/FormElements/Switch';
import { updateProfileSetting } from '@/store/user/thunks/updateProfileSetting';
import { selectActiveProfileSettingsAugmented } from '@/store/user/selectors/selectActiveProfileSettingsAugmented';

// This is what we have in settings:
//   "community": {
//     anyone-can-create-tags: false
//     anyone-can-raise: false
//   }

function CommunityPostSettings({ profile, settings, updateProfileSetting }) {
  const [anyoneCanRaisePost, setAnyoneCanRaisePost] = useState(
    settings.community?.anyoneCanRaise ?? false
  );
  const [anyoneCanCreateTags, setAnyoneCanCreateTags] = useState(
    settings.community?.anyoneCanCreateTags ?? false
  );

  const [token] = useTokenState();

  if (!profile.isCommunity) {
    return null;
  }

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Настройки постов</h4>
          <p>
            Выберите, могут ли участники сообщества поднимать посты и добавлять
            теги
          </p>
        </SettingSectionTitle>
        <Settings>
          <Switch
            label="участники могут создавать теги"
            checked={anyoneCanCreateTags}
            onClick={(e) => {
              const anyoneCan = e.target.checked;
              setAnyoneCanCreateTags(anyoneCan);
              updateProfileSetting(
                `community.anyoneCanCreateTags`,
                anyoneCan,
                token
              );
            }}
          />
          <Switch
            label="участники могут поднимать посты"
            checked={anyoneCanRaisePost}
            onClick={(e) => {
              const anyoneCan = e.target.checked;
              setAnyoneCanRaisePost(anyoneCan);
              updateProfileSetting(
                `community.anyoneCanRaise`,
                anyoneCan,
                token
              );
            }}
          />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId }),
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  updateProfileSetting
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(CommunityPostSettings)
);
