import React, { useEffect } from 'react';
import styled from 'styled-components';
import ContentWrap from '@/components/DybrComponents/ContentWrap';
import { HOSTNAME } from '@/configs/dybr';

import {
  SectionNarrowBlock,
  SectionTitle
} from '@/components/DybrComponents/Section';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin: 0 30px;
  h3 {
    margin: 30px 0 20px 0;
  }
  p {
    text-align: unset;
  }
`;

const ForbiddenActions = () => {
  useEffect(() => {
    document.title = `Правила и политика конфиденциальности - Дыбр`;
  }, []);

  return (
    <ContentWrap>
      <SectionTitle data-testid="terms">
        Запрещённые действия и материалы
      </SectionTitle>
      <SectionNarrowBlock size="650px">
        <Wrapper>
          <p>
            Запрещены предложения сексуальных услуг, публикация информации о
            покупке и продаже оружия, алкоголя и табачных изделий, а также
            информации о покупке и продаже наркотических средств.
          </p>
          <p>
            <Link to={HOSTNAME + '/terms'}>Вернуться к списку правил</Link>
          </p>
        </Wrapper>
      </SectionNarrowBlock>
    </ContentWrap>
  );
};

export default ForbiddenActions;
