import { merge } from 'lodash-es';
import { createSelector } from 'reselect';

import defaultUserSettings from '@/configs/defaultUserSettings';

import { selectUser } from './selectUser';

export const selectUserSettingsAugmented = createSelector(selectUser, user =>
  merge({}, defaultUserSettings, user.settings)
);
