import { requestPasswordChangeApi } from '@/api/auth';

// currently it is not a thunk, but I suppose it will become one when
// we start handling errors properly
export const requestNewPasswordThunk = ({ email }) => async () =>
  // dispatch,
  // getState
  {
    const res = await requestPasswordChangeApi(email);
    if (!res.error) {
      return { status: 'ok' };
    } else {
      return { error: res.error };
    }
  };
