import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { createCommentApi } from '@/api/comments';
import ContentWrap from '@/components/DybrComponents/ContentWrap';
import {
  SectionNarrowBlock,
  SectionTitle
} from '@/components/DybrComponents/Section';
import TextField from '@/components/FormElements/TextField';
import { ImportantButton } from '@/components/Shared/Buttons';
import { SLON_MODE } from '@/configs/dybr';
import NumberInRangeSetting from '@/scenes/Blog/designer/components/NumberInRangeSetting';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';

function TestComments({ ownProfile }) {
  const [faker, setFaker] = useState(null);
  const [entryId, setEntryId] = useState('');
  const [amount, setAmount] = useState(10);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [token] = useTokenState();

  useEffect(() => {
    // Import faker dynamically, so it won't be bundled in production
    if (SLON_MODE) {
      import('@faker-js/faker').then(module => setFaker(module.default));
    }
  }, []);

  async function generateComments() {
    setLoading(true);
    setMessage('генерируем...');
    for (let i = 1; i < amount + 1; i++) {
      const res = await createCommentApi({
        content: `${i} ${faker ? faker.hacker.phrase() : ''}`,
        entryId,
        profileId: ownProfile.id,
        token
      });
      if (res.error || !res.data) {
        setMessage(res.error ?? 'что-то пошло не так');
        setLoading(false);
        return;
      }
    }
    setLoading(false);
    setMessage('готово!');
  }

  return (
    <ContentWrap>
      <SectionTitle>Генератор комментов</SectionTitle>
      <SectionNarrowBlock size="800px">
        <p>
          На этой странице можно сгенерировать нужное количество комментов к
          любой записи. Комменты будут написаны от текущего аккаунта
        </p>
        <br />
        <TextField
          label="entry id (цифра из URL поста)"
          value={entryId}
          onChange={e => setEntryId(e.target.value)}
          touched
        />
        <br />

        <NumberInRangeSetting
          min={1}
          max={1000}
          value={amount}
          label="количество комментов"
          units=""
          onChange={value => {
            if (value === undefined) {
              return;
            }
            setAmount(value);
          }}
        />
        <br />
        <br />

        <ImportantButton
          onClick={generateComments}
          disabled={loading || !entryId || !amount}
        >
          Сгенерировать комменты!
        </ImportantButton>
        {message && <span style={{ marginLeft: '20px' }}>{message}</span>}
      </SectionNarrowBlock>
    </ContentWrap>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export const TestCommentsContainer = WithActiveProfileId(
  connect(mapStateToProps)(TestComments)
);
