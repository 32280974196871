import React, { useState } from 'react';
import TagIcon from '@/styles/Icons/Tag';
import Select from '@/components/FormElements/Select';

const BlogFeedControl = props => {
  const {
    tags = [],
    selectedTags,
    onTagSelect,
    page = 1,
    onPageSelect = () => {},
    onSortSelect = () => {},
    sort = 'desc',
    pages = 1
  } = props;

  const [tagsOpen, setTagsOpen] = useState(false);
  const pageOptions = [];

  if (pages > 1) {
    for (let i = 1; i <= pages; i++) {
      pageOptions.push({ label: i, value: i });
    }
  }

  const sortOptions = [
    { label: 'от старых к новым', value: 'asc' },
    { label: 'от новых к старым', value: 'desc' }
  ];

  return (
    <div>
      <div className={`blog-feed-control`} data-testid="blog-feed-control">
        <div className="feed-settings">
          {pages > 1 && (
            <div className="feed-setting__page">
              <div className="page-select-label">страница</div>
              <Select
                options={pageOptions}
                className="page-select"
                captureMenuScroll={true}
                menuPlacement="auto"
                blogSelect={true}
                selectedOptionID={page - 1}
                onChange={id => onPageSelect(id + 1)}
              />
            </div>
          )}
          <div className="feed-setting__sort">
            <div className="sort-select-label">сортировать</div>
            <Select
              options={sortOptions}
              className="sort-select"
              captureMenuScroll={true}
              menuPlacement="auto"
              blogSelect={true}
              // rewrite if we ever get more sorting options
              selectedOptionID={sortOptions.findIndex(o => o.value === sort)}
              onChange={id => onSortSelect(sortOptions[id].value)}
            />
          </div>
        </div>
        <div className="icon-buttons">
          {(!selectedTags || !selectedTags.length) && (
            <span
              onClick={() => setTagsOpen(!tagsOpen)}
              className={
                tagsOpen || selectedTags.length ? 'tags tags-active' : 'tags'
              }
            >
              <TagIcon />
            </span>
          )}
          {selectedTags.map(selectedTag => (
            <span
              key={selectedTag.id}
              onClick={() => setTagsOpen(!tagsOpen)}
              className={
                tagsOpen || selectedTags.length ? 'tags tags-active' : 'tags'
              }
            >
              <TagIcon />
              <span className="selected-tag">{selectedTag}</span>
            </span>
          ))}
        </div>
      </div>
      {tagsOpen && (
        <div className={`tags-block--top ${tagsOpen ? '' : 'hidden'}`}>
          {tags.length === 0 && (
            <div className="tag-name">В этом блоге нет тегов</div>
          )}
          {tags.length > 0 &&
            tags
              .slice()
              .sort((a, b) => {
                if (a.entries < b.entries) return 1;
                if (a.entries > b.entries) return -1;
                return a.name <= b.name ? 1 : -1;
              })
              .map(tag => (
                <div
                  onClick={() => {
                    onTagSelect(tag.name);
                    setTagsOpen(false);
                  }}
                  key={tag.name}
                  className={`tag ${
                    selectedTags.includes(tag.name) ? 'tag--selected' : ''
                  }`}
                  title={tag.name}
                >
                  <span className="tag-name">{tag.name}</span>
                  <span className="tag-entries-count">{tag.entries}</span>
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default BlogFeedControl;
