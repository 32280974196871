import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import Entry, { canManipulate } from '@/components/Shared/Entry/Entry';
import Pagination from '@/components/BlogComponents/BlogPagination';
import Loading from '@/components/Shared/Loading';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { selectUserSettingsAugmented } from '@/store/user/selectors/selectUserSettingsAugmented';
import { connect } from 'react-redux';
import { useBookmark } from '@/scenes/hooks/useBookmark';
import { removeEntryApi } from '@/api/entries';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import { useLocation } from 'react-router-dom';

function unknownPaginationSize(path) {
  return path === 'favorites' || path === 'communities';
}

function getPageTitle(path) {
  switch (path) {
    case 'favorites':
      return 'Избранное';
    case 'bookmarks':
      return 'Закладки';
    case 'communities':
      return 'Мои сообщества';
  }
}

/**
 * fetch entries with correct page settings
 * display pagination if there are more entries
 * do not display pagination if there aren't more entries
 * render entries with all data necessary
 */

export function BlogFavorites({ settings, ownerProfile, ownProfile, apiCallback }) {
  const location = useLocation();
  const [loadingEntries, setLoadingEntries] = useState(true);
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState('');
  const [totalBookmarks, setTotalBookmarks] = useState();
  const [token] = useTokenState();
  const loggedIn = Boolean(token);

  const path = useLocation().pathname.split('/').pop();

  useEffect(() => {
    document.title = `${ownerProfile.nickname} - ${getPageTitle(path)} - Дыбр`;
  }, [ownerProfile.nickname, path]);

  const { toggleBookmark } = useBookmark({ setEntries, token });

  // const [failed, setFailed] = useState(false);
  // todo toast notification when failed??

  const parsed = queryString.parse(location.search);
  const page = Number(parsed.page || parsed.p) || 1;
  const pageSize = settings.pagination.entries;

  async function loadEntries(pageNumber) {
    setError('');

    const res = await apiCallback({
      profileId: ownerProfile.id,
      pageSize,
      pageNumber,
      token
    });

    if (!res || !res.data) {
      setLoadingEntries(false);
      setError('Ошибка загрузки');
      return;
    }

    const newEntries =
      path === 'bookmarks'
        ? res.data.map(b => b.entry).filter(b => b)
        : res.data;
    setTotalBookmarks(res.meta?.totalBookmarks);
    setEntries(newEntries);
    setLoadingEntries(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingEntries(true);

    loadEntries(page);
  }, [page, apiCallback, ownerProfile.id, pageSize, path, token]);

  const deleteEntry = async entryId => {
    const res = await removeEntryApi({ entryId, token });

    if (res.error) {
      window.alert(res.error);
      return;
    }
    await loadEntries(page);
  };

  function renderPagination(type) {
    const unknownSize = unknownPaginationSize(path);

    return (
      <Pagination
        classModifier={type}
        totalRecords={unknownSize ? entries.length : totalBookmarks}
        currentPage={page}
        unknownSize={unknownSize && entries.length >= pageSize}
        pageSize={pageSize}
        url={`/blog/${ownerProfile.blogSlug}/${path}?page=`}
      />
    );
  }

  if (!loadingEntries && !entries.length) {
    return (
      <div className="blog-info-block">
        {error || 'Похоже, что тут ничего нет.'}
      </div>
    );
  }

  return (
    <>
      {renderPagination('top')}
      {loadingEntries ? (
        <Loading />
      ) : (
        <>
          {entries.map(entry => (
            <Entry
              type="feed-entry"
              key={entry.id}
              entry={entry}
              canManipulate={canManipulate(loggedIn, ownProfile.id, entry)}
              canManageWatching={loggedIn && entry.profile.id !== ownProfile.id}
              hasUserFunctions={loggedIn}
              onToggleBookmark={() => toggleBookmark(entry)}
              onDelete={deleteEntry}
            />
          ))}
          {renderPagination('bottom')}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectUserSettingsAugmented(state),
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export const BlogFavoritesContainer = WithActiveProfileId(
  connect(mapStateToProps)(BlogFavorites)
);
