import { merge } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  createCommentApi,
  getEntryCommentsApi,
  removeCommentApi,
  updateCommentApi
} from '@/api/comments';
import BlogComment from '@/components/BlogComponents/BlogComment';
import Pagination from '@/components/BlogComponents/BlogPagination';
import Loading from '@/components/Shared/Loading';
import defaultProfileSettings from '@/configs/defaultProfileSettings';
import { useCommentDraftById } from '@/store/localStorage/drafts/useCommentDraftById';
import { useSetCommentDraft } from '@/store/localStorage/drafts/useSetCommentDraft';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import { readComments } from '@/store/notifications/operations/readComments';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { selectUserSettingsAugmented } from '@/store/user/selectors/selectUserSettingsAugmented';

import { CommentEditFormContainer } from './CommentEditForm';
import { useEntryPageContext } from './EntryPageContext';

const calculateCommentPage = (commentIds, toComment, currentPage, pageSize) => {
  if (!toComment) {
    return currentPage;
  }

  const index = commentIds
    .slice()
    // todo maybe this sorting is redundant. we already sort it in selectors
    .sort((a, b) => (parseInt(a) > parseInt(b) ? 1 : -1))
    .indexOf(parseInt(toComment));

  // not found, use the page param
  if (index === -1) {
    console.warn('cannot find commentId', toComment);
    return currentPage;
  }

  // calculate based on the position and user pagination settings
  return Math.ceil((index + 1) / pageSize);
};

function BlogEntryPageComments({
  eid,
  blog,
  page,
  toComment,
  scrollToComments,
  commentIds,
  isCommentingAllowed,
  reloadEntry,
  settings,
  ownProfile,
  readComments
}) {
  const navigate = useNavigate();
  const pageSize = settings.pagination.comments;
  const ref = useRef();
  const [loadingComments, setLoadingComments] = useState(true);
  const [comments, setComments] = useState([]);
  const [scroll, setScroll] = useState(true);
  const [editingComment, setEditingComment] = useState(null);
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();
  const loggedIn = Boolean(token);
  const isOwnBlog = blog.id === activeProfileId;
  const saveCommentDraft = useSetCommentDraft();
  const commentDraft = useCommentDraftById({ activeProfileId, commentId: eid });

  const { insertReply, handleCommentEdit } = useEntryPageContext();

  useEffect(() => {
    setScroll(true);
    async function loadContent() {
      const commentPage = calculateCommentPage(
        commentIds,
        toComment,
        page,
        pageSize
      );

      if (page !== commentPage) {
        const maxPage = Math.max(Math.ceil(commentIds.length / pageSize), 1);
        const pageCommentPath =
          maxPage < commentPage ? `${maxPage}` : `${commentPage}#${toComment}`;
        navigate(`/blog/${blog.blogSlug}/${eid}?page=${pageCommentPath}`, {
          replace: true
        });
      }

      setLoadingComments(true);
      const res = await getEntryCommentsApi({
        entryId: eid,
        pageSize,
        pageNumber: commentPage,
        token
      });
      if (res.error) {
        setLoadingComments(false);
        return;
      }

      const newComments = res.data.map(comment => ({
        ...comment,
        profile: {
          ...comment.profile,
          settings: merge(
            {},
            defaultProfileSettings,
            comment.profile.settings || {}
          )
        }
      }));
      setComments(newComments);
      setLoadingComments(false);

      readComments({
        commentIds: newComments.map(c => c.id),
        token,
        activeProfileId
      });
    }
    loadContent();
  }, [
    toComment,
    page,
    blog.blogSlug,
    commentIds,
    eid,
    pageSize,
    token,
    readComments
  ]);
  // there was an intention not to update the component if no new notification

  useEffect(() => {
    if (scrollToComments) {
      ref.current?.scrollIntoView();
    }
  }, [loadingComments, scrollToComments]);

  const deleteComment = async id => {
    let res = await removeCommentApi({ id, token });
    if (res.error) {
      window.alert(res.error);
      return;
    }
    setComments(currentComments => currentComments.filter(c => c.id !== id));
    await reloadEntry();

    // if no entries left, go to the previous page
    let newPage = page;
    if (!comments.length) {
      newPage = Math.max(page - 1, 1);
    }

    if (newPage !== page) {
      await navigate(`/blog/${blog.blogSlug}/${eid}?page=${newPage}`, {
        replace: true
      });
    }
  };

  const renderPagination = type => {
    return (
      <Pagination
        classModifier={type}
        totalRecords={commentIds?.length ?? 0}
        currentPage={page}
        onChange={() => ref.current?.scrollIntoView()}
        pageSize={pageSize}
        url={`/blog/${blog.blogSlug}/${eid}?scroll=true&page=`}
        data-testid={`blog-entry-pagination-${type}`}
      />
    );
  };

  // NOTE: meta.canComment is wrong in some cases, do not use it for now
  const canComment = loggedIn && (isCommentingAllowed || isOwnBlog);

  return (
    <div ref={ref}>
      {renderPagination('top')}

      {!loadingComments &&
        comments.map(comment => {
          const ownComment = ownProfile?.id === comment.profile.id;
          return (
            <BlogComment
              scrollIntoView={toComment === comment.id && scroll}
              onScrollIntoView={() => setScroll(false)}
              key={comment.id}
              comment={comment}
              url={`/blog/${blog.blogSlug}/${eid}#${comment.id}`}
              canDelete={isOwnBlog || ownComment}
              onDelete={deleteComment}
              canEdit={loggedIn && ownComment}
              hasUserFunctions={loggedIn}
              handleReply={() =>
                insertReply(
                  comment.id,
                  comment.profile.id,
                  comment.profile.nickname
                )
              }
              onEdit={commentId => {
                const commentToEdit = comments.find(c => c.id === commentId);
                setEditingComment(commentToEdit);
                handleCommentEdit(commentToEdit.content);
              }}
            />
          );
        })}
      {loadingComments && <Loading />}
      {!loadingComments && renderPagination('bottom')}
      {!isCommentingAllowed && (
        <div className="blog-info-block">Комментарии отключены.</div>
      )}
      {!loadingComments && canComment && (
        <CommentEditFormContainer
          commentDraft={commentDraft}
          editingComment={editingComment}
          onPublishFinished={async () => {
            setEditingComment(null);
            await reloadEntry();
          }}
          onUpdateComment={content =>
            updateCommentApi({
              commentId: editingComment.id,
              content,
              token
            })
          }
          onCreateComment={content =>
            createCommentApi({
              content,
              entryId: eid,
              profileId: ownProfile.id,
              token
            })
          }
          onSaveDraftComment={content => saveCommentDraft({ eid, content })}
          navigateTo={commentId => `/blog/${blog.blogSlug}/${eid}#${commentId}`}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectUserSettingsAugmented(state),
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  readComments
};

export const BlogEntryPageCommentsContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(BlogEntryPageComments)
);
