import ProfileLink from '@/scenes/Management/Community/ProfileLink';
import React from 'react';
import css from '@/scenes/User/Settings/Privacy/PrivacyLists/List/ProfileEntry.module.css';
import { CloseDeleteIcon } from '@/styles/Icons/CloseDelete';
import { usePrivacyListContext } from '@/scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';

function ProfileEntry({ list, profile }) {
  const { handleRemoveUser } = usePrivacyListContext();

  return (
    <div className={css.container}>
      <div
        className={css.removeButton}
        onClick={() => handleRemoveUser(list, profile.id)}
      >
        <CloseDeleteIcon className={css.removeIcon} />
      </div>
      <ProfileLink profile={profile} />
    </div>
  );
}

export default ProfileEntry;
