import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { ActionButton, SecondaryButton } from '@/components/Shared/Buttons';
// import 'simplebar/dist/simplebar.min.css';

import { upload } from '@/utils/helpers/fileUploads';

import ArrowUp from '@/styles/Icons/SimpleArrowUp';
import ArrowDown from '@/styles/Icons/SimpleArrowDown';

import { Designer, Menu, StyledIconButton } from './components/styled';

import {
  cancelDesign,
  collapseDesigner,
  expandDesigner,
  saveDesign,
  updateDesign
} from '@/store/designer/operations';
import { selectDraftDesign } from '@/store/designer/selectors';
import setFieldUpdeep from '@/utils/helpers/setFieldUpdeep';
import { updateProfileSetting } from '@/store/user/thunks/updateProfileSetting';
import { ColorSettingsTab } from '@/scenes/Blog/designer/DesignerScene/ColorSettingsTab';
import { FontSettingsTab } from '@/scenes/Blog/designer/DesignerScene/FontSettingsTab';
import { HeaderSettingsTab } from '@/scenes/Blog/designer/DesignerScene/HeaderSettingsTab';
import { LayoutSettingsTab } from '@/scenes/Blog/designer/DesignerScene/LayoutSettingsTab';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';

const designSections = [
  { id: 1, name: 'макет' },
  { id: 2, name: 'шапка' },
  { id: 3, name: 'цвета' },
  { id: 4, name: 'шрифты' }
];

function DesignerPanel({
  design,
  designerCollapsed,
  collapseDesigner,
  expandDesigner,
  updateDesign,
  saveDesign,
  updateProfileSetting,
  applyDesign,
  cancelDesign
}) {
  const navigate = useNavigate();
  const [section, setSection] = useState(1);
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  const toggleSetting = keys => e => {
    const updated = setFieldUpdeep(design, keys, e.target.checked);
    updateDesign(updated);
  };

  const changeSetting = keys => e => {
    const value = (e.target && e.target.value) || e.value || e;
    const updated = setFieldUpdeep(design, keys, value);
    updateDesign(updated);
  };

  const changeName = e => {
    const name = e.target.value;
    if (name.length <= 30) {
      changeSetting('name')(name);
    }
  };

  const saveDesignAndApply = async () => {
    const id = await saveDesign({ token, activeProfileId });

    if (id.error) {
      window.alert('Не получилось сохранить дизайн :(');
      return;
    }
    if (id) {
      updateProfileSetting('currentDesign', id, token);
      applyDesign();
      cancelDesign();
    }
  };

  const handleSaveDesign = async () => {
    const id = await saveDesign({ token, activeProfileId });

    if (!id || id.error) {
      window.alert('Не получилось сохранить дизайн :(');
      return;
    }
    await navigate(`/settings/blog#design`);
  };

  const handleCancelDesign = async () => {
    cancelDesign();
    await navigate(`/settings/blog#design`);
  };

  const handleDrop = key => async accepted => {
    upload(accepted, token, changeSetting(key));
  };

  if (!design) {
    return null;
  }

  return (
    <Designer className={designerCollapsed ? 'preview-mode' : ''}>
      <div className="action-section">
        <div className="design-name">
          <StyledIconButton
            onClick={() => {
              designerCollapsed ? expandDesigner() : collapseDesigner();
            }}
          >
            {designerCollapsed ? <ArrowUp /> : <ArrowDown />}
          </StyledIconButton>
          <input
            type="text"
            name="design-name"
            value={design.name}
            onChange={changeName}
          />
        </div>
        <div className="action-buttons">
          <ActionButton onClick={handleSaveDesign}>Сохранить</ActionButton>
          <ActionButton onClick={saveDesignAndApply}>
            Сохранить и применить
          </ActionButton>
          <SecondaryButton onClick={handleCancelDesign}>Отмена</SecondaryButton>
        </div>
      </div>
      <div className="design-section">
        <div className="menu">
          <Menu>
            {designSections.map(s => (
              <div
                key={s.id}
                onClick={() => setSection(s.id)}
                className={section === s.id ? 'active' : ''}
              >
                {s.name}
              </div>
            ))}
          </Menu>
        </div>
        <div
          className="scroll-wrapper"
          data-simplebar
          data-simplebar-auto-hide="true"
        >
          <div className="options">
            {section === 1 && (
              <LayoutSettingsTab
                design={design}
                changeSetting={changeSetting}
              />
            )}
            {section === 2 && (
              <HeaderSettingsTab
                design={design}
                changeSetting={changeSetting}
                toggleSetting={toggleSetting}
                handleDrop={handleDrop}
              />
            )}
            {section === 3 && (
              <ColorSettingsTab
                design={design}
                changeSetting={changeSetting}
                toggleSetting={toggleSetting}
                handleDrop={handleDrop}
              />
            )}
            {section === 4 && (
              <FontSettingsTab design={design} changeSetting={changeSetting} />
            )}
          </div>
        </div>
      </div>
    </Designer>
  );
}

const mapStateToProps = state => {
  return {
    designerOn: state.designer.designerOn,
    designerCollapsed: state.designer.designerCollapsed,
    design: selectDraftDesign(state)
  };
};

const mapDispatchToProps = {
  collapseDesigner,
  expandDesigner,
  saveDesign,
  updateDesign,
  cancelDesign,
  updateProfileSetting
};

export const DesignerPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignerPanel);
