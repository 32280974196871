/**
 * get page number, size, total entries, url base
 * render pagination accordingly
 */

import { useSearchParams } from 'react-router-dom';
import ArrowLeftIcon from '@/styles/Icons/SimpleArrowLeft';
import ArrowRightIcon from '@/styles/Icons/SimpleArrowRight';

const Pagination = ({
  totalRecords,
  currentPage,
  lastPage = false,
  pageSize,
  maxFirst = 5,
  unknownSize = false,
  wrap = a => a
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const totalPages = Math.ceil(totalRecords / pageSize);

  if ((!totalPages && !unknownSize) || (totalPages === 1 && !unknownSize))
    return null;

  const paginationElements = [];
  const pageLinks = [];

  function navigateToPage(page) {
    setSearchParams(params => {
      params.set('page', page);
      return params;
    });
  }

  const addLink = i => {
    const link = (
      <a key={i} onClick={() => navigateToPage(i)} className={`page page-${i}`}>
        {i}
      </a>
    );
    pageLinks.push(link);
  };

  if (currentPage !== 1) {
    const link = (
      <div key={'prev'} className="previous">
        <a onClick={() => navigateToPage(currentPage - 1)}>
          <ArrowLeftIcon />
          <span className="previous-word">предыдущая</span>
        </a>
      </div>
    );
    paginationElements.push(link);
  } else {
    const link = <div key={'prev'} className="previous" />;
    paginationElements.push(link);
  }

  for (let i = 1, dots = true; i <= (unknownSize ? 100 : totalPages); i += 1) {
    // how many page numbers to show before and after current
    const pagesBefore = 1;
    const pagesAfter = unknownSize ? 1 : Math.min(1, totalPages - currentPage);
    let showLink =
      i === 1 ||
      (i <= maxFirst && currentPage <= maxFirst - 2) ||
      (i >= currentPage - pagesBefore && i <= currentPage + pagesAfter) ||
      i === totalPages;
    if (lastPage && i >= currentPage + 1) showLink = false;
    if (lastPage) dots = false;

    if (showLink) {
      if (currentPage === i) {
        const link = (
          <span key={i} className={`current page-${i}`}>
            {i}
          </span>
        );
        pageLinks.push(link);
      } else {
        addLink(i);
        dots = true;
      }
    } else if (dots) {
      dots = false;
      const link = (
        <span key={i} className={`dots dots-${i}`}>
          ...
        </span>
      );
      pageLinks.push(link);
    }
  }

  paginationElements.push(
    <div key={'numbers'} className="page-numbers">
      {pageLinks}
    </div>
  );
  if (
    (currentPage !== totalPages || unknownSize) &&
    (totalPages || unknownSize) &&
    !lastPage
  ) {
    const link = (
      <div key="next" className="next">
        <a onClick={() => navigateToPage(currentPage + 1)}>
          <span className="next-word">следующая</span>
          <ArrowRightIcon />
        </a>
      </div>
    );
    paginationElements.push(link);
  } else {
    const link = <div key="next" className="next" />;
    paginationElements.push(link);
  }
  return wrap(paginationElements);
};

export default Pagination;
