import { switchProfileApi } from '@/api/auth';
import { ClearListsAction } from '@/store/lists/actions';
import { ClearDesignsAction } from '@/store/designer/actions/ClearDesignsAction';
import { setCommunitiesThunk } from '@/store/user/thunks/setCommunitiesThunk';

export const setActiveProfileThunk = ({ profileId, setToken, token }) => async (
  dispatch,
  getState
) => {
  try {
    const res = await switchProfileApi({ profileId, token });

    if (!res.error) {
      // const { userId, activeProfileId } = parseToken(res.accessToken);
      dispatch(new ClearListsAction());
      dispatch(new ClearDesignsAction());
      setToken?.(res.accessToken);
      // dispatch(new MigrateFormsAction(userId, activeProfileId));

      return await setCommunitiesThunk({ profileId, token })(
        dispatch,
        getState
      );
    } else {
      alert(`Что-то пошло не так, попробуйте позднее.`);
      return { error: res.error };
    }
  } catch (e) {
    const message = `Не получается переключить профили, попробуйте позднее.`;
    alert(message);
    return { error: message };
  }
};
