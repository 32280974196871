import React, { useEffect, useState } from 'react';
import defaultBlogDesign from '@/configs/defaultBlogDesign';

import { getDesignAPI } from '@/api/designs';
import BlogDisplay from '@/components/Shared/BlogDisplay';
import { useTokenState } from '@/store/localStorage/useTokenState';

function BlogDisplayContainer({ profile }) {
  const [design, setDesign] = useState(defaultBlogDesign);
  const designId = (profile.settings || {}).currentDesign;
  const [token] = useTokenState();

  useEffect(() => {
    const loadDesign = async () => {
      if (designId && designId !== '0') {
        const res = await getDesignAPI({
          designId,
          profileId: profile.id,
          token
        });
        if (!res.error) {
          setDesign({ ...res.data, id: designId });
          return;
        }
      }
      setDesign(defaultBlogDesign);
    };
    loadDesign();
  }, [designId, profile.id, token]);

  return <BlogDisplay profile={profile} design={design} />;
}

export default BlogDisplayContainer;
