import configs from '@/configs/dybr';

export function validateBlogSetting(value, target) {
  let err;
  if (!value.length) {
    err = `Придумайте название ${target}`;
  } else if (value.length > configs.MAX_LENGTH.blogTitle) {
    err = 'Слишком длинно';
  } else if (value[0] === ' ' || value[value.length - 1] === ' ') {
    err = 'Название не может начинаться или заканчиваться на пробел';
  }

  return err;
}
