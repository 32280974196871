import { removeEntryApi } from '@/api/entries';

// todo can be removed from thunks
export const removeEditedEntry =
  ({ entryId, blog, token, saveEntryDraft, navigate }) =>
  async dispatch => {
    if (window.confirm('Вы точно хотите удалить запись?')) {
      if (entryId !== '0') {
        const res = await removeEntryApi({ entryId, token });
        if (res.error) {
          window.alert(res.error);
          return;
        }
      }

      setTimeout(() => saveEntryDraft({ id: entryId, content: '' }, blog), 0);

      navigate(`/blog/${blog.blogSlug}/`);
    }
  };
