import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from './Tooltip';
import { getAvatar } from '@/utils/helpers/profiles';

import {
  ChosenProfile,
  ChosenProfileAvatar,
  ChosenProfileAvatarWrapper,
  StyledMenu,
  UserLinks
} from './styled';
import { connect } from 'react-redux';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { doLogoutThunk } from '@/store/user/thunks/doLogoutThunk';
import {
  InactiveProfileAvatar,
  InactiveProfiles
} from '@/scenes/Header/components/Sidebar/MenuSidebar.style';
import { selectInactiveProfilesAugmented } from '@/store/user/selectors/selectInactiveProfilesAugmented';
import { setActiveProfileThunk } from '@/store/user/thunks/setActiveProfileThunk';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

function MenuSidebar({
  profile,
  inactiveProfiles,
  setActiveProfileThunk,
  doLogout
}) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [profileInTooltip, setProfileInTooltip] = useState(null);
  const [token, setToken] = useTokenState();

  const avatar = getAvatar(profile);

  return (
    <>
      <ChosenProfile>
        <ChosenProfileAvatarWrapper>
          <ChosenProfileAvatar>
            <img src={avatar} alt="" />
          </ChosenProfileAvatar>
          {/* <Tick size={25} /> */}
        </ChosenProfileAvatarWrapper>
        <UserLinks>
          <h4>
            <Link
              to={`/profile/${profile.id}`}
              data-testid="sidebar-my-active-profile-link"
            >
              {profile.nickname}
            </Link>
          </h4>

          <h5>
            <Link to={`/blog/${profile.blogSlug}`}>{profile.blogTitle}</Link>
          </h5>
        </UserLinks>
      </ChosenProfile>

      <InactiveProfiles>
        {inactiveProfiles.map(inactiveProfile => (
          <InactiveProfileAvatar
            key={inactiveProfile.nickname}
            onMouseOver={() => {
              setTooltipVisible(true);
              setProfileInTooltip(inactiveProfile);
            }}
            onMouseLeave={() => {
              setTooltipVisible(false);
              setProfileInTooltip(null);
            }}
            onClick={() => {
              setActiveProfileThunk({
                profileId: inactiveProfile.id,
                setToken,
                token
              });
            }}
          >
            <img src={getAvatar(inactiveProfile)} alt="" />
          </InactiveProfileAvatar>
        ))}
      </InactiveProfiles>

      <Tooltip isVisible={isTooltipVisible} {...profileInTooltip} />

      <StyledMenu>
        <li key="settings">
          <Link to="/settings" data-testid="sidebar-settings-link">
            настройки
          </Link>
        </li>
        {profile.isCommunity && (
          <li key="manageCommunity">
            <Link
              to="/manage/community"
              data-testid="sidebar-create-new-profile-link"
            >
              управление сообществом
            </Link>
          </li>
        )}
        <li key="conversations">
          <Link to="/direct" data-testid="sidebar-conversations-link">
            разговоры
          </Link>
        </li>
        <li key="createProfile">
          <Link
            to="/account/create"
            data-testid="sidebar-create-new-profile-link"
          >
            создать новый профиль
          </Link>
        </li>
        <br />
        <li key="help">
          <Link to="/help" data-testid="sidebar-help-link">
            ответы на вопросы
          </Link>
        </li>
        <li key="errorlog">
          <Link to="/errorlog" data-testid="sidebar-errorlog-link">
            помощь с багами
          </Link>
        </li>
        <li key="terms">
          <Link to="/terms" data-testid="sidebar-terms-link">
            правила
          </Link>
        </li>
        <br />
        <li key="logout">
          <button
            onClick={() => doLogout(setToken)}
            data-testid="sidebar-logout-link"
          >
            Выход
          </button>
        </li>
      </StyledMenu>
    </>
  );
}

const mapDispatchToProps = {
  doLogout: doLogoutThunk,
  setActiveProfileThunk
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId }),
    inactiveProfiles: selectInactiveProfilesAugmented(state, {
      activeProfileId
    })
  };
};

export const MenuSidebarContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(MenuSidebar)
);
