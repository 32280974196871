import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox,
  TextFieldWithButton
} from '@/components/DybrComponents/StyledServicePagesElements';
import TextField from '@/components/FormElements/TextField';
import configs from '@/configs/dybr';
import { ActionButton } from '@/components/Shared/Buttons';
import { DesignList } from '@/scenes/User/Settings/Styled';
import { DesignItemContainer } from '@/scenes/User/Settings/Blog/DesignItem';
import React, { useEffect, useState } from 'react';
import { validateBlogSetting } from '@/scenes/User/Settings/Blog/validateBlogSetting';
import { useNavigate } from 'react-router-dom';

import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import { loadDesigns, startNewDesign } from '@/store/designer/operations';
import { connect } from 'react-redux';
import {
  useActiveProfile,
  useTokenState
} from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';

function DesignsBlock({ loadDesigns, startNewDesign, profile, designs }) {
  const navigate = useNavigate();
  const [designDraftName, setDesignDraftName] = useState('');
  const [error, setError] = useState('');
  const [token] = useTokenState();
  const { activeProfileId } = useActiveProfile();

  useEffect(() => {
    async function fetchDesigns() {
      await loadDesigns({ token, activeProfileId });
    }
    fetchDesigns();
  }, [loadDesigns]);

  /* Designs */
  function changeDesignDraftName(e) {
    setDesignDraftName(e.target.value);
    setError(validateBlogSetting(e.target.value, 'дизайну'));
  }

  async function startDesigning() {
    startNewDesign(designDraftName);
    await navigate(`/blog/${profile.blogSlug}`);
  }

  const { currentDesign: current } = profile.settings;
  const hasDesigns = Object.values(designs).length > 0;

  const currentDesign = designs?.[current] ?? null;
  const designsToRender = [];

  if (currentDesign) {
    designsToRender.push(currentDesign);
  }

  if (hasDesigns) {
    const availableDesigns = Object.values(designs).filter(
      d => d.id.toString() !== current
    );
    designsToRender.push(...availableDesigns);
  }

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Дизайн</h4>
          <p>Вы можете создать для своего блога дизайн, который вам по душе</p>
        </SettingSectionTitle>
        <Settings>
          <div style={{ marginBottom: '5px' }}>
            <span>добавить новый дизайн</span>
          </div>
          <TextFieldWithButton>
            <TextField
              label="название дизайна"
              isRequired
              value={designDraftName}
              onChange={changeDesignDraftName}
              maxLen={configs.MAX_LENGTH.blogTitle}
              error={error}
              touched={false}
            />
            <ActionButton
              onClick={startDesigning}
              disabled={error || !designDraftName.trim()}
            >
              создать
            </ActionButton>
          </TextFieldWithButton>
          {designsToRender.length > 0 && (
            <div style={{ marginTop: '25px', marginBottom: '20px' }}>
              <span>ваши дизайны</span>
            </div>
          )}
          {designsToRender.length > 0 && (
            <DesignList>
              {/*<div data-simplebar data-simplebar-auto-hide="true">*/}
              {designsToRender.map(d => (
                <DesignItemContainer key={d.id} design={d} />
              ))}
              {/*</div>*/}
            </DesignList>
          )}
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    designs: state.designer.designs,
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  loadDesigns,
  startNewDesign
};

export const DesignsBlockContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(DesignsBlock)
);
