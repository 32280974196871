import { merge } from 'lodash-es';

import defaultProfileSettings from '@/configs/defaultProfileSettings';

import type { components } from './schema.d.ts';

import { query } from './Query';

type Profile = components['schemas']['Profile'];
type ProfileList = components['schemas']['ProfileList'];

interface GetAllProfilesApiParams {
  token: string;
  searchTerm?: string;
  searchAttribute?: 'nickname' | 'blog-slug';
  pageSize: number;
  pageNumber: number;
  sort?: string;
  isCommunity?: boolean;
  searchAllBlogs?: boolean;
}

interface ApiResponse<T> {
  status: string;
  data?: T;
  error?: string;
}

export const getAllProfilesApi = async ({
  token,
  searchTerm,
  //Specify searching attribute. Can have two values for now: `nickname` or `blog-slug`
  //If not specified, it uses filter `filter[profile-match-any|contains]` by default
  searchAttribute,
  pageSize,
  pageNumber,
  sort,
  isCommunity = false,
  searchAllBlogs = false
}: GetAllProfilesApiParams): Promise<ApiResponse<ProfileList>> => {
  try {
    let queryParams: Record<string, any> = {
      sort,
      ...(!searchAllBlogs
        ? { 'filters[is-community]': isCommunity ? 1 : 0 }
        : {}),
      'page[size]': pageSize,
      'page[number]': pageNumber
    };
    if (searchTerm) {
      if (searchAttribute === 'nickname') {
        queryParams['filters[nickname|contains]'] = searchTerm;
      }
      if (searchAttribute === 'blog-slug') {
        queryParams['filters[blog-slug|contains]'] = searchTerm;
      }
      if (!searchAttribute) {
        queryParams['filters[profile-match-any|contains]'] = searchTerm;
      }
    }
    let res = await query({
      method: 'GET',
      resource: `profiles`,
      queryParams: queryParams,
      token
    });

    return { status: 'ok', ...res };
  } catch (err) {
    console.error('getAllProfilesApi', err);

    return { error: 'что-то пошло не так', data: [], ...err.response };
  }
};

interface CreateProfileApiParams {
  blogSlug: string;
  blogTitle: string;
  nickname: string;
  isCommunity: boolean;
  settings?: Record<string, any>;
  token: string;
}

export const createProfileApi = async ({
  blogSlug,
  blogTitle,
  nickname,
  isCommunity,
  settings = {},
  token
}: CreateProfileApiParams): Promise<ApiResponse<Profile>> => {
  try {
    const queryParams = {
      nickname,
      settings,
      blogSlug,
      blogTitle,
      isCommunity
    };
    const response = await query({
      method: 'POST',
      resource: 'profiles',
      queryParams,
      token
    });
    return { status: 'ok', data: response.data };
  } catch (err) {
    console.log('profileCreate', err);
    //return { status: 'ok', data: {} };
    let error = 'не получилось создать профиль';
    if (err.response) {
      switch (err.response.status) {
        case 403:
          error = 'потеряна сессия, зайдите заново';
          break;
        case 500:
          error = 'временные проблемы на сервере :(';
          break;
        default:
          error = 'что-то пошло не так';
          break;
      }
    }

    return { error, status: 'error', ...err.response };
  }
};
//
// export const remove = async id => {
//   try {
//     await query('DELETE', 'profiles', {}, id);
//     return { status: 'ok' };
//   } catch (err) {
//     return { error: 'не получилось удалить профиль' };
//   }
// };

interface UpdateProfileApiParams {
  data: Record<string, any>;
  activeProfileId: string;
  token: string;
}

export const updateProfileApi = async ({
  data,
  activeProfileId,
  token
}: UpdateProfileApiParams): Promise<ApiResponse<Profile>> => {
  try {
    let res = await query({
      method: 'PATCH',
      resource: 'profiles',
      queryParams: data,
      id: activeProfileId,
      token
    });
    if (res.data) {
      return { status: 'ok', data: res.data };
    }
    return { error: 'не удалось загрузить профиль', status: 'error' };
  } catch (err) {
    console.log('profileUpdate', err);

    if (err.response && err.response.status === 403) {
      return {
        error: 'никнейм занят',
        response: err.response,
        status: 'error'
      };
    }
    return {
      error: 'не получилось обновить профиль',
      status: 'error',
      ...err.response
    };
  }
};

interface GetProfileApiParams {
  profileId: string;
  token: string;
  include?: string;
}

export const getProfileApi = async ({
  profileId,
  token,
  include = 'my-communities'
}: GetProfileApiParams): Promise<ApiResponse<Profile>> => {
  try {
    let res = await query({
      method: 'GET',
      resource: `profiles/${profileId}`,
      queryParams: { include },
      token
    });

    if (res.data) {
      res.data.settings = merge(
        {},
        defaultProfileSettings,
        res.data.settings || {}
      );
      return { status: 'ok', data: res.data };
    }
    return { error: 'не удалось загрузить профиль', status: 'error' };
  } catch (err) {
    console.log('profile get', err);
    return { error: 'что-то случилось', status: 'error' };
  }
};

interface GetProfileByBlogSlugParams {
  slug: string;
  token: string;
  withTags?: boolean;
}

export const getProfileByBlogSlug = async ({
  slug,
  token,
  withTags = false
}: GetProfileByBlogSlugParams): Promise<ApiResponse<Profile>> => {
  try {
    const queryParams = {
      'filters[slug]': slug.toLowerCase(),
      ...(withTags ? { include: 'tags' } : {})
    };

    const res = await query({
      method: 'GET',
      resource: 'profiles',
      queryParams,
      token
    });

    if (res.data && res.data.length > 0) {
      res.data[0].settings = merge(
        {},
        defaultProfileSettings,
        res.data[0].settings
      );

      return { status: 'ok', data: res.data[0] };
    }
    return { error: 'блог не найден', status: 'error' };
  } catch (e) {
    return { error: 'что-то случилось', status: 'error' };
  }
};

interface GetArchiveStatusParams {
  profileId: string;
  token: string;
}

// not connected to profile, but whatever, let it be here for now
export const getArchiveStatus = async ({
  profileId,
  token
}: GetArchiveStatusParams): Promise<ApiResponse<any>> => {
  try {
    let res = await query({
      method: 'GET',
      resource: `users/archive-status`,
      token
    });
    //let res = await query('GET', `profiles/${profileID}/archive-status`, {});

    if (res.data) {
      return { status: 'ok', data: res.data };
    }
    return { error: 'не удалось получить статус архива', status: 'error' };
  } catch (err) {
    console.log('profile list', err);
    return { error: 'что-то случилось', status: 'error' };
  }
};

interface GetDybrArchiveParams {
  profileId: string;
  token: string;
}

export const getDybrArchive = async ({
  profileId,
  token
}: GetDybrArchiveParams): Promise<ApiResponse<any>> => {
  try {
    let res = await query({
      method: 'CUSTOM_POST',
      resource: `profiles/${profileId}/export`,
      token
    });
    if (res.dataOriginal && res.dataOriginal.link) {
      let file = await query({
        method: 'GET_FILE',
        resource: res.dataOriginal.link,
        token
      });
      if (file) {
        return file;
      } else {
        return { error: 'no file returned', status: 'error' };
      }
    } else {
      return { error: 'no link in the server response', status: 'error' };
    }
  } catch (err) {
    console.log('profile export', err);
    return { error: 'что-то случилось', status: 'error' };
  }
};

interface DeleteDybrBlogParams {
  profileId: string;
  token: string;
  comments: boolean;
}

export const deleteDybrBlog = async ({
  profileId,
  token,
  comments
}: DeleteDybrBlogParams): Promise<ApiResponse<any>> => {
  try {
    let res = await query({
      method: 'DELETE',
      resource: `profiles/${profileId}/?comments=${comments}`,
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('blog deletion', err);
    return { error: 'что-то случилось', status: 'error' };
  }
};

interface GetRandomProfileParams {
  token: string;
}

export const getRandomProfile = async ({
  token
}: GetRandomProfileParams): Promise<ApiResponse<any>> => {
  try {
    let res = await query({
      method: 'GET',
      resource: `random-profile`,
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('get random profile', err);
    return { error: 'что-то случилось', status: 'error' };
  }
};
