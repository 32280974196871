import produce from 'immer';
import cloneDeep from 'lodash-es/cloneDeep';
import keyBy from 'lodash-es/keyBy';

import { defaultNotificationState } from '@/store/_setup/initialState';
import { ClearNotificationsAction } from '@/store/notifications/actions/ClearNotificationsAction';
import { MarkAllNotificationsReadAction } from '@/store/notifications/actions/MarkAllNotificationsReadAction';
import { MarkNotificationReadAction } from '@/store/notifications/actions/MarkNotificationReadAction';
import { StoreEntriesAction } from '@/store/notifications/actions/StoreEntriesAction';
import { StoreNotificationsAction } from '@/store/notifications/actions/StoreNotificationsAction';
import { LogoutAction } from '@/store/user/actions/LogoutAction';

/**
  structure:

  list of notifications comes like this:
  items: {
    "457416": {
      type: "notifications"
      id: "457416"
      blog: "1",
      comment: "1482630",
      entry: "384832",
      state: "new"
    }
  }

  then we load entries by these ids

  entries: {
    '1': { id: '1', content, title, etc.}
  }

  then, when the sidebar is opened and comments expanded, they are loaded, with profiles

  comments: {
      '1482630': {
        "type": "comments",
        "id": "1482630",
        "content": "my content",
        "createdAt": "2020-05-30T21:59:28.272793Z",
        "updatedAt": "2020-05-30T21:59:28.272793Z"
        profile: { nickname, id, ...}
      }
      '1482631': { ... }
  }

 */

export const notificationsReducer = (state = defaultNotificationState, action) =>
  produce(state, (draft) => {
  switch (action.type) {
    case StoreNotificationsAction.type:
      draft.items = keyBy(action.items, 'id');
      break;
    case StoreEntriesAction.type:
      draft.entries = keyBy(action.entries, 'id');
      break;
    // case StoreCommentsAction.type:
    //   draft.comments = keyBy(action.comments, 'id');
    //   break;
    case MarkNotificationReadAction.type:
      delete draft.items[action.notificationId];
      break;
    case MarkAllNotificationsReadAction.type:
      draft.items = {};
      break;
    case ClearNotificationsAction.type:
      draft = cloneDeep(defaultNotificationState);
      break;
    case LogoutAction.type:
      draft = cloneDeep(defaultNotificationState);
      break;
    default:
      break;
  }
});
