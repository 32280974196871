import defaultAva from '@/components/img/default_ava.jpg';

const defaultProfileSettings = {
  avatar: defaultAva,
  notifications: {
    comments: {
      enable: true,
      regularity: 'timely'
    },
    entries: {
      enable: true,
      regularity: 'timely'
    },
    messages: {
      enable: true,
      regularity: 'timely'
    },
    mentions: {
      enable: true,
      regularity: 'timely'
    }
  },
  currentDesign: '0',
  quickAccessLink: { enable: true, name: 'общая лента', url: 'feed' },
  privacy: {
    dybrfeed: true
  },
  feed: { hide: { profiles: [], tags: [] } },
  pinnedEntries: []
};

export default defaultProfileSettings;
