import { configureStore } from '@reduxjs/toolkit';
import { classActionMiddleware } from '@/store/_setup/classActionMiddleware';

import { designerReducer } from '@/store/designer/designerReducer';
import { listReducer } from '@/store/lists/listReducer';
import { notificationsReducer } from '@/store/notifications/reducer/notificationsReducer';
import { userReducer } from '@/store/user/reducer/userReducer';

export const store = configureStore({
  reducer: {
    designer: designerReducer,
    user: userReducer,
    notifications: notificationsReducer,
    lists: listReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(classActionMiddleware),
  devTools: process.env.NODE_ENV !== 'production'
});

console.log("Store instance created:", store);

export type AppStore = typeof store
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
