import { getEntryApi } from '@/api/entries';

export const loadEntryToRedirect =
  ({ eid, location, token, navigate }) =>
  async dispatch => {
    const res = await getEntryApi({
      entryId: eid,
      include: 'profile,community',
      token
    });

    if (res.error) {
      // todo if 403, we should tell user about that
      navigate(`/not-found`);
    } else {
      const entry = res.data;

      const slug = entry.relationshipNames.includes('community')
        ? entry.community.blogSlug
        : entry.profile.blogSlug;

      const url = `/blog/${slug}/${eid}${location.search || ''}${
        location.hash || ''
      }`;
      navigate(url, {
        replace: true
      });
    }
  };
