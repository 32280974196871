import { updateNotificationAPI } from '@/api/notifications';
import { MarkNotificationReadAction } from '@/store/notifications/actions/MarkNotificationReadAction';
import { selectNotificationIdsByEntryId } from '../selectors/selectNotificationIds';

export const readEntryNotifications =
  ({ entryId, token, activeProfileId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const notificationIds = selectNotificationIdsByEntryId(state, {
      entryId
    });

    for (const notificationId of notificationIds) {
      const res = await updateNotificationAPI({
        profileId: activeProfileId,
        data: {
          id: notificationId,
          state: 'read'
        },
        token
      });
      if (!res.error) {
        dispatch(new MarkNotificationReadAction(notificationId));
      } else {
        // dispatch error
      }
    }
  };
