import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { getProfileApi } from '@/api/profiles';
import { HeaderGeneral } from '@/components/DybrComponents/HeaderGeneral';
import { ServiceBackground } from '@/components/DybrComponents/ServiceBackground';
import AvatarWithMenu from '@/components/Shared/AvatarWithMenu';
import { ArrowIconWithBackground } from '@/components/Shared/IconButton';
import defaultBlogDesign from '@/configs/defaultBlogDesign';
import { EntryPageProvider } from '@/scenes/Blog/BlogEntryPage/EntryPageContext';
import { BirthdayIcon } from '@/scenes/Dybr/Users/BirthdayIcon';
import { DirectMessageHeaderProfile } from '@/scenes/Dybr/Users/styled/StyledProfile';
import { useTokenState } from '@/store/localStorage/useTokenState';
import WithActiveProfileId from '@/store/localStorage/WithActiveProfileId';
import { selectActiveProfileAugmented } from '@/store/user/selectors/selectActiveProfileAugmented';
import style from '@/styles/blog/BlogCssConstructor';
import SimpleArrowLeft from '@/styles/Icons/SimpleArrowLeftWithBackground';

import css from './Conversation.module.css';
import { ConversationMessagesContainer } from './ConversationMessages';

function ConversationScene({ ownProfile }) {
  const { targetProfileId } = useParams();
  const [targetProfile, setTargetProfile] = useState();
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [error, setError] = useState('');
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  useEffect(() => {
    loadTargetProfile();
  }, [targetProfileId]);

  function loadTargetProfile() {
    getProfileApi({
      profileId: targetProfileId,
      token
    })
      .then(res => {
        setTargetProfile(res.data);
      })
      .catch(() =>
        setError(
          'не удалось получить данные профиля: возможно, он находится в архиве или удален'
        )
      )
      .finally(() => setLoadingProfile(false));
  }

  const theme = defaultBlogDesign;
  return (
    <ServiceBackground data-testid="feed-scenes">
      <HeaderGeneral paddingBottom="45px">
        <h1>разговоры</h1>
      </HeaderGeneral>
      <ThemeProvider theme={theme}>
        <style>{style(theme)}</style>
        <div>
          {targetProfile && (
            <div className={css.header}>
              <div className={css.headerSideContainer}>
                {isLoggedIn && (
                  <ArrowIconWithBackground light="true" to={'/direct'}>
                    <SimpleArrowLeft className={css.backButtonIcon} />
                    <div className={css.backButtonText}>
                      вернуться ко всем разговорам
                    </div>
                  </ArrowIconWithBackground>
                )}
              </div>
              <DirectMessageHeaderProfile>
                <AvatarWithMenu
                  className="profile-avatar direct-message-header"
                  profile={targetProfile}
                />
                <BirthdayIcon
                  birthday={targetProfile.birthday}
                  nick={targetProfile.nickname}
                />
                <div className="nickname">{targetProfile.nickname}</div>
              </DirectMessageHeaderProfile>
              <div className={css.headerSideContainer} />
            </div>
          )}
        </div>
        <EntryPageProvider>
          <ConversationMessagesContainer
            ownProfileId={ownProfile?.id}
            targetProfileId={targetProfileId}
          />
        </EntryPageProvider>
      </ThemeProvider>
    </ServiceBackground>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {};

export const ConversationContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(ConversationScene)
);
