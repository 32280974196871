import Select, { GroupBase, Props } from 'react-select';
import {
  AsyncPaginate,
  LoadOptions,
  reduceGroupedOptions
} from 'react-select-async-paginate';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';

import theme from '@/styles/DybrTheme';

//***********************************/
// this is used only for dybr pages
const StyledSelectWrapper = styled.div`
  .dybr-select__dropdown-indicator {
    transition: 0.2s;
  }
  .dybr-select:hover .dybr-select__dropdown-indicator {
    color: ${p => p.theme.accent};
  }
`;

// this is used only for dybr pages
// the styles are defined in this fashion because the dropdown cannot be styled
// with styled component (it is mounted in the bottom of page body)
const customStyles = {
  container: (base, state) => ({
    ...base,
    borderTop: '1px solid ' + theme.backgroundDark,
    borderBottom: '1px solid ' + theme.backgroundDark,
    minHeight: '35px',
    borderRadius: '0px',
    borderColor: state.isFocused ? theme.accent : theme.backgroundDark,
    '&:hover': { borderColor: theme.accent },
    '&.sidebar-select': {
      borderTop: 'none'
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '90%',
    margin: '0px'
  }),
  control: base => ({
    ...base,
    border: 0,
    background: 'none',
    minHeight: '33px',
    boxShadow: 'none',
    borderRadius: '0px'
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px'
  }),
  input: base => ({
    ...base,
    margin: '0px',
    padding: '0px'
  }),
  menu: (base, state) => ({
    ...base,
    borderRadius: '0px'
  }),
  menuList: base => ({
    ...base,
    scrollbarWidth: 'thin',
    scrollbarColor: theme.backgroundDark + ' ' + theme.backgroundScroll,
    '::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: theme.backgroundScroll
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.backgroundDark
    }
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    fontWeight: state.isFocused ? 'bold' : 'normal',
    color: theme.text,
    transition: '0.2s',
    backgroundColor:
      state.isFocused || state.isSelected
        ? theme.backgroundLight
        : theme.backgroundLighter,
    borderLeft:
      '8px solid ' +
      (state.isFocused || state.isSelected
        ? theme.accent
        : theme.backgroundLighter),
    '&:active': { backgroundColor: theme.backgroundLight }
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: 'transparent'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? theme.accent : theme.backgroundDark
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  })
};

// wrapper for https://github.com/JedWatson/react-select

/** props:
 *
 * // accept options as an array of value+label objects
 *
 blogSelect: false - if set to true, styled component will not be used, styles should be defined in css
 options: [{ value: 'chocolate', label: 'Chocolate', otherKey: '' }]
 selectedOptionID: 0,
 onChange: (selectedOptionID) => {}
 className - for the container
 classNamePrefix - prefix for inner elements
 autoFocus - focus the control when it mounts
 isDisabled - disable the control
 isMulti - allow the user to select multiple values
 isSearchable - allow the user to search for matching options
 isPaginated - works with isAsync, loads more options when scrolling down, uses react-select-async-paginate wrapper
 name - generate an HTML input with this name, containing the current value
 placeholder - change the text displayed when no option is selected
 *
 * USE CLASSNAMES inside styled components
 * or in BLOG CSS
 *


 //***********************************/
// NOTE: react-select expects the value to be the option OBJECT, not its value or id (WHYYYYY)
// We should get option ID (= array index) from the outside component.
// We return the same back.

interface DybrSelectProps {
  blogSelect: boolean;
  options: Array<{ value: string; label: string; otherKey?: string }>;
  selectedOptionID: number;
  onChange: () => void;
  className?: string;
  classNamePrefix?: string;
  maxMenuHeight?: number;
  value?: any;
  creatable: boolean;
  controlledValue?: any;
  isAsync?: boolean;
  isMulti?: boolean;
  isPaginated?: boolean;
  isGrouped?: boolean;
  placeholder?: string;
  noOptionsMessage?: () => string;
  loadOptions?: LoadOptions<any, any, any>;
}

const DEFAULT_NO_OPTIONS_TEXT = 'Нет вариантов';

function DybrSelect({
  blogSelect,
  options,
  selectedOptionID,
  onChange: originalOnChange,
  className: originalClassName,
  classNamePrefix: originalClassNamePrefix,
  maxMenuHeight: originalMaxMenuHeight,
  value: originalValue,
  creatable = false,
  controlledValue,
  isAsync,
  isGrouped,
  isMulti,
  isPaginated,
  placeholder,
  noOptionsMessage = () => DEFAULT_NO_OPTIONS_TEXT,
  loadOptions,
  ...restProps
}: DybrSelectProps) {
  const onChangeReturnIndex = option => {
    let index = options.findIndex(o => o === option);
    if (index === -1) index = 0;
    originalOnChange(index);
  };

  const value = controlledValue
    ? originalValue
    : typeof selectedOptionID !== 'undefined'
    ? options[selectedOptionID]
    : null;

  const onChange = controlledValue ? originalOnChange : onChangeReturnIndex;

  const styles = blogSelect ? {} : customStyles;

  const maxMenuHeight = originalMaxMenuHeight || 130;

  const className = blogSelect
    ? originalClassName || 'blog-select'
    : originalClassName + ' dybr-select';

  const classNamePrefix = blogSelect
    ? originalClassNamePrefix || 'blog-select'
    : originalClassName + ' dybr-select';

  const select =
    isAsync && loadOptions ? (
      isPaginated ? (
        <AsyncPaginate
          loadOptions={loadOptions}
          options={options}
          value={value}
          onChange={onChange}
          reduceOptions={isGrouped ? reduceGroupedOptions : undefined}
          styles={styles}
          className={className}
          classNamePrefix={classNamePrefix}
          placeholder={placeholder}
          isMulti={isMulti}
          noOptionsMessage={noOptionsMessage}
          maxMenuHeight={maxMenuHeight}
          {...restProps}
        />
      ) : (
        <AsyncSelect
          options={options}
          value={value}
          onChange={onChange}
          styles={styles}
          className={className}
          classNamePrefix={classNamePrefix}
          placeholder={placeholder}
          isMulti={isMulti}
          noOptionsMessage={noOptionsMessage}
          maxMenuHeight={maxMenuHeight}
          {...restProps}
        />
      )
    ) : creatable ? (
      <CreatableSelect
        options={options}
        value={value}
        onChange={onChange}
        styles={styles}
        className={className}
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage}
        maxMenuHeight={maxMenuHeight}
        {...restProps}
      />
    ) : (
      <Select
        options={options}
        value={value}
        onChange={onChange}
        styles={styles}
        className={className}
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage}
        maxMenuHeight={maxMenuHeight}
        {...restProps}
      />
    );

  if (blogSelect) return select;

  return <StyledSelectWrapper>{select}</StyledSelectWrapper>;
}

export default DybrSelect;
